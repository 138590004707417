import fp from 'lodash/fp.js'
import qs from 'qs'

import getAsset from '~p/getAsset'
import getCompositeAsset from '~p/getCompositeAsset'

import { DECO_PLACEMENTS, DECO_HEIGHT_DICT } from '../common/sheets'

const getPhotoshopLayer = (pathParts) => {
  const bundleName = 'photoshop-layers'
  const size = {
    width: 2048,
    height: 1536,
  }
  return getCompositeAsset(bundleName, size, pathParts)
}

const getConfig = (activeView, expandedRecipeNested) => {
  const { product, calc, deco, colors } = expandedRecipeNested
  const { garment } = product
  const { sport } = calc
  const { color } = colors

  const getLogoTexture = (decoPlacement, thisDeco, textureSize) => {
    const { logo } = thisDeco

    if (!logo) {
      return undefined
    }

    const { previewFile } = logo

    if (!previewFile) {
      return undefined
    }

    return {
      key: decoPlacement.id,
      value: {
        url: `/api/images/${previewFile.id}`,
        size: textureSize,
      },
    }
  }

  const getFontTexture = (decoPlacement, thisDeco, textureSize) => {
    const { font, teamName, playerName, number } = thisDeco

    const text = teamName || playerName || number

    if (!text) {
      return undefined
    }

    const fill = thisDeco.fill
    const outline1 = thisDeco.outline1
    const outline2 = thisDeco.outline2

    const fillHex = fill.customColor || (fill.color && fill.color.props.hex)

    if (!fillHex) {
      return undefined
    }

    const rendererQuery = {
      font: font ? font.id : 'script',
      text,
      fill: fillHex,
    }

    const outline1Hex =
      outline1 &&
      (outline1.customColor || (outline1.color && outline1.color.props.hex))
    const outline2Hex =
      outline2 &&
      (outline2.customColor || (outline2.color && outline2.color.props.hex))

    if (outline1Hex) {
      rendererQuery.outline1 = outline1Hex
    }
    if (outline2Hex) {
      rendererQuery.outline2 = outline2Hex
    }

    const url = `/renderFont?${qs.stringify(rendererQuery)}`

    return {
      key: decoPlacement.id,
      value: {
        url,
        size: textureSize,
      },
    }
  }

  const getTexture = (decoPlacement) => {
    const thisDeco = deco[decoPlacement.id]

    const { isAvailable, type, size } = thisDeco

    if (!isAvailable) {
      return undefined
    }

    if (!type) {
      return undefined
    }

    const decoSize = decoPlacement.props.sizesBySportId[sport.id][type.id]

    let selectedHeight
    let maxHeight
    if (decoSize.heightIds) {
      if (!size) {
        return undefined
      }

      selectedHeight = DECO_HEIGHT_DICT[size.id].props.height
      maxHeight =
        DECO_HEIGHT_DICT[fp.max(fp.map(Number, decoSize.heightIds))].props
          .height
    }

    const width = decoSize.width
    const height = decoSize.height ? decoSize.height : selectedHeight

    if (!width || !height) {
      return undefined
    }

    if (!maxHeight) {
      maxHeight = height
    }

    const textureSize = {
      width,
      height,
      maxHeight,
    }

    if (type.id === 'logo') {
      return getLogoTexture(decoPlacement, thisDeco, textureSize)
    }

    return getFontTexture(decoPlacement, thisDeco, textureSize)
  }

  const getTextures = () =>
    fp.reduce(
      (a, decoPlacement) => {
        const texture = getTexture(decoPlacement)
        if (texture) {
          a[texture.key] = texture.value
        }
        return a
      },
      {},
      DECO_PLACEMENTS,
    )

  return {
    overlays: {
      model: getAsset(`3Dplacements/${garment.id}_${activeView}.gltf`),
      modelBin: getAsset(`3Dplacements/${garment.id}_${activeView}.bin`),
      // TODO: How do we limit textures by view, should we store it in sheets?
      textures: getTextures(),
    },
    images: {
      mask: getPhotoshopLayer([garment.id, activeView, 'mask.png']),
      shadow: getPhotoshopLayer([garment.id, activeView, 'shadow.png']),
      highlight: getPhotoshopLayer([garment.id, activeView, 'highlight.png']),
      bg: getPhotoshopLayer([garment.id, activeView, `color_${color.id}.png`]),
    },
    size: {
      width: 1109,
      height: 1536,
    },
  }
}

export default getConfig
