// DO NOT EDIT, GENERATED BY "yarn sheets"

import { DecoPackageRow } from '../typings';

const data = [
  {
    "id": "embroidery",
    "name": "Embroidery",
    "props": {
      "logo": {
        "colorCount": 4
      },
      "outlineCount": {
        "teamName": 0,
        "playerName": 0,
        "number": 1
      }
    }
  },
  {
    "id": "screenPrint",
    "name": "Screen Print",
    "props": {
      "logo": {
        "colorCount": 4
      },
      "outlineCount": {
        "teamName": 2,
        "playerName": 0,
        "number": 1
      }
    }
  },
  {
    "id": "tackleTwill",
    "name": "Tackle Twill",
    "props": {
      "logo": {
        "colorCount": 3
      },
      "outlineCount": {
        "teamName": 2,
        "playerName": 2,
        "number": 2
      }
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<DecoPackageRow[]>> = data;

export type DecoPackage = typeof typed[number];

export const DECO_PACKAGE_INDEX_KEY = "id";
export type DecoPackageIndexKey = "id";
export type DecoPackageId = DecoPackage["id"];

let i = 0;
export const DECO_PACKAGE_DICT = {
  "embroidery": typed[i++],
  "screenPrint": typed[i++],
  "tackleTwill": typed[i++]
} as const;

export { typed as DECO_PACKAGES };
