import * as _ from '@technically/lodash'
import React, { Fragment } from 'react'
import cn from 'classnames'

import { connectOrders } from '~p/client/component-enhancers'
import Application from '~p/client/containers/Application'
import RenderOverlay from '~p/client/renderOverlay/components/RenderOverlay'
import PreviewImages from '~p/client/components/PreviewImages'
import getCompositeAsset from '~p/getCompositeAsset'

import Layout from '~mizuno/client/components/Layout'
import Sidebar from '~mizuno/client/components/Sidebar'
import _ProductTitle from '~mizuno/client/components/organisms/ProductTitle'
import ProductSet from '~mizuno/client/components/molecules/ProductSet'
import ProductTile from '~mizuno/client/components/molecules/ProductTile'
import Summary from '~mizuno/client/components/Summary'
import ViewNavigation from '~mizuno/client/components/atoms/ViewNavigation'
import Button from '~mizuno/client/components/atoms/Button'
import LoadingIndicator from '~mizuno/client/components/atoms/LoadingIndicator'
import SocialIcons from '~mizuno/client/containers/SocialIcons'

import '~mizuno/client/components/Root.css'

import { setPreviewMinimization } from '~p/client/common/actions'

import _Finalize from '../../../mizuno-uniforms/client/containers/Finalize'
import ContactForm from '../../../mizuno-uniforms/client/containers/ContactForm'

import viewAngles, { defaultViewAngleId } from '../../viewAngles'
import store from '../store'
import controlTree from '../controlTree'

import './Root.css'

{
  const mql = window.matchMedia('(min-width: 1000px)')
  if (mql.matches) store.dispatch(setPreviewMinimization(false))
  mql.addListener((x) => store.dispatch(setPreviewMinimization(!x.matches)))
}

const ProductTitle = connectOrders(controlTree, _ProductTitle)
const Finalize = connectOrders(controlTree, _Finalize)

const getImageUrl = (pathParts) => {
  const bundleName = 'photoshop-layers'
  const size = {
    width: 2048,
    height: 512,
  }
  return getCompositeAsset(bundleName, size, pathParts)
}

const Root = (props) => {
  const isProductScreen = props.menu === 'product' && !props.isRecipeFinalized
  const isFinalizeScreen = props.isRecipeFinalized
  const isRendererHidden = isProductScreen || isFinalizeScreen

  return (
    <Application store={store}>
      <Layout
        isProductScreen={isProductScreen}
        isFinalizeScreen={isFinalizeScreen}
        isAppLoading={props.isAppLoading}
        isPreviewMinimized={props.isPreviewMinimized}
      >
        <ProductTitle
          previewState={props.isPreviewMinimized ? 'off' : 'on'}
          title={
            props.expandedRecipeNested.details.recipeName.text ||
            'My custom apparel'
          }
          name={props.productName}
          saveButtonLabel="Save my apparel"
          onSave={() => props.saveRecipe()}
          onShare={() => {
            props.copyLinkToClipboard()
          }}
          onPreviewToggle={() => props.togglePreviewMinimization()}
        />

        <div className="viewer">
          <div className="preview">
            <div
              className={cn([
                'renderer',
                props.isPreviewMinimized ? 'is-off' : 'is-on',
              ])}
            >
              <RenderOverlay
                setPreviewGenerator={props.setPreviewGenerator}
                config={props.renderOverlayConfig}
                isHidden={isRendererHidden}
                isLoading={props.renderOverlayIsLoading}
                setLoading={props.setRenderOverlayLoading}
              />

              {!isRendererHidden && (
                <Fragment>
                  {props.renderOverlayIsLoading && <LoadingIndicator />}

                  <ViewNavigation>
                    {_.map(viewAngles, ({ label }, view) => (
                      <Button
                        key={view}
                        classMods={['view']}
                        classStates={
                          view === props.renderOverlayActiveView ?
                            ['active']
                          : []
                        }
                        onClick={() => {
                          props.setRenderOverlayActiveView(view)
                        }}
                      >
                        {label}
                      </Button>
                    ))}
                  </ViewNavigation>
                </Fragment>
              )}

              {isFinalizeScreen && (
                <div className="preview-images-outer">
                  {!window.serverConfig.hideSocial && (
                    <SocialIcons
                      classMods={['largeScreen']}
                      downloadUrl={props.previewUrls[defaultViewAngleId]}
                    />
                  )}

                  <PreviewImages previewUrls={props.previewUrls} />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="productSelect">
          <ProductSet>
            {_.map(props.products, (product) => (
              <ProductTile
                key={product.id}
                name={product.name}
                onClick={() => props.changeProduct(product.id)}
                imageUrl={getImageUrl([
                  product.id,
                  'front',
                  `color_${product.props.defaultColorId}.png`,
                ])}
                buttonText="Build this apparel"
              />
            ))}
          </ProductSet>
        </div>

        <div className="sidebar">
          <div className="sidebar-body">
            <Sidebar nodes={props.nodes} />
          </div>
        </div>

        <Finalize
          controlTree={controlTree}
          onSave={props.saveRecipe}
          resetMenu="colors"
          saveButtonLabel="Save my apparel"
          resumeButtonLabel="Edit apparel"
        >
          {isFinalizeScreen && !props.isAppLoading && (
            <Fragment>
              <ContactForm />
              <Summary nodes={props.nodes} />
            </Fragment>
          )}
        </Finalize>
      </Layout>
    </Application>
  )
}

export default Root
