// DO NOT EDIT, GENERATED BY "yarn sheets"

import { RosterSizeRow } from '../typings';

const data = [
  {
    "id": "yS",
    "name": "YS",
    "props": {
      "ageGroupId": "youth",
      "localName": "S",
      "printName": "Youth S"
    }
  },
  {
    "id": "yM",
    "name": "YM",
    "props": {
      "ageGroupId": "youth",
      "localName": "M",
      "printName": "Youth M"
    }
  },
  {
    "id": "yL",
    "name": "YL",
    "props": {
      "ageGroupId": "youth",
      "localName": "L",
      "printName": "Youth L"
    }
  },
  {
    "id": "yXL",
    "name": "YXL",
    "props": {
      "ageGroupId": "youth",
      "localName": "XL",
      "printName": "Youth XL"
    }
  },
  {
    "id": "yXXL",
    "name": "YXXL",
    "props": {
      "ageGroupId": "youth",
      "localName": "XXL",
      "printName": "Youth XXL"
    }
  },
  {
    "id": "yXXXL",
    "name": "YXXXL",
    "props": {
      "ageGroupId": "youth",
      "localName": "XXXL",
      "printName": "Youth XXXL"
    }
  },
  {
    "id": "XXS",
    "name": "XXS",
    "props": {
      "ageGroupId": "adult",
      "localName": "XXS",
      "printName": "XXS"
    }
  },
  {
    "id": "XS",
    "name": "XS",
    "props": {
      "ageGroupId": "adult",
      "localName": "XS",
      "printName": "XS"
    }
  },
  {
    "id": "S",
    "name": "S",
    "props": {
      "ageGroupId": "adult",
      "localName": "S",
      "printName": "S"
    }
  },
  {
    "id": "M",
    "name": "M",
    "props": {
      "ageGroupId": "adult",
      "localName": "M",
      "printName": "M"
    }
  },
  {
    "id": "L",
    "name": "L",
    "props": {
      "ageGroupId": "adult",
      "localName": "L",
      "printName": "L"
    }
  },
  {
    "id": "XL",
    "name": "XL",
    "props": {
      "ageGroupId": "adult",
      "localName": "XL",
      "printName": "XL"
    }
  },
  {
    "id": "XXL",
    "name": "XXL",
    "props": {
      "ageGroupId": "adult",
      "localName": "XXL",
      "printName": "XXL"
    }
  },
  {
    "id": "XXXL",
    "name": "XXXL",
    "props": {
      "ageGroupId": "adult",
      "localName": "XXXL",
      "printName": "XXXL"
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<RosterSizeRow[]>> = data;

export type RosterSize = typeof typed[number];

export const ROSTER_SIZE_INDEX_KEY = "id";
export type RosterSizeIndexKey = "id";
export type RosterSizeId = RosterSize["id"];

let i = 0;
export const ROSTER_SIZE_DICT = {
  "yS": typed[i++],
  "yM": typed[i++],
  "yL": typed[i++],
  "yXL": typed[i++],
  "yXXL": typed[i++],
  "yXXXL": typed[i++],
  "XXS": typed[i++],
  "XS": typed[i++],
  "S": typed[i++],
  "M": typed[i++],
  "L": typed[i++],
  "XL": typed[i++],
  "XXL": typed[i++],
  "XXXL": typed[i++]
} as const;

export { typed as ROSTER_SIZES };
