// DO NOT EDIT, GENERATED BY "yarn sheets"

import { GarmentColorRow } from '../typings';

const data = [
  {
    "id": "0000",
    "name": "White"
  },
  {
    "id": "0073",
    "name": "White / Silver"
  },
  {
    "id": "0091",
    "name": "White / Grey"
  },
  {
    "id": "1000",
    "name": "Red / White"
  },
  {
    "id": "1010",
    "name": "Red"
  },
  {
    "id": "1073",
    "name": "Red / Silver"
  },
  {
    "id": "1091",
    "name": "Red / Grey"
  },
  {
    "id": "1200",
    "name": "Cardinal / White"
  },
  {
    "id": "1212",
    "name": "Cardinal"
  },
  {
    "id": "1273",
    "name": "Cardinal / Silver"
  },
  {
    "id": "2000",
    "name": "Orange / White"
  },
  {
    "id": "2020",
    "name": "Orange"
  },
  {
    "id": "4100",
    "name": "Forest / White"
  },
  {
    "id": "4141",
    "name": "Forest Green"
  },
  {
    "id": "4173",
    "name": "Forest / Silver"
  },
  {
    "id": "5100",
    "name": "Navy / White"
  },
  {
    "id": "5110",
    "name": "Navy / Red"
  },
  {
    "id": "5151",
    "name": "Navy"
  },
  {
    "id": "5173",
    "name": "Navy / Silver"
  },
  {
    "id": "5191",
    "name": "Navy / Grey"
  },
  {
    "id": "5200",
    "name": "Royal / White"
  },
  {
    "id": "5252",
    "name": "Royal"
  },
  {
    "id": "5273",
    "name": "Royal / Silver"
  },
  {
    "id": "5291",
    "name": "Royal / Grey"
  },
  {
    "id": "5500",
    "name": "Columbia / White"
  },
  {
    "id": "5555",
    "name": "Columbia"
  },
  {
    "id": "6000",
    "name": "Purple / White"
  },
  {
    "id": "6060",
    "name": "Purple"
  },
  {
    "id": "6073",
    "name": "Purple / Silver"
  },
  {
    "id": "9000",
    "name": "Black / White"
  },
  {
    "id": "9073",
    "name": "Black / Silver"
  },
  {
    "id": "9090",
    "name": "Black"
  },
  {
    "id": "9091",
    "name": "Black / Grey"
  },
  {
    "id": "9100",
    "name": "Grey / White"
  },
  {
    "id": "9191",
    "name": "Grey"
  },
  {
    "id": "9200",
    "name": "Charcoal / White"
  },
  {
    "id": "9273",
    "name": "Charcoal / Silver"
  },
  {
    "id": "9292",
    "name": "Charcoal"
  },
  {
    "id": "109I",
    "name": "Red / Shade"
  },
  {
    "id": "1M00",
    "name": "Shocking Pink / White"
  },
  {
    "id": "1M1M",
    "name": "Shocking Pink"
  },
  {
    "id": "1M73",
    "name": "Shocking Pink / Silver"
  },
  {
    "id": "4L4L",
    "name": "Kelly Green"
  },
  {
    "id": "519I",
    "name": "Navy / Shade"
  },
  {
    "id": "529I",
    "name": "Royal / Shade"
  },
  {
    "id": "5S73",
    "name": "Diva Blue / Silver"
  },
  {
    "id": "8D90",
    "name": "Electric Green / Black"
  },
  {
    "id": "909I",
    "name": "Black / Shade"
  },
  {
    "id": "9191-1707",
    "name": "Grey"
  },
  {
    "id": "9I9I",
    "name": "Shade"
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<GarmentColorRow[]>> = data;

export type GarmentColor = typeof typed[number];

export const GARMENT_COLOR_INDEX_KEY = "id";
export type GarmentColorIndexKey = "id";
export type GarmentColorId = GarmentColor["id"];

let i = 0;
export const GARMENT_COLOR_DICT = {
  "0000": typed[i++],
  "0073": typed[i++],
  "0091": typed[i++],
  "1000": typed[i++],
  "1010": typed[i++],
  "1073": typed[i++],
  "1091": typed[i++],
  "1200": typed[i++],
  "1212": typed[i++],
  "1273": typed[i++],
  "2000": typed[i++],
  "2020": typed[i++],
  "4100": typed[i++],
  "4141": typed[i++],
  "4173": typed[i++],
  "5100": typed[i++],
  "5110": typed[i++],
  "5151": typed[i++],
  "5173": typed[i++],
  "5191": typed[i++],
  "5200": typed[i++],
  "5252": typed[i++],
  "5273": typed[i++],
  "5291": typed[i++],
  "5500": typed[i++],
  "5555": typed[i++],
  "6000": typed[i++],
  "6060": typed[i++],
  "6073": typed[i++],
  "9000": typed[i++],
  "9073": typed[i++],
  "9090": typed[i++],
  "9091": typed[i++],
  "9100": typed[i++],
  "9191": typed[i++],
  "9200": typed[i++],
  "9273": typed[i++],
  "9292": typed[i++],
  "109I": typed[i++],
  "1M00": typed[i++],
  "1M1M": typed[i++],
  "1M73": typed[i++],
  "4L4L": typed[i++],
  "519I": typed[i++],
  "529I": typed[i++],
  "5S73": typed[i++],
  "8D90": typed[i++],
  "909I": typed[i++],
  "9191-1707": typed[i++],
  "9I9I": typed[i++]
} as const;

export { typed as GARMENT_COLORS };
