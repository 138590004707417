import { forEach } from '@technically/lodash'
import React from 'react'
import { createRoot } from 'react-dom/client'
import viewportUnitsBuggyfill from 'viewport-units-buggyfill'
import WebFont from 'webfontloader'

import handleErrors from '../../../platform/client/handleErrors'
import * as controlTreeDebugUtils from '../../../platform/client/control-tree/debugUtils'
import {
  setOriginValues,
  loadRecipe,
} from '../../../platform/client/common/actions'
import configureRouter from '../../../platform/client/configureRouter'

import * as meta from '../common/meta'
import Root from './containers/Root'
import store from './store'
import controlTree from '../client/controlTree'

handleErrors(store)

// For debugging.
window.store = store

// One should be using control-tree/debugUtils via window like window.getNodes().
window.getControlTree = () => controlTree
forEach(controlTreeDebugUtils.createUtils(store, controlTree), (fn, name) => {
  window[name] = fn
})

viewportUnitsBuggyfill.init()

WebFont.load({
  google: {
    families: ['Roboto:300,400,500,700'],
  },
})

const routes = ['/sku/:sku', '/design/:recipeId(/:status)', '/review/:recipeId']

const { initialMatches } = configureRouter(controlTree, store, routes)

if (initialMatches) {
  const matches = initialMatches
  const { sku, recipeId } = matches

  if (recipeId) {
    store.dispatch(loadRecipe(controlTree, recipeId))
  } else if (sku) {
    const { garmentId } = meta.getSkuValues(sku)
    store.dispatch(
      controlTree.setValues({
        'product.garment': garmentId,
      }),
    )
    store.dispatch(setOriginValues(controlTree))
  }
} else {
  store.dispatch(setOriginValues(controlTree))
}

const $root = document.getElementById('root')
if (!$root) throw Error('Node <root /> not found, cannot mount React app!')
createRoot($root).render(<Root store={store} />)
