import {
  transform,
  forEach,
  every,
  last,
  isEqual,
  mapValues,
} from '@technically/lodash'
import {
  Engine,
  Vector3,
  Camera,
  Mesh,
  Color4,
  StandardMaterial,
  Texture,
  Scene,
  SceneLoader,
} from '@babylonjs/core'
import '@babylonjs/loaders/glTF'

import ImageLoader from './ImageLoader'
import getPaddedImageURL from './getPaddedImageURL'

class ModelLogic {
  imageLoader
  engine
  scene
  camera

  state = {
    model: 'not specified',
    modelBin: 'not specified',
    textures: {},
  }
  modelAssetsMap = {}
  modelAssets

  isDirty = false

  constructor(canvas, state) {
    this.imageLoader = new ImageLoader()

    this.engine = new Engine(
      canvas,
      true,
      {
        limitDeviceRatio: 3,
        doNotHandleTouchAction: true,
        alpha: true,
      },
      true,
    )

    this.scene = new Scene(this.engine)
    this.scene.clearColor = new Color4(0, 0, 0, 0)

    this.camera = new Camera(
      'dynamicCamera',
      new Vector3(0, 0, 0),
      this.scene,
      true,
    )

    this.engine.runRenderLoop(this.maybeRender)

    this.state = state
    this.loadAndUseModel(state)
  }

  onBeforeRender = (callback) => {
    this.scene.onBeforeRenderObservable.add(callback)
  }

  onAfterRender = (callback) => {
    this.scene.onAfterRenderObservable.add(callback)
  }

  getHardwareScalingLevel() {
    return this.engine.getHardwareScalingLevel()
  }

  updateState(state) {
    if (isEqual(this.state, state)) {
      return true
    }

    if (
      this.state.model !== state.model ||
      this.state.modelBin !== state.modelBin
    ) {
      this.loadAndUseModel(state)
    }

    if (this.modelAssets) {
      this.updatePlacements(this.modelAssets, state.textures)
    }

    this.state = state

    return false
  }

  dispose() {
    this.scene.dispose()
    this.engine.dispose()
  }

  maybeRender = () => {
    if (this.scene.debugLayer.isVisible()) {
      // `debugLayer.onPropertyChangedObservable` does not seem to work
      this.isDirty = true
    }
    if (!this.isDirty) {
      return
    }
    this.isDirty = false

    const hasAllImages =
      this.modelAssets &&
      every(
        this.modelAssets.meshes,
        (meshAssets) =>
          !meshAssets.mesh.isEnabled(false) || meshAssets.texture.isReady(),
      )
    if (!hasAllImages) {
      return
    }
    this.scene.render()
  }

  setSize(width, height) {
    this.engine.setSize(width, height)
    this.isDirty = true
  }

  showDebugLayer = () => {
    this.scene.debugLayer.show()
  }

  loadAndUseModel({ model, modelBin }) {
    if (this.modelAssets) {
      this.modelAssets.assetContainer.removeAllFromScene()
      this.modelAssets = undefined
      this.isDirty = true
    }

    if (this.modelAssetsMap[model]) {
      this.useModelAssets(this.modelAssetsMap[model])
      return
    }

    // Fixes gltf file pointing to bin file which is not reved.
    SceneLoader.OnPluginActivatedObservable.addOnce((loader) => {
      if (loader.name === 'gltf') {
        loader.onParsed = (data) => {
          data.json.buffers[0].uri = last(modelBin.split('/'))
        }
      }
    })

    SceneLoader.LoadAssetContainerAsync(model, undefined, this.scene).then(
      (assetContainer) => {
        const meshes = transform(
          assetContainer.meshes,
          this.registerMeshAssets,
          {},
        )
        const camera = assetContainer.cameras[0]
        if (!camera) {
          throw new Error(`Camera not found: ${model}`)
        }
        const modelAssets = {
          camera,
          meshes,
          assetContainer,
        }
        this.modelAssetsMap[model] = modelAssets

        // check if we are still waiting for this particular model
        if (model === this.state.model) {
          this.useModelAssets(modelAssets)
        }
      },
    )
  }

  registerMeshAssets = (result, mesh) => {
    if (mesh.name === '__root__') {
      return
    }
    const mat = new StandardMaterial(mesh.name, this.scene)
    const texture = new Texture(null, this.scene, undefined, false)
    mat.emissiveTexture = texture
    mat.opacityTexture = texture
    mat.sideOrientation = Mesh.FRONTSIDE
    mesh.material = mat

    result[mesh.name] = {
      mesh,
      texture,
    }
  }

  useModelAssets(modelAssets) {
    this.modelAssets = modelAssets
    modelAssets.assetContainer.addAllToScene()
    this.scene.activeCamera = modelAssets.camera
    this.isDirty = true

    this.updatePlacements(modelAssets, this.state.textures)
  }

  async updatePlacements(modelAssets, textures) {
    this.imageLoader.cancel()
    const images = await this.imageLoader.load(mapValues(textures, 'url'))

    forEach(modelAssets.meshes, (placement, name) => {
      const texture = textures[name]

      const image = images[name]

      let url
      if (image) {
        url = getPaddedImageURL(image, texture.size)
      }

      placement.mesh.setEnabled(!!url)

      const hasChanged = placement.texture.url !== url

      if (hasChanged) {
        if (url) {
          placement.texture.updateURL(url, null, () => {
            this.isDirty = true
          })
        } else {
          placement.texture.url = null
          this.isDirty = true
        }
      }
    })
  }
}

export default ModelLogic
