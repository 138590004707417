// DO NOT EDIT, GENERATED BY "yarn sheets"

import { DecoPlacementRow } from '../typings';

const data = [
  {
    "id": "upperFrontLeft",
    "name": "Upper Front Left",
    "props": {
      "sizesBySportId": {
        "baseball": {
          "logo": {
            "width": 4.5,
            "height": 4.5
          },
          "teamName": {
            "width": 4.5,
            "height": 4.5
          },
          "number": {
            "width": 4,
            "heightIds": [
              "2"
            ]
          }
        },
        "softball": {
          "logo": {
            "width": 4.5,
            "height": 4.5
          },
          "teamName": {
            "width": 4.5,
            "height": 4.5
          },
          "number": {
            "width": 4,
            "heightIds": [
              "2"
            ]
          }
        },
        "volleyball": {
          "logo": {
            "width": 4.5,
            "height": 4.5
          },
          "teamName": {
            "width": 4.5,
            "height": 4.5
          }
        },
        "training": {
          "logo": {
            "width": 4.5,
            "height": 4.5
          },
          "teamName": {
            "width": 4.5,
            "height": 4.5
          },
          "number": {
            "width": 4,
            "heightIds": [
              "2"
            ]
          }
        }
      }
    }
  },
  {
    "id": "upperFrontRight",
    "name": "Upper Front Right",
    "props": {
      "sizesBySportId": {
        "training": {
          "logo": {
            "width": 4.5,
            "height": 4.5
          },
          "teamName": {
            "width": 4.5,
            "height": 4.5
          },
          "number": {
            "width": 4,
            "heightIds": [
              "2"
            ]
          }
        }
      }
    }
  },
  {
    "id": "fullFront-teamName",
    "name": "Middle Front",
    "props": {
      "sizesBySportId": {
        "baseball": {
          "logo": {
            "width": 14,
            "height": 5
          },
          "teamName": {
            "width": 14,
            "height": 5
          }
        },
        "softball": {
          "logo": {
            "width": 14,
            "height": 5
          },
          "teamName": {
            "width": 14,
            "height": 5
          }
        },
        "volleyball": {
          "logo": {
            "width": 12,
            "height": 4
          },
          "teamName": {
            "width": 12,
            "height": 4
          }
        },
        "training": {
          "logo": {
            "width": 14,
            "height": 5
          },
          "teamName": {
            "width": 14,
            "height": 5
          },
          "number": {
            "width": 8,
            "heightIds": [
              "4",
              "6"
            ]
          }
        }
      },
      "overlapsWith": [
        "lowerFrontCenter"
      ]
    }
  },
  {
    "id": "fullFront",
    "name": "Full Front",
    "props": {
      "sizesBySportId": {
        "baseball": {
          "logo": {
            "width": 14,
            "height": 12
          },
          "teamName": {
            "width": 14,
            "height": 5
          }
        },
        "softball": {
          "logo": {
            "width": 14,
            "height": 12
          },
          "teamName": {
            "width": 14,
            "height": 5
          }
        },
        "training": {
          "logo": {
            "width": 14,
            "height": 12
          },
          "teamName": {
            "width": 14,
            "height": 5
          },
          "number": {
            "width": 12,
            "heightIds": [
              "8"
            ]
          }
        },
        "cap": {
          "logo": {
            "width": 5,
            "height": 3
          },
          "teamName": {
            "width": 5,
            "height": 3
          }
        }
      },
      "overlapsWith": [
        "fullFront-teamName",
        "lowerFrontCenter",
        "lowerFrontRight",
        "lowerFrontLeft"
      ]
    }
  },
  {
    "id": "lowerFrontLeft",
    "name": "Lower Front Left",
    "props": {
      "sizesBySportId": {
        "baseball": {
          "logo": {
            "width": 8,
            "height": 4
          },
          "number": {
            "width": 8,
            "heightIds": [
              "4"
            ]
          }
        },
        "softball": {
          "logo": {
            "width": 8,
            "height": 4
          },
          "number": {
            "width": 8,
            "heightIds": [
              "4"
            ]
          }
        },
        "training": {
          "logo": {
            "width": 8,
            "height": 4
          },
          "number": {
            "width": 8,
            "heightIds": [
              "4"
            ]
          }
        }
      },
      "overlapsWith": [
        "lowerFrontCenter",
        "fullFront-teamName"
      ]
    }
  },
  {
    "id": "lowerFrontRight",
    "name": "Lower Front Right",
    "props": {
      "sizesBySportId": {
        "baseball": {
          "logo": {
            "width": 8,
            "height": 4
          },
          "number": {
            "width": 8,
            "heightIds": [
              "4"
            ]
          }
        },
        "softball": {
          "logo": {
            "width": 8,
            "height": 4
          },
          "number": {
            "width": 8,
            "heightIds": [
              "4"
            ]
          }
        },
        "training": {
          "logo": {
            "width": 8,
            "height": 4
          },
          "number": {
            "width": 8,
            "heightIds": [
              "4"
            ]
          }
        }
      },
      "overlapsWith": [
        "lowerFrontCenter",
        "fullFront-teamName"
      ]
    }
  },
  {
    "id": "lowerFrontCenter",
    "name": "Lower Front Center",
    "props": {
      "sizesBySportId": {
        "baseball": {
          "logo": {
            "width": 8,
            "height": 4
          },
          "number": {
            "width": 8,
            "heightIds": [
              "4"
            ]
          }
        },
        "softball": {
          "logo": {
            "width": 8,
            "height": 4
          },
          "number": {
            "width": 8,
            "heightIds": [
              "4"
            ]
          }
        },
        "volleyball": {
          "number": {
            "width": 8,
            "heightIds": [
              "4",
              "6"
            ]
          }
        },
        "training": {
          "logo": {
            "width": 8,
            "height": 4
          },
          "number": {
            "width": 8,
            "heightIds": [
              "4"
            ]
          }
        }
      },
      "overlapsWith": [
        "fullFront-teamName",
        "lowerFrontRight",
        "lowerFrontLeft"
      ]
    }
  },
  {
    "id": "upperBackSquare",
    "name": "Upper Back Square",
    "props": {
      "sizesBySportId": {
        "baseball": {
          "logo": {
            "width": 4.5,
            "height": 4.5
          }
        },
        "softball": {
          "logo": {
            "width": 4.5,
            "height": 4.5
          }
        },
        "volleyball": {
          "logo": {
            "width": 4.5,
            "height": 4.5
          }
        },
        "training": {
          "logo": {
            "width": 4.5,
            "height": 4.5
          },
          "number": {
            "width": 4,
            "heightIds": [
              "2"
            ]
          }
        },
        "cap": {
          "logo": {
            "width": 2,
            "height": 2
          },
          "teamName": {
            "width": 2,
            "height": 2
          },
          "playerName": {
            "width": 2,
            "height": 2
          },
          "number": {
            "width": 2,
            "heightIds": [
              "2"
            ]
          }
        }
      },
      "overlapsWith": [
        "upperBackWide"
      ]
    }
  },
  {
    "id": "upperBackWide",
    "name": "Upper Back Wide",
    "props": {
      "sizesBySportId": {
        "baseball": {
          "teamName": {
            "width": 15,
            "height": 3
          },
          "playerName": {
            "width": 15,
            "height": 3
          }
        },
        "softball": {
          "teamName": {
            "width": 15,
            "height": 3
          },
          "playerName": {
            "width": 15,
            "height": 3
          }
        },
        "volleyball": {
          "teamName": {
            "width": 15,
            "height": 3
          },
          "playerName": {
            "width": 15,
            "height": 3
          }
        },
        "training": {
          "teamName": {
            "width": 15,
            "height": 3
          },
          "playerName": {
            "width": 15,
            "height": 3
          }
        }
      },
      "overlapsWith": [
        "upperBackSquare"
      ]
    }
  },
  {
    "id": "middleBack",
    "name": "Middle Back",
    "props": {
      "sizesBySportId": {
        "baseball": {
          "logo": {
            "width": 12,
            "height": 12
          },
          "number": {
            "width": 12,
            "heightIds": [
              "8"
            ]
          }
        },
        "softball": {
          "logo": {
            "width": 12,
            "height": 12
          },
          "number": {
            "width": 12,
            "heightIds": [
              "8"
            ]
          }
        },
        "volleyball": {
          "number": {
            "width": 12,
            "heightIds": [
              "6",
              "8"
            ]
          }
        },
        "training": {
          "logo": {
            "width": 12,
            "height": 12
          },
          "number": {
            "width": 12,
            "heightIds": [
              "6",
              "8"
            ]
          }
        }
      }
    }
  },
  {
    "id": "lowerBack",
    "name": "Lower Back",
    "props": {
      "sizesBySportId": {
        "baseball": {
          "teamName": {
            "width": 15,
            "height": 3
          },
          "playerName": {
            "width": 15,
            "height": 3
          }
        },
        "softball": {
          "teamName": {
            "width": 15,
            "height": 3
          },
          "playerName": {
            "width": 15,
            "height": 3
          }
        },
        "volleyball": {
          "teamName": {
            "width": 15,
            "height": 3
          },
          "playerName": {
            "width": 15,
            "height": 3
          }
        },
        "training": {
          "logo": {
            "width": 15,
            "height": 3
          },
          "teamName": {
            "width": 15,
            "height": 3
          },
          "playerName": {
            "width": 15,
            "height": 3
          }
        }
      }
    }
  },
  {
    "id": "fullBack",
    "name": "Full Back",
    "props": {
      "sizesBySportId": {
        "baseball": {
          "logo": {
            "width": 14,
            "height": 12
          }
        },
        "softball": {
          "logo": {
            "width": 14,
            "height": 12
          }
        },
        "training": {
          "logo": {
            "width": 14,
            "height": 12
          }
        }
      }
    }
  },
  {
    "id": "leftSleeve",
    "name": "Left Sleeve",
    "props": {
      "sizesBySportId": {
        "baseball": {
          "logo": {
            "width": 4.5,
            "height": 4.5
          },
          "teamName": {
            "width": 4.5,
            "height": 4.5
          },
          "number": {
            "width": 4,
            "heightIds": [
              "2"
            ]
          }
        },
        "softball": {
          "logo": {
            "width": 4.5,
            "height": 4.5
          },
          "teamName": {
            "width": 4.5,
            "height": 4.5
          },
          "number": {
            "width": 4,
            "heightIds": [
              "2"
            ]
          }
        },
        "volleyball": {
          "logo": {
            "width": 4.5,
            "height": 4.5
          },
          "teamName": {
            "width": 4.5,
            "height": 4.5
          }
        },
        "training": {
          "logo": {
            "width": 4.5,
            "height": 4.5
          },
          "teamName": {
            "width": 4.5,
            "height": 4.5
          },
          "number": {
            "width": 4,
            "heightIds": [
              "2"
            ]
          }
        }
      },
      "overlapsWith": [
        "fullLeftSleeve"
      ]
    }
  },
  {
    "id": "rightSleeve",
    "name": "Right Sleeve",
    "props": {
      "sizesBySportId": {
        "baseball": {
          "logo": {
            "width": 4.5,
            "height": 4.5
          },
          "teamName": {
            "width": 4.5,
            "height": 4.5
          },
          "number": {
            "width": 4,
            "heightIds": [
              "2"
            ]
          }
        },
        "softball": {
          "logo": {
            "width": 4.5,
            "height": 4.5
          },
          "teamName": {
            "width": 4.5,
            "height": 4.5
          },
          "number": {
            "width": 4,
            "heightIds": [
              "2"
            ]
          }
        },
        "volleyball": {
          "logo": {
            "width": 4.5,
            "height": 4.5
          },
          "teamName": {
            "width": 4.5,
            "height": 4.5
          }
        },
        "training": {
          "logo": {
            "width": 4.5,
            "height": 4.5
          },
          "teamName": {
            "width": 4.5,
            "height": 4.5
          },
          "number": {
            "width": 4,
            "heightIds": [
              "2"
            ]
          }
        }
      },
      "overlapsWith": [
        "fullRightSleeve"
      ]
    }
  },
  {
    "id": "fullLeftSleeve",
    "name": "Full Left Sleeve",
    "props": {
      "overlapsWith": [
        "leftSleeve"
      ]
    }
  },
  {
    "id": "fullRightSleeve",
    "name": "Full Right Sleeve",
    "props": {
      "overlapsWith": [
        "rightSleeve"
      ]
    }
  },
  {
    "id": "rightSide",
    "name": "Right Side",
    "props": {
      "sizesBySportId": {
        "cap": {
          "logo": {
            "width": 3,
            "height": 3
          },
          "teamName": {
            "width": 3,
            "height": 3
          },
          "playerName": {
            "width": 3,
            "height": 3
          },
          "number": {
            "width": 3,
            "heightIds": [
              "1.5"
            ]
          }
        }
      }
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<DecoPlacementRow[]>> = data;

export type DecoPlacement = typeof typed[number];

export const DECO_PLACEMENT_INDEX_KEY = "id";
export type DecoPlacementIndexKey = "id";
export type DecoPlacementId = DecoPlacement["id"];

let i = 0;
export const DECO_PLACEMENT_DICT = {
  "upperFrontLeft": typed[i++],
  "upperFrontRight": typed[i++],
  "fullFront-teamName": typed[i++],
  "fullFront": typed[i++],
  "lowerFrontLeft": typed[i++],
  "lowerFrontRight": typed[i++],
  "lowerFrontCenter": typed[i++],
  "upperBackSquare": typed[i++],
  "upperBackWide": typed[i++],
  "middleBack": typed[i++],
  "lowerBack": typed[i++],
  "fullBack": typed[i++],
  "leftSleeve": typed[i++],
  "rightSleeve": typed[i++],
  "fullLeftSleeve": typed[i++],
  "fullRightSleeve": typed[i++],
  "rightSide": typed[i++]
} as const;

export { typed as DECO_PLACEMENTS };
