import configureStore from '~p/client/configureStore'

import { PROP_DEF_DICT, PROP_DEFS } from '../common/sheets'
import updater from './updater'
import controlTree from './controlTree'

const store = configureStore({
  updater,
  initialState: {
    sheets: { propDefs: PROP_DEF_DICT },
    sheetLists: { propDefs: PROP_DEFS },
  },
  controlTree,
})

export default store
