// DO NOT EDIT, GENERATED BY "yarn sheets"

import { NavTreeItemRow } from '../typings';

const data = [
  {
    "childId": "product",
    "name": "Product",
    "id": "product"
  },
  {
    "parentId": "product",
    "childId": "sport",
    "name": "Sport",
    "propId": "filter.sport",
    "id": "product.sport",
    "contentType": "TileSelectControl"
  },
  {
    "childId": "colors",
    "name": "Colors",
    "id": "colors"
  },
  {
    "parentId": "colors",
    "childId": "color",
    "name": "Color",
    "propId": "colors.color",
    "id": "colors.color",
    "contentType": "TileSelectControl"
  },
  {
    "childId": "deco",
    "name": "Decoration",
    "id": "deco"
  },
  {
    "parentId": "deco",
    "childId": "package",
    "name": "Technique",
    "propId": "deco.package",
    "id": "deco.package",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "deco",
    "childId": "upperFrontLeft",
    "name": "Upper Front Left Placement",
    "id": "deco.upperFrontLeft"
  },
  {
    "parentId": "deco.upperFrontLeft",
    "childId": "type",
    "name": "Content Type",
    "propId": "deco.upperFrontLeft.type",
    "id": "deco.upperFrontLeft.type",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "deco.upperFrontLeft",
    "childId": "teamName",
    "name": "Team Name",
    "propId": "deco.upperFrontLeft.teamName",
    "id": "deco.upperFrontLeft.teamName",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "deco.upperFrontLeft",
    "childId": "playerName",
    "name": "Player Name",
    "propId": "deco.upperFrontLeft.playerName",
    "id": "deco.upperFrontLeft.playerName",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "deco.upperFrontLeft",
    "childId": "number",
    "name": "Number",
    "propId": "deco.upperFrontLeft.number",
    "id": "deco.upperFrontLeft.number",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "deco.upperFrontLeft",
    "childId": "logo.previewFile",
    "name": "Preview File",
    "propId": "deco.upperFrontLeft.logo.previewFile",
    "id": "deco.upperFrontLeft.logo.previewFile",
    "contentType": "ImageUploadControl"
  },
  {
    "parentId": "deco.upperFrontLeft",
    "childId": "logo.factoryFile",
    "name": "Factory File",
    "propId": "deco.upperFrontLeft.logo.factoryFile",
    "id": "deco.upperFrontLeft.logo.factoryFile",
    "contentType": "FileUploadControl"
  },
  {
    "parentId": "deco.upperFrontLeft",
    "childId": "size",
    "name": "Size",
    "propId": "deco.upperFrontLeft.size",
    "id": "deco.upperFrontLeft.size",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "deco.upperFrontLeft",
    "childId": "font",
    "name": "Font",
    "propId": "deco.upperFrontLeft.font",
    "id": "deco.upperFrontLeft.font",
    "contentType": "FontSelectControl"
  },
  {
    "parentId": "deco.upperFrontLeft",
    "childId": "fill.color",
    "name": "Fill",
    "propId": "deco.upperFrontLeft.fill.color",
    "id": "deco.upperFrontLeft.fill.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "deco.upperFrontLeft",
    "childId": "fill.customColor",
    "name": "Custom Color",
    "propId": "deco.upperFrontLeft.fill.customColor",
    "id": "deco.upperFrontLeft.fill.customColor",
    "contentType": "CustomColorControl"
  },
  {
    "parentId": "deco.upperFrontLeft",
    "childId": "outline1.color",
    "name": "Outline 1",
    "propId": "deco.upperFrontLeft.outline1.color",
    "id": "deco.upperFrontLeft.outline1.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "deco.upperFrontLeft",
    "childId": "outline1.customColor",
    "name": "Custom Color",
    "propId": "deco.upperFrontLeft.outline1.customColor",
    "id": "deco.upperFrontLeft.outline1.customColor",
    "contentType": "CustomColorControl"
  },
  {
    "parentId": "deco.upperFrontLeft",
    "childId": "outline2.color",
    "name": "Outline 2",
    "propId": "deco.upperFrontLeft.outline2.color",
    "id": "deco.upperFrontLeft.outline2.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "deco.upperFrontLeft",
    "childId": "outline2.customColor",
    "name": "Custom Color",
    "propId": "deco.upperFrontLeft.outline2.customColor",
    "id": "deco.upperFrontLeft.outline2.customColor",
    "contentType": "CustomColorControl"
  },
  {
    "parentId": "deco",
    "childId": "upperFrontRight",
    "name": "Upper Front Right Placement",
    "id": "deco.upperFrontRight"
  },
  {
    "parentId": "deco.upperFrontRight",
    "childId": "type",
    "name": "Content Type",
    "propId": "deco.upperFrontRight.type",
    "id": "deco.upperFrontRight.type",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "deco.upperFrontRight",
    "childId": "teamName",
    "name": "Team Name",
    "propId": "deco.upperFrontRight.teamName",
    "id": "deco.upperFrontRight.teamName",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "deco.upperFrontRight",
    "childId": "playerName",
    "name": "Player Name",
    "propId": "deco.upperFrontRight.playerName",
    "id": "deco.upperFrontRight.playerName",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "deco.upperFrontRight",
    "childId": "number",
    "name": "Number",
    "propId": "deco.upperFrontRight.number",
    "id": "deco.upperFrontRight.number",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "deco.upperFrontRight",
    "childId": "logo.previewFile",
    "name": "Preview File",
    "propId": "deco.upperFrontRight.logo.previewFile",
    "id": "deco.upperFrontRight.logo.previewFile",
    "contentType": "ImageUploadControl"
  },
  {
    "parentId": "deco.upperFrontRight",
    "childId": "logo.factoryFile",
    "name": "Factory File",
    "propId": "deco.upperFrontRight.logo.factoryFile",
    "id": "deco.upperFrontRight.logo.factoryFile",
    "contentType": "FileUploadControl"
  },
  {
    "parentId": "deco.upperFrontRight",
    "childId": "size",
    "name": "Size",
    "propId": "deco.upperFrontRight.size",
    "id": "deco.upperFrontRight.size",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "deco.upperFrontRight",
    "childId": "font",
    "name": "Font",
    "propId": "deco.upperFrontRight.font",
    "id": "deco.upperFrontRight.font",
    "contentType": "FontSelectControl"
  },
  {
    "parentId": "deco.upperFrontRight",
    "childId": "fill.color",
    "name": "Fill",
    "propId": "deco.upperFrontRight.fill.color",
    "id": "deco.upperFrontRight.fill.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "deco.upperFrontRight",
    "childId": "fill.customColor",
    "name": "Custom Color",
    "propId": "deco.upperFrontRight.fill.customColor",
    "id": "deco.upperFrontRight.fill.customColor",
    "contentType": "CustomColorControl"
  },
  {
    "parentId": "deco.upperFrontRight",
    "childId": "outline1.color",
    "name": "Outline 1",
    "propId": "deco.upperFrontRight.outline1.color",
    "id": "deco.upperFrontRight.outline1.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "deco.upperFrontRight",
    "childId": "outline1.customColor",
    "name": "Custom Color",
    "propId": "deco.upperFrontRight.outline1.customColor",
    "id": "deco.upperFrontRight.outline1.customColor",
    "contentType": "CustomColorControl"
  },
  {
    "parentId": "deco.upperFrontRight",
    "childId": "outline2.color",
    "name": "Outline 2",
    "propId": "deco.upperFrontRight.outline2.color",
    "id": "deco.upperFrontRight.outline2.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "deco.upperFrontRight",
    "childId": "outline2.customColor",
    "name": "Custom Color",
    "propId": "deco.upperFrontRight.outline2.customColor",
    "id": "deco.upperFrontRight.outline2.customColor",
    "contentType": "CustomColorControl"
  },
  {
    "parentId": "deco",
    "childId": "fullFront-teamName",
    "name": "Full Front - Team Name Placement",
    "id": "deco.fullFront-teamName"
  },
  {
    "parentId": "deco.fullFront-teamName",
    "childId": "type",
    "name": "Content Type",
    "propId": "deco.fullFront-teamName.type",
    "id": "deco.fullFront-teamName.type",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "deco.fullFront-teamName",
    "childId": "teamName",
    "name": "Team Name",
    "propId": "deco.fullFront-teamName.teamName",
    "id": "deco.fullFront-teamName.teamName",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "deco.fullFront-teamName",
    "childId": "playerName",
    "name": "Player Name",
    "propId": "deco.fullFront-teamName.playerName",
    "id": "deco.fullFront-teamName.playerName",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "deco.fullFront-teamName",
    "childId": "number",
    "name": "Number",
    "propId": "deco.fullFront-teamName.number",
    "id": "deco.fullFront-teamName.number",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "deco.fullFront-teamName",
    "childId": "logo.previewFile",
    "name": "Preview File",
    "propId": "deco.fullFront-teamName.logo.previewFile",
    "id": "deco.fullFront-teamName.logo.previewFile",
    "contentType": "ImageUploadControl"
  },
  {
    "parentId": "deco.fullFront-teamName",
    "childId": "logo.factoryFile",
    "name": "Factory File",
    "propId": "deco.fullFront-teamName.logo.factoryFile",
    "id": "deco.fullFront-teamName.logo.factoryFile",
    "contentType": "FileUploadControl"
  },
  {
    "parentId": "deco.fullFront-teamName",
    "childId": "size",
    "name": "Size",
    "propId": "deco.fullFront-teamName.size",
    "id": "deco.fullFront-teamName.size",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "deco.fullFront-teamName",
    "childId": "font",
    "name": "Font",
    "propId": "deco.fullFront-teamName.font",
    "id": "deco.fullFront-teamName.font",
    "contentType": "FontSelectControl"
  },
  {
    "parentId": "deco.fullFront-teamName",
    "childId": "fill.color",
    "name": "Fill",
    "propId": "deco.fullFront-teamName.fill.color",
    "id": "deco.fullFront-teamName.fill.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "deco.fullFront-teamName",
    "childId": "fill.customColor",
    "name": "Custom Color",
    "propId": "deco.fullFront-teamName.fill.customColor",
    "id": "deco.fullFront-teamName.fill.customColor",
    "contentType": "CustomColorControl"
  },
  {
    "parentId": "deco.fullFront-teamName",
    "childId": "outline1.color",
    "name": "Outline 1",
    "propId": "deco.fullFront-teamName.outline1.color",
    "id": "deco.fullFront-teamName.outline1.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "deco.fullFront-teamName",
    "childId": "outline1.customColor",
    "name": "Custom Color",
    "propId": "deco.fullFront-teamName.outline1.customColor",
    "id": "deco.fullFront-teamName.outline1.customColor",
    "contentType": "CustomColorControl"
  },
  {
    "parentId": "deco.fullFront-teamName",
    "childId": "outline2.color",
    "name": "Outline 2",
    "propId": "deco.fullFront-teamName.outline2.color",
    "id": "deco.fullFront-teamName.outline2.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "deco.fullFront-teamName",
    "childId": "outline2.customColor",
    "name": "Custom Color",
    "propId": "deco.fullFront-teamName.outline2.customColor",
    "id": "deco.fullFront-teamName.outline2.customColor",
    "contentType": "CustomColorControl"
  },
  {
    "parentId": "deco",
    "childId": "fullFront",
    "name": "Full Front Placement",
    "id": "deco.fullFront"
  },
  {
    "parentId": "deco.fullFront",
    "childId": "type",
    "name": "Content Type",
    "propId": "deco.fullFront.type",
    "id": "deco.fullFront.type",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "deco.fullFront",
    "childId": "teamName",
    "name": "Team Name",
    "propId": "deco.fullFront.teamName",
    "id": "deco.fullFront.teamName",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "deco.fullFront",
    "childId": "playerName",
    "name": "Player Name",
    "propId": "deco.fullFront.playerName",
    "id": "deco.fullFront.playerName",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "deco.fullFront",
    "childId": "number",
    "name": "Number",
    "propId": "deco.fullFront.number",
    "id": "deco.fullFront.number",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "deco.fullFront",
    "childId": "logo.previewFile",
    "name": "Preview File",
    "propId": "deco.fullFront.logo.previewFile",
    "id": "deco.fullFront.logo.previewFile",
    "contentType": "ImageUploadControl"
  },
  {
    "parentId": "deco.fullFront",
    "childId": "logo.factoryFile",
    "name": "Factory File",
    "propId": "deco.fullFront.logo.factoryFile",
    "id": "deco.fullFront.logo.factoryFile",
    "contentType": "FileUploadControl"
  },
  {
    "parentId": "deco.fullFront",
    "childId": "size",
    "name": "Size",
    "propId": "deco.fullFront.size",
    "id": "deco.fullFront.size",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "deco.fullFront",
    "childId": "font",
    "name": "Font",
    "propId": "deco.fullFront.font",
    "id": "deco.fullFront.font",
    "contentType": "FontSelectControl"
  },
  {
    "parentId": "deco.fullFront",
    "childId": "fill.color",
    "name": "Fill",
    "propId": "deco.fullFront.fill.color",
    "id": "deco.fullFront.fill.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "deco.fullFront",
    "childId": "fill.customColor",
    "name": "Custom Color",
    "propId": "deco.fullFront.fill.customColor",
    "id": "deco.fullFront.fill.customColor",
    "contentType": "CustomColorControl"
  },
  {
    "parentId": "deco.fullFront",
    "childId": "outline1.color",
    "name": "Outline 1",
    "propId": "deco.fullFront.outline1.color",
    "id": "deco.fullFront.outline1.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "deco.fullFront",
    "childId": "outline1.customColor",
    "name": "Custom Color",
    "propId": "deco.fullFront.outline1.customColor",
    "id": "deco.fullFront.outline1.customColor",
    "contentType": "CustomColorControl"
  },
  {
    "parentId": "deco.fullFront",
    "childId": "outline2.color",
    "name": "Outline 2",
    "propId": "deco.fullFront.outline2.color",
    "id": "deco.fullFront.outline2.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "deco.fullFront",
    "childId": "outline2.customColor",
    "name": "Custom Color",
    "propId": "deco.fullFront.outline2.customColor",
    "id": "deco.fullFront.outline2.customColor",
    "contentType": "CustomColorControl"
  },
  {
    "parentId": "deco",
    "childId": "lowerFrontLeft",
    "name": "Lower Front Left Placement",
    "id": "deco.lowerFrontLeft"
  },
  {
    "parentId": "deco.lowerFrontLeft",
    "childId": "type",
    "name": "Content Type",
    "propId": "deco.lowerFrontLeft.type",
    "id": "deco.lowerFrontLeft.type",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "deco.lowerFrontLeft",
    "childId": "teamName",
    "name": "Team Name",
    "propId": "deco.lowerFrontLeft.teamName",
    "id": "deco.lowerFrontLeft.teamName",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "deco.lowerFrontLeft",
    "childId": "playerName",
    "name": "Player Name",
    "propId": "deco.lowerFrontLeft.playerName",
    "id": "deco.lowerFrontLeft.playerName",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "deco.lowerFrontLeft",
    "childId": "number",
    "name": "Number",
    "propId": "deco.lowerFrontLeft.number",
    "id": "deco.lowerFrontLeft.number",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "deco.lowerFrontLeft",
    "childId": "logo.previewFile",
    "name": "Preview File",
    "propId": "deco.lowerFrontLeft.logo.previewFile",
    "id": "deco.lowerFrontLeft.logo.previewFile",
    "contentType": "ImageUploadControl"
  },
  {
    "parentId": "deco.lowerFrontLeft",
    "childId": "logo.factoryFile",
    "name": "Factory File",
    "propId": "deco.lowerFrontLeft.logo.factoryFile",
    "id": "deco.lowerFrontLeft.logo.factoryFile",
    "contentType": "FileUploadControl"
  },
  {
    "parentId": "deco.lowerFrontLeft",
    "childId": "size",
    "name": "Size",
    "propId": "deco.lowerFrontLeft.size",
    "id": "deco.lowerFrontLeft.size",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "deco.lowerFrontLeft",
    "childId": "font",
    "name": "Font",
    "propId": "deco.lowerFrontLeft.font",
    "id": "deco.lowerFrontLeft.font",
    "contentType": "FontSelectControl"
  },
  {
    "parentId": "deco.lowerFrontLeft",
    "childId": "fill.color",
    "name": "Fill",
    "propId": "deco.lowerFrontLeft.fill.color",
    "id": "deco.lowerFrontLeft.fill.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "deco.lowerFrontLeft",
    "childId": "fill.customColor",
    "name": "Custom Color",
    "propId": "deco.lowerFrontLeft.fill.customColor",
    "id": "deco.lowerFrontLeft.fill.customColor",
    "contentType": "CustomColorControl"
  },
  {
    "parentId": "deco.lowerFrontLeft",
    "childId": "outline1.color",
    "name": "Outline 1",
    "propId": "deco.lowerFrontLeft.outline1.color",
    "id": "deco.lowerFrontLeft.outline1.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "deco.lowerFrontLeft",
    "childId": "outline1.customColor",
    "name": "Custom Color",
    "propId": "deco.lowerFrontLeft.outline1.customColor",
    "id": "deco.lowerFrontLeft.outline1.customColor",
    "contentType": "CustomColorControl"
  },
  {
    "parentId": "deco.lowerFrontLeft",
    "childId": "outline2.color",
    "name": "Outline 2",
    "propId": "deco.lowerFrontLeft.outline2.color",
    "id": "deco.lowerFrontLeft.outline2.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "deco.lowerFrontLeft",
    "childId": "outline2.customColor",
    "name": "Custom Color",
    "propId": "deco.lowerFrontLeft.outline2.customColor",
    "id": "deco.lowerFrontLeft.outline2.customColor",
    "contentType": "CustomColorControl"
  },
  {
    "parentId": "deco",
    "childId": "lowerFrontRight",
    "name": "Lower Front Right Placement",
    "id": "deco.lowerFrontRight"
  },
  {
    "parentId": "deco.lowerFrontRight",
    "childId": "type",
    "name": "Content Type",
    "propId": "deco.lowerFrontRight.type",
    "id": "deco.lowerFrontRight.type",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "deco.lowerFrontRight",
    "childId": "teamName",
    "name": "Team Name",
    "propId": "deco.lowerFrontRight.teamName",
    "id": "deco.lowerFrontRight.teamName",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "deco.lowerFrontRight",
    "childId": "playerName",
    "name": "Player Name",
    "propId": "deco.lowerFrontRight.playerName",
    "id": "deco.lowerFrontRight.playerName",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "deco.lowerFrontRight",
    "childId": "number",
    "name": "Number",
    "propId": "deco.lowerFrontRight.number",
    "id": "deco.lowerFrontRight.number",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "deco.lowerFrontRight",
    "childId": "logo.previewFile",
    "name": "Preview File",
    "propId": "deco.lowerFrontRight.logo.previewFile",
    "id": "deco.lowerFrontRight.logo.previewFile",
    "contentType": "ImageUploadControl"
  },
  {
    "parentId": "deco.lowerFrontRight",
    "childId": "logo.factoryFile",
    "name": "Factory File",
    "propId": "deco.lowerFrontRight.logo.factoryFile",
    "id": "deco.lowerFrontRight.logo.factoryFile",
    "contentType": "FileUploadControl"
  },
  {
    "parentId": "deco.lowerFrontRight",
    "childId": "size",
    "name": "Size",
    "propId": "deco.lowerFrontRight.size",
    "id": "deco.lowerFrontRight.size",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "deco.lowerFrontRight",
    "childId": "font",
    "name": "Font",
    "propId": "deco.lowerFrontRight.font",
    "id": "deco.lowerFrontRight.font",
    "contentType": "FontSelectControl"
  },
  {
    "parentId": "deco.lowerFrontRight",
    "childId": "fill.color",
    "name": "Fill",
    "propId": "deco.lowerFrontRight.fill.color",
    "id": "deco.lowerFrontRight.fill.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "deco.lowerFrontRight",
    "childId": "fill.customColor",
    "name": "Custom Color",
    "propId": "deco.lowerFrontRight.fill.customColor",
    "id": "deco.lowerFrontRight.fill.customColor",
    "contentType": "CustomColorControl"
  },
  {
    "parentId": "deco.lowerFrontRight",
    "childId": "outline1.color",
    "name": "Outline 1",
    "propId": "deco.lowerFrontRight.outline1.color",
    "id": "deco.lowerFrontRight.outline1.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "deco.lowerFrontRight",
    "childId": "outline1.customColor",
    "name": "Custom Color",
    "propId": "deco.lowerFrontRight.outline1.customColor",
    "id": "deco.lowerFrontRight.outline1.customColor",
    "contentType": "CustomColorControl"
  },
  {
    "parentId": "deco.lowerFrontRight",
    "childId": "outline2.color",
    "name": "Outline 2",
    "propId": "deco.lowerFrontRight.outline2.color",
    "id": "deco.lowerFrontRight.outline2.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "deco.lowerFrontRight",
    "childId": "outline2.customColor",
    "name": "Custom Color",
    "propId": "deco.lowerFrontRight.outline2.customColor",
    "id": "deco.lowerFrontRight.outline2.customColor",
    "contentType": "CustomColorControl"
  },
  {
    "parentId": "deco",
    "childId": "lowerFrontCenter",
    "name": "Lower Front Center Placement",
    "id": "deco.lowerFrontCenter"
  },
  {
    "parentId": "deco.lowerFrontCenter",
    "childId": "type",
    "name": "Content Type",
    "propId": "deco.lowerFrontCenter.type",
    "id": "deco.lowerFrontCenter.type",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "deco.lowerFrontCenter",
    "childId": "teamName",
    "name": "Team Name",
    "propId": "deco.lowerFrontCenter.teamName",
    "id": "deco.lowerFrontCenter.teamName",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "deco.lowerFrontCenter",
    "childId": "playerName",
    "name": "Player Name",
    "propId": "deco.lowerFrontCenter.playerName",
    "id": "deco.lowerFrontCenter.playerName",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "deco.lowerFrontCenter",
    "childId": "number",
    "name": "Number",
    "propId": "deco.lowerFrontCenter.number",
    "id": "deco.lowerFrontCenter.number",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "deco.lowerFrontCenter",
    "childId": "logo.previewFile",
    "name": "Preview File",
    "propId": "deco.lowerFrontCenter.logo.previewFile",
    "id": "deco.lowerFrontCenter.logo.previewFile",
    "contentType": "ImageUploadControl"
  },
  {
    "parentId": "deco.lowerFrontCenter",
    "childId": "logo.factoryFile",
    "name": "Factory File",
    "propId": "deco.lowerFrontCenter.logo.factoryFile",
    "id": "deco.lowerFrontCenter.logo.factoryFile",
    "contentType": "FileUploadControl"
  },
  {
    "parentId": "deco.lowerFrontCenter",
    "childId": "size",
    "name": "Size",
    "propId": "deco.lowerFrontCenter.size",
    "id": "deco.lowerFrontCenter.size",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "deco.lowerFrontCenter",
    "childId": "font",
    "name": "Font",
    "propId": "deco.lowerFrontCenter.font",
    "id": "deco.lowerFrontCenter.font",
    "contentType": "FontSelectControl"
  },
  {
    "parentId": "deco.lowerFrontCenter",
    "childId": "fill.color",
    "name": "Fill",
    "propId": "deco.lowerFrontCenter.fill.color",
    "id": "deco.lowerFrontCenter.fill.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "deco.lowerFrontCenter",
    "childId": "fill.customColor",
    "name": "Custom Color",
    "propId": "deco.lowerFrontCenter.fill.customColor",
    "id": "deco.lowerFrontCenter.fill.customColor",
    "contentType": "CustomColorControl"
  },
  {
    "parentId": "deco.lowerFrontCenter",
    "childId": "outline1.color",
    "name": "Outline 1",
    "propId": "deco.lowerFrontCenter.outline1.color",
    "id": "deco.lowerFrontCenter.outline1.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "deco.lowerFrontCenter",
    "childId": "outline1.customColor",
    "name": "Custom Color",
    "propId": "deco.lowerFrontCenter.outline1.customColor",
    "id": "deco.lowerFrontCenter.outline1.customColor",
    "contentType": "CustomColorControl"
  },
  {
    "parentId": "deco.lowerFrontCenter",
    "childId": "outline2.color",
    "name": "Outline 2",
    "propId": "deco.lowerFrontCenter.outline2.color",
    "id": "deco.lowerFrontCenter.outline2.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "deco.lowerFrontCenter",
    "childId": "outline2.customColor",
    "name": "Custom Color",
    "propId": "deco.lowerFrontCenter.outline2.customColor",
    "id": "deco.lowerFrontCenter.outline2.customColor",
    "contentType": "CustomColorControl"
  },
  {
    "parentId": "deco",
    "childId": "upperBackSquare",
    "name": "Upper Back Square Placement",
    "id": "deco.upperBackSquare"
  },
  {
    "parentId": "deco.upperBackSquare",
    "childId": "type",
    "name": "Content Type",
    "propId": "deco.upperBackSquare.type",
    "id": "deco.upperBackSquare.type",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "deco.upperBackSquare",
    "childId": "teamName",
    "name": "Team Name",
    "propId": "deco.upperBackSquare.teamName",
    "id": "deco.upperBackSquare.teamName",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "deco.upperBackSquare",
    "childId": "playerName",
    "name": "Player Name",
    "propId": "deco.upperBackSquare.playerName",
    "id": "deco.upperBackSquare.playerName",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "deco.upperBackSquare",
    "childId": "number",
    "name": "Number",
    "propId": "deco.upperBackSquare.number",
    "id": "deco.upperBackSquare.number",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "deco.upperBackSquare",
    "childId": "logo.previewFile",
    "name": "Preview File",
    "propId": "deco.upperBackSquare.logo.previewFile",
    "id": "deco.upperBackSquare.logo.previewFile",
    "contentType": "ImageUploadControl"
  },
  {
    "parentId": "deco.upperBackSquare",
    "childId": "logo.factoryFile",
    "name": "Factory File",
    "propId": "deco.upperBackSquare.logo.factoryFile",
    "id": "deco.upperBackSquare.logo.factoryFile",
    "contentType": "FileUploadControl"
  },
  {
    "parentId": "deco.upperBackSquare",
    "childId": "size",
    "name": "Size",
    "propId": "deco.upperBackSquare.size",
    "id": "deco.upperBackSquare.size",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "deco.upperBackSquare",
    "childId": "font",
    "name": "Font",
    "propId": "deco.upperBackSquare.font",
    "id": "deco.upperBackSquare.font",
    "contentType": "FontSelectControl"
  },
  {
    "parentId": "deco.upperBackSquare",
    "childId": "fill.color",
    "name": "Fill",
    "propId": "deco.upperBackSquare.fill.color",
    "id": "deco.upperBackSquare.fill.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "deco.upperBackSquare",
    "childId": "fill.customColor",
    "name": "Custom Color",
    "propId": "deco.upperBackSquare.fill.customColor",
    "id": "deco.upperBackSquare.fill.customColor",
    "contentType": "CustomColorControl"
  },
  {
    "parentId": "deco.upperBackSquare",
    "childId": "outline1.color",
    "name": "Outline 1",
    "propId": "deco.upperBackSquare.outline1.color",
    "id": "deco.upperBackSquare.outline1.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "deco.upperBackSquare",
    "childId": "outline1.customColor",
    "name": "Custom Color",
    "propId": "deco.upperBackSquare.outline1.customColor",
    "id": "deco.upperBackSquare.outline1.customColor",
    "contentType": "CustomColorControl"
  },
  {
    "parentId": "deco.upperBackSquare",
    "childId": "outline2.color",
    "name": "Outline 2",
    "propId": "deco.upperBackSquare.outline2.color",
    "id": "deco.upperBackSquare.outline2.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "deco.upperBackSquare",
    "childId": "outline2.customColor",
    "name": "Custom Color",
    "propId": "deco.upperBackSquare.outline2.customColor",
    "id": "deco.upperBackSquare.outline2.customColor",
    "contentType": "CustomColorControl"
  },
  {
    "parentId": "deco",
    "childId": "upperBackWide",
    "name": "Upper Back Wide Placement",
    "id": "deco.upperBackWide"
  },
  {
    "parentId": "deco.upperBackWide",
    "childId": "type",
    "name": "Content Type",
    "propId": "deco.upperBackWide.type",
    "id": "deco.upperBackWide.type",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "deco.upperBackWide",
    "childId": "teamName",
    "name": "Team Name",
    "propId": "deco.upperBackWide.teamName",
    "id": "deco.upperBackWide.teamName",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "deco.upperBackWide",
    "childId": "playerName",
    "name": "Player Name",
    "propId": "deco.upperBackWide.playerName",
    "id": "deco.upperBackWide.playerName",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "deco.upperBackWide",
    "childId": "number",
    "name": "Number",
    "propId": "deco.upperBackWide.number",
    "id": "deco.upperBackWide.number",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "deco.upperBackWide",
    "childId": "logo.previewFile",
    "name": "Preview File",
    "propId": "deco.upperBackWide.logo.previewFile",
    "id": "deco.upperBackWide.logo.previewFile",
    "contentType": "ImageUploadControl"
  },
  {
    "parentId": "deco.upperBackWide",
    "childId": "logo.factoryFile",
    "name": "Factory File",
    "propId": "deco.upperBackWide.logo.factoryFile",
    "id": "deco.upperBackWide.logo.factoryFile",
    "contentType": "FileUploadControl"
  },
  {
    "parentId": "deco.upperBackWide",
    "childId": "size",
    "name": "Size",
    "propId": "deco.upperBackWide.size",
    "id": "deco.upperBackWide.size",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "deco.upperBackWide",
    "childId": "font",
    "name": "Font",
    "propId": "deco.upperBackWide.font",
    "id": "deco.upperBackWide.font",
    "contentType": "FontSelectControl"
  },
  {
    "parentId": "deco.upperBackWide",
    "childId": "fill.color",
    "name": "Fill",
    "propId": "deco.upperBackWide.fill.color",
    "id": "deco.upperBackWide.fill.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "deco.upperBackWide",
    "childId": "fill.customColor",
    "name": "Custom Color",
    "propId": "deco.upperBackWide.fill.customColor",
    "id": "deco.upperBackWide.fill.customColor",
    "contentType": "CustomColorControl"
  },
  {
    "parentId": "deco.upperBackWide",
    "childId": "outline1.color",
    "name": "Outline 1",
    "propId": "deco.upperBackWide.outline1.color",
    "id": "deco.upperBackWide.outline1.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "deco.upperBackWide",
    "childId": "outline1.customColor",
    "name": "Custom Color",
    "propId": "deco.upperBackWide.outline1.customColor",
    "id": "deco.upperBackWide.outline1.customColor",
    "contentType": "CustomColorControl"
  },
  {
    "parentId": "deco.upperBackWide",
    "childId": "outline2.color",
    "name": "Outline 2",
    "propId": "deco.upperBackWide.outline2.color",
    "id": "deco.upperBackWide.outline2.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "deco.upperBackWide",
    "childId": "outline2.customColor",
    "name": "Custom Color",
    "propId": "deco.upperBackWide.outline2.customColor",
    "id": "deco.upperBackWide.outline2.customColor",
    "contentType": "CustomColorControl"
  },
  {
    "parentId": "deco",
    "childId": "middleBack",
    "name": "Middle Back Placement",
    "id": "deco.middleBack"
  },
  {
    "parentId": "deco.middleBack",
    "childId": "type",
    "name": "Content Type",
    "propId": "deco.middleBack.type",
    "id": "deco.middleBack.type",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "deco.middleBack",
    "childId": "teamName",
    "name": "Team Name",
    "propId": "deco.middleBack.teamName",
    "id": "deco.middleBack.teamName",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "deco.middleBack",
    "childId": "playerName",
    "name": "Player Name",
    "propId": "deco.middleBack.playerName",
    "id": "deco.middleBack.playerName",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "deco.middleBack",
    "childId": "number",
    "name": "Number",
    "propId": "deco.middleBack.number",
    "id": "deco.middleBack.number",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "deco.middleBack",
    "childId": "logo.previewFile",
    "name": "Preview File",
    "propId": "deco.middleBack.logo.previewFile",
    "id": "deco.middleBack.logo.previewFile",
    "contentType": "ImageUploadControl"
  },
  {
    "parentId": "deco.middleBack",
    "childId": "logo.factoryFile",
    "name": "Factory File",
    "propId": "deco.middleBack.logo.factoryFile",
    "id": "deco.middleBack.logo.factoryFile",
    "contentType": "FileUploadControl"
  },
  {
    "parentId": "deco.middleBack",
    "childId": "size",
    "name": "Size",
    "propId": "deco.middleBack.size",
    "id": "deco.middleBack.size",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "deco.middleBack",
    "childId": "font",
    "name": "Font",
    "propId": "deco.middleBack.font",
    "id": "deco.middleBack.font",
    "contentType": "FontSelectControl"
  },
  {
    "parentId": "deco.middleBack",
    "childId": "fill.color",
    "name": "Fill",
    "propId": "deco.middleBack.fill.color",
    "id": "deco.middleBack.fill.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "deco.middleBack",
    "childId": "fill.customColor",
    "name": "Custom Color",
    "propId": "deco.middleBack.fill.customColor",
    "id": "deco.middleBack.fill.customColor",
    "contentType": "CustomColorControl"
  },
  {
    "parentId": "deco.middleBack",
    "childId": "outline1.color",
    "name": "Outline 1",
    "propId": "deco.middleBack.outline1.color",
    "id": "deco.middleBack.outline1.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "deco.middleBack",
    "childId": "outline1.customColor",
    "name": "Custom Color",
    "propId": "deco.middleBack.outline1.customColor",
    "id": "deco.middleBack.outline1.customColor",
    "contentType": "CustomColorControl"
  },
  {
    "parentId": "deco.middleBack",
    "childId": "outline2.color",
    "name": "Outline 2",
    "propId": "deco.middleBack.outline2.color",
    "id": "deco.middleBack.outline2.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "deco.middleBack",
    "childId": "outline2.customColor",
    "name": "Custom Color",
    "propId": "deco.middleBack.outline2.customColor",
    "id": "deco.middleBack.outline2.customColor",
    "contentType": "CustomColorControl"
  },
  {
    "parentId": "deco",
    "childId": "lowerBack",
    "name": "Lower Back Placement",
    "id": "deco.lowerBack"
  },
  {
    "parentId": "deco.lowerBack",
    "childId": "type",
    "name": "Content Type",
    "propId": "deco.lowerBack.type",
    "id": "deco.lowerBack.type",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "deco.lowerBack",
    "childId": "teamName",
    "name": "Team Name",
    "propId": "deco.lowerBack.teamName",
    "id": "deco.lowerBack.teamName",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "deco.lowerBack",
    "childId": "playerName",
    "name": "Player Name",
    "propId": "deco.lowerBack.playerName",
    "id": "deco.lowerBack.playerName",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "deco.lowerBack",
    "childId": "number",
    "name": "Number",
    "propId": "deco.lowerBack.number",
    "id": "deco.lowerBack.number",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "deco.lowerBack",
    "childId": "logo.previewFile",
    "name": "Preview File",
    "propId": "deco.lowerBack.logo.previewFile",
    "id": "deco.lowerBack.logo.previewFile",
    "contentType": "ImageUploadControl"
  },
  {
    "parentId": "deco.lowerBack",
    "childId": "logo.factoryFile",
    "name": "Factory File",
    "propId": "deco.lowerBack.logo.factoryFile",
    "id": "deco.lowerBack.logo.factoryFile",
    "contentType": "FileUploadControl"
  },
  {
    "parentId": "deco.lowerBack",
    "childId": "size",
    "name": "Size",
    "propId": "deco.lowerBack.size",
    "id": "deco.lowerBack.size",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "deco.lowerBack",
    "childId": "font",
    "name": "Font",
    "propId": "deco.lowerBack.font",
    "id": "deco.lowerBack.font",
    "contentType": "FontSelectControl"
  },
  {
    "parentId": "deco.lowerBack",
    "childId": "fill.color",
    "name": "Fill",
    "propId": "deco.lowerBack.fill.color",
    "id": "deco.lowerBack.fill.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "deco.lowerBack",
    "childId": "fill.customColor",
    "name": "Custom Color",
    "propId": "deco.lowerBack.fill.customColor",
    "id": "deco.lowerBack.fill.customColor",
    "contentType": "CustomColorControl"
  },
  {
    "parentId": "deco.lowerBack",
    "childId": "outline1.color",
    "name": "Outline 1",
    "propId": "deco.lowerBack.outline1.color",
    "id": "deco.lowerBack.outline1.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "deco.lowerBack",
    "childId": "outline1.customColor",
    "name": "Custom Color",
    "propId": "deco.lowerBack.outline1.customColor",
    "id": "deco.lowerBack.outline1.customColor",
    "contentType": "CustomColorControl"
  },
  {
    "parentId": "deco.lowerBack",
    "childId": "outline2.color",
    "name": "Outline 2",
    "propId": "deco.lowerBack.outline2.color",
    "id": "deco.lowerBack.outline2.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "deco.lowerBack",
    "childId": "outline2.customColor",
    "name": "Custom Color",
    "propId": "deco.lowerBack.outline2.customColor",
    "id": "deco.lowerBack.outline2.customColor",
    "contentType": "CustomColorControl"
  },
  {
    "parentId": "deco",
    "childId": "fullBack",
    "name": "Full Back Placement",
    "id": "deco.fullBack"
  },
  {
    "parentId": "deco.fullBack",
    "childId": "type",
    "name": "Content Type",
    "propId": "deco.fullBack.type",
    "id": "deco.fullBack.type",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "deco.fullBack",
    "childId": "teamName",
    "name": "Team Name",
    "propId": "deco.fullBack.teamName",
    "id": "deco.fullBack.teamName",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "deco.fullBack",
    "childId": "playerName",
    "name": "Player Name",
    "propId": "deco.fullBack.playerName",
    "id": "deco.fullBack.playerName",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "deco.fullBack",
    "childId": "number",
    "name": "Number",
    "propId": "deco.fullBack.number",
    "id": "deco.fullBack.number",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "deco.fullBack",
    "childId": "logo.previewFile",
    "name": "Preview File",
    "propId": "deco.fullBack.logo.previewFile",
    "id": "deco.fullBack.logo.previewFile",
    "contentType": "ImageUploadControl"
  },
  {
    "parentId": "deco.fullBack",
    "childId": "logo.factoryFile",
    "name": "Factory File",
    "propId": "deco.fullBack.logo.factoryFile",
    "id": "deco.fullBack.logo.factoryFile",
    "contentType": "FileUploadControl"
  },
  {
    "parentId": "deco.fullBack",
    "childId": "size",
    "name": "Size",
    "propId": "deco.fullBack.size",
    "id": "deco.fullBack.size",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "deco.fullBack",
    "childId": "font",
    "name": "Font",
    "propId": "deco.fullBack.font",
    "id": "deco.fullBack.font",
    "contentType": "FontSelectControl"
  },
  {
    "parentId": "deco.fullBack",
    "childId": "fill.color",
    "name": "Fill",
    "propId": "deco.fullBack.fill.color",
    "id": "deco.fullBack.fill.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "deco.fullBack",
    "childId": "fill.customColor",
    "name": "Custom Color",
    "propId": "deco.fullBack.fill.customColor",
    "id": "deco.fullBack.fill.customColor",
    "contentType": "CustomColorControl"
  },
  {
    "parentId": "deco.fullBack",
    "childId": "outline1.color",
    "name": "Outline 1",
    "propId": "deco.fullBack.outline1.color",
    "id": "deco.fullBack.outline1.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "deco.fullBack",
    "childId": "outline1.customColor",
    "name": "Custom Color",
    "propId": "deco.fullBack.outline1.customColor",
    "id": "deco.fullBack.outline1.customColor",
    "contentType": "CustomColorControl"
  },
  {
    "parentId": "deco.fullBack",
    "childId": "outline2.color",
    "name": "Outline 2",
    "propId": "deco.fullBack.outline2.color",
    "id": "deco.fullBack.outline2.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "deco.fullBack",
    "childId": "outline2.customColor",
    "name": "Custom Color",
    "propId": "deco.fullBack.outline2.customColor",
    "id": "deco.fullBack.outline2.customColor",
    "contentType": "CustomColorControl"
  },
  {
    "parentId": "deco",
    "childId": "leftSleeve",
    "name": "Left Sleeve Placement",
    "id": "deco.leftSleeve"
  },
  {
    "parentId": "deco.leftSleeve",
    "childId": "type",
    "name": "Content Type",
    "propId": "deco.leftSleeve.type",
    "id": "deco.leftSleeve.type",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "deco.leftSleeve",
    "childId": "teamName",
    "name": "Team Name",
    "propId": "deco.leftSleeve.teamName",
    "id": "deco.leftSleeve.teamName",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "deco.leftSleeve",
    "childId": "playerName",
    "name": "Player Name",
    "propId": "deco.leftSleeve.playerName",
    "id": "deco.leftSleeve.playerName",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "deco.leftSleeve",
    "childId": "number",
    "name": "Number",
    "propId": "deco.leftSleeve.number",
    "id": "deco.leftSleeve.number",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "deco.leftSleeve",
    "childId": "logo.previewFile",
    "name": "Preview File",
    "propId": "deco.leftSleeve.logo.previewFile",
    "id": "deco.leftSleeve.logo.previewFile",
    "contentType": "ImageUploadControl"
  },
  {
    "parentId": "deco.leftSleeve",
    "childId": "logo.factoryFile",
    "name": "Factory File",
    "propId": "deco.leftSleeve.logo.factoryFile",
    "id": "deco.leftSleeve.logo.factoryFile",
    "contentType": "FileUploadControl"
  },
  {
    "parentId": "deco.leftSleeve",
    "childId": "size",
    "name": "Size",
    "propId": "deco.leftSleeve.size",
    "id": "deco.leftSleeve.size",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "deco.leftSleeve",
    "childId": "font",
    "name": "Font",
    "propId": "deco.leftSleeve.font",
    "id": "deco.leftSleeve.font",
    "contentType": "FontSelectControl"
  },
  {
    "parentId": "deco.leftSleeve",
    "childId": "fill.color",
    "name": "Fill",
    "propId": "deco.leftSleeve.fill.color",
    "id": "deco.leftSleeve.fill.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "deco.leftSleeve",
    "childId": "fill.customColor",
    "name": "Custom Color",
    "propId": "deco.leftSleeve.fill.customColor",
    "id": "deco.leftSleeve.fill.customColor",
    "contentType": "CustomColorControl"
  },
  {
    "parentId": "deco.leftSleeve",
    "childId": "outline1.color",
    "name": "Outline 1",
    "propId": "deco.leftSleeve.outline1.color",
    "id": "deco.leftSleeve.outline1.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "deco.leftSleeve",
    "childId": "outline1.customColor",
    "name": "Custom Color",
    "propId": "deco.leftSleeve.outline1.customColor",
    "id": "deco.leftSleeve.outline1.customColor",
    "contentType": "CustomColorControl"
  },
  {
    "parentId": "deco.leftSleeve",
    "childId": "outline2.color",
    "name": "Outline 2",
    "propId": "deco.leftSleeve.outline2.color",
    "id": "deco.leftSleeve.outline2.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "deco.leftSleeve",
    "childId": "outline2.customColor",
    "name": "Custom Color",
    "propId": "deco.leftSleeve.outline2.customColor",
    "id": "deco.leftSleeve.outline2.customColor",
    "contentType": "CustomColorControl"
  },
  {
    "parentId": "deco",
    "childId": "rightSleeve",
    "name": "Right Sleeve Placement",
    "id": "deco.rightSleeve"
  },
  {
    "parentId": "deco.rightSleeve",
    "childId": "type",
    "name": "Content Type",
    "propId": "deco.rightSleeve.type",
    "id": "deco.rightSleeve.type",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "deco.rightSleeve",
    "childId": "teamName",
    "name": "Team Name",
    "propId": "deco.rightSleeve.teamName",
    "id": "deco.rightSleeve.teamName",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "deco.rightSleeve",
    "childId": "playerName",
    "name": "Player Name",
    "propId": "deco.rightSleeve.playerName",
    "id": "deco.rightSleeve.playerName",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "deco.rightSleeve",
    "childId": "number",
    "name": "Number",
    "propId": "deco.rightSleeve.number",
    "id": "deco.rightSleeve.number",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "deco.rightSleeve",
    "childId": "logo.previewFile",
    "name": "Preview File",
    "propId": "deco.rightSleeve.logo.previewFile",
    "id": "deco.rightSleeve.logo.previewFile",
    "contentType": "ImageUploadControl"
  },
  {
    "parentId": "deco.rightSleeve",
    "childId": "logo.factoryFile",
    "name": "Factory File",
    "propId": "deco.rightSleeve.logo.factoryFile",
    "id": "deco.rightSleeve.logo.factoryFile",
    "contentType": "FileUploadControl"
  },
  {
    "parentId": "deco.rightSleeve",
    "childId": "size",
    "name": "Size",
    "propId": "deco.rightSleeve.size",
    "id": "deco.rightSleeve.size",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "deco.rightSleeve",
    "childId": "font",
    "name": "Font",
    "propId": "deco.rightSleeve.font",
    "id": "deco.rightSleeve.font",
    "contentType": "FontSelectControl"
  },
  {
    "parentId": "deco.rightSleeve",
    "childId": "fill.color",
    "name": "Fill",
    "propId": "deco.rightSleeve.fill.color",
    "id": "deco.rightSleeve.fill.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "deco.rightSleeve",
    "childId": "fill.customColor",
    "name": "Custom Color",
    "propId": "deco.rightSleeve.fill.customColor",
    "id": "deco.rightSleeve.fill.customColor",
    "contentType": "CustomColorControl"
  },
  {
    "parentId": "deco.rightSleeve",
    "childId": "outline1.color",
    "name": "Outline 1",
    "propId": "deco.rightSleeve.outline1.color",
    "id": "deco.rightSleeve.outline1.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "deco.rightSleeve",
    "childId": "outline1.customColor",
    "name": "Custom Color",
    "propId": "deco.rightSleeve.outline1.customColor",
    "id": "deco.rightSleeve.outline1.customColor",
    "contentType": "CustomColorControl"
  },
  {
    "parentId": "deco.rightSleeve",
    "childId": "outline2.color",
    "name": "Outline 2",
    "propId": "deco.rightSleeve.outline2.color",
    "id": "deco.rightSleeve.outline2.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "deco.rightSleeve",
    "childId": "outline2.customColor",
    "name": "Custom Color",
    "propId": "deco.rightSleeve.outline2.customColor",
    "id": "deco.rightSleeve.outline2.customColor",
    "contentType": "CustomColorControl"
  },
  {
    "parentId": "deco",
    "childId": "fullLeftSleeve",
    "name": "Full Left Sleeve Placement",
    "id": "deco.fullLeftSleeve"
  },
  {
    "parentId": "deco.fullLeftSleeve",
    "childId": "type",
    "name": "Content Type",
    "propId": "deco.fullLeftSleeve.type",
    "id": "deco.fullLeftSleeve.type",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "deco.fullLeftSleeve",
    "childId": "teamName",
    "name": "Team Name",
    "propId": "deco.fullLeftSleeve.teamName",
    "id": "deco.fullLeftSleeve.teamName",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "deco.fullLeftSleeve",
    "childId": "playerName",
    "name": "Player Name",
    "propId": "deco.fullLeftSleeve.playerName",
    "id": "deco.fullLeftSleeve.playerName",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "deco.fullLeftSleeve",
    "childId": "number",
    "name": "Number",
    "propId": "deco.fullLeftSleeve.number",
    "id": "deco.fullLeftSleeve.number",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "deco.fullLeftSleeve",
    "childId": "logo.previewFile",
    "name": "Preview File",
    "propId": "deco.fullLeftSleeve.logo.previewFile",
    "id": "deco.fullLeftSleeve.logo.previewFile",
    "contentType": "ImageUploadControl"
  },
  {
    "parentId": "deco.fullLeftSleeve",
    "childId": "logo.factoryFile",
    "name": "Factory File",
    "propId": "deco.fullLeftSleeve.logo.factoryFile",
    "id": "deco.fullLeftSleeve.logo.factoryFile",
    "contentType": "FileUploadControl"
  },
  {
    "parentId": "deco.fullLeftSleeve",
    "childId": "size",
    "name": "Size",
    "propId": "deco.fullLeftSleeve.size",
    "id": "deco.fullLeftSleeve.size",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "deco.fullLeftSleeve",
    "childId": "font",
    "name": "Font",
    "propId": "deco.fullLeftSleeve.font",
    "id": "deco.fullLeftSleeve.font",
    "contentType": "FontSelectControl"
  },
  {
    "parentId": "deco.fullLeftSleeve",
    "childId": "fill.color",
    "name": "Fill",
    "propId": "deco.fullLeftSleeve.fill.color",
    "id": "deco.fullLeftSleeve.fill.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "deco.fullLeftSleeve",
    "childId": "fill.customColor",
    "name": "Custom Color",
    "propId": "deco.fullLeftSleeve.fill.customColor",
    "id": "deco.fullLeftSleeve.fill.customColor",
    "contentType": "CustomColorControl"
  },
  {
    "parentId": "deco.fullLeftSleeve",
    "childId": "outline1.color",
    "name": "Outline 1",
    "propId": "deco.fullLeftSleeve.outline1.color",
    "id": "deco.fullLeftSleeve.outline1.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "deco.fullLeftSleeve",
    "childId": "outline1.customColor",
    "name": "Custom Color",
    "propId": "deco.fullLeftSleeve.outline1.customColor",
    "id": "deco.fullLeftSleeve.outline1.customColor",
    "contentType": "CustomColorControl"
  },
  {
    "parentId": "deco.fullLeftSleeve",
    "childId": "outline2.color",
    "name": "Outline 2",
    "propId": "deco.fullLeftSleeve.outline2.color",
    "id": "deco.fullLeftSleeve.outline2.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "deco.fullLeftSleeve",
    "childId": "outline2.customColor",
    "name": "Custom Color",
    "propId": "deco.fullLeftSleeve.outline2.customColor",
    "id": "deco.fullLeftSleeve.outline2.customColor",
    "contentType": "CustomColorControl"
  },
  {
    "parentId": "deco",
    "childId": "fullRightSleeve",
    "name": "Full Right Sleeve Placement",
    "id": "deco.fullRightSleeve"
  },
  {
    "parentId": "deco.fullRightSleeve",
    "childId": "type",
    "name": "Content Type",
    "propId": "deco.fullRightSleeve.type",
    "id": "deco.fullRightSleeve.type",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "deco.fullRightSleeve",
    "childId": "teamName",
    "name": "Team Name",
    "propId": "deco.fullRightSleeve.teamName",
    "id": "deco.fullRightSleeve.teamName",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "deco.fullRightSleeve",
    "childId": "playerName",
    "name": "Player Name",
    "propId": "deco.fullRightSleeve.playerName",
    "id": "deco.fullRightSleeve.playerName",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "deco.fullRightSleeve",
    "childId": "number",
    "name": "Number",
    "propId": "deco.fullRightSleeve.number",
    "id": "deco.fullRightSleeve.number",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "deco.fullRightSleeve",
    "childId": "logo.previewFile",
    "name": "Preview File",
    "propId": "deco.fullRightSleeve.logo.previewFile",
    "id": "deco.fullRightSleeve.logo.previewFile",
    "contentType": "ImageUploadControl"
  },
  {
    "parentId": "deco.fullRightSleeve",
    "childId": "logo.factoryFile",
    "name": "Factory File",
    "propId": "deco.fullRightSleeve.logo.factoryFile",
    "id": "deco.fullRightSleeve.logo.factoryFile",
    "contentType": "FileUploadControl"
  },
  {
    "parentId": "deco.fullRightSleeve",
    "childId": "size",
    "name": "Size",
    "propId": "deco.fullRightSleeve.size",
    "id": "deco.fullRightSleeve.size",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "deco.fullRightSleeve",
    "childId": "font",
    "name": "Font",
    "propId": "deco.fullRightSleeve.font",
    "id": "deco.fullRightSleeve.font",
    "contentType": "FontSelectControl"
  },
  {
    "parentId": "deco.fullRightSleeve",
    "childId": "fill.color",
    "name": "Fill",
    "propId": "deco.fullRightSleeve.fill.color",
    "id": "deco.fullRightSleeve.fill.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "deco.fullRightSleeve",
    "childId": "fill.customColor",
    "name": "Custom Color",
    "propId": "deco.fullRightSleeve.fill.customColor",
    "id": "deco.fullRightSleeve.fill.customColor",
    "contentType": "CustomColorControl"
  },
  {
    "parentId": "deco.fullRightSleeve",
    "childId": "outline1.color",
    "name": "Outline 1",
    "propId": "deco.fullRightSleeve.outline1.color",
    "id": "deco.fullRightSleeve.outline1.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "deco.fullRightSleeve",
    "childId": "outline1.customColor",
    "name": "Custom Color",
    "propId": "deco.fullRightSleeve.outline1.customColor",
    "id": "deco.fullRightSleeve.outline1.customColor",
    "contentType": "CustomColorControl"
  },
  {
    "parentId": "deco.fullRightSleeve",
    "childId": "outline2.color",
    "name": "Outline 2",
    "propId": "deco.fullRightSleeve.outline2.color",
    "id": "deco.fullRightSleeve.outline2.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "deco.fullRightSleeve",
    "childId": "outline2.customColor",
    "name": "Custom Color",
    "propId": "deco.fullRightSleeve.outline2.customColor",
    "id": "deco.fullRightSleeve.outline2.customColor",
    "contentType": "CustomColorControl"
  },
  {
    "parentId": "deco",
    "childId": "rightSide",
    "name": "Right Side Placement",
    "id": "deco.rightSide"
  },
  {
    "parentId": "deco.rightSide",
    "childId": "type",
    "name": "Content Type",
    "propId": "deco.rightSide.type",
    "id": "deco.rightSide.type",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "deco.rightSide",
    "childId": "teamName",
    "name": "Team Name",
    "propId": "deco.rightSide.teamName",
    "id": "deco.rightSide.teamName",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "deco.rightSide",
    "childId": "playerName",
    "name": "Player Name",
    "propId": "deco.rightSide.playerName",
    "id": "deco.rightSide.playerName",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "deco.rightSide",
    "childId": "number",
    "name": "Number",
    "propId": "deco.rightSide.number",
    "id": "deco.rightSide.number",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "deco.rightSide",
    "childId": "logo.previewFile",
    "name": "Preview File",
    "propId": "deco.rightSide.logo.previewFile",
    "id": "deco.rightSide.logo.previewFile",
    "contentType": "ImageUploadControl"
  },
  {
    "parentId": "deco.rightSide",
    "childId": "logo.factoryFile",
    "name": "Factory File",
    "propId": "deco.rightSide.logo.factoryFile",
    "id": "deco.rightSide.logo.factoryFile",
    "contentType": "FileUploadControl"
  },
  {
    "parentId": "deco.rightSide",
    "childId": "size",
    "name": "Size",
    "propId": "deco.rightSide.size",
    "id": "deco.rightSide.size",
    "contentType": "TileSelectControl"
  },
  {
    "parentId": "deco.rightSide",
    "childId": "font",
    "name": "Font",
    "propId": "deco.rightSide.font",
    "id": "deco.rightSide.font",
    "contentType": "FontSelectControl"
  },
  {
    "parentId": "deco.rightSide",
    "childId": "fill.color",
    "name": "Fill",
    "propId": "deco.rightSide.fill.color",
    "id": "deco.rightSide.fill.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "deco.rightSide",
    "childId": "fill.customColor",
    "name": "Custom Color",
    "propId": "deco.rightSide.fill.customColor",
    "id": "deco.rightSide.fill.customColor",
    "contentType": "CustomColorControl"
  },
  {
    "parentId": "deco.rightSide",
    "childId": "outline1.color",
    "name": "Outline 1",
    "propId": "deco.rightSide.outline1.color",
    "id": "deco.rightSide.outline1.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "deco.rightSide",
    "childId": "outline1.customColor",
    "name": "Custom Color",
    "propId": "deco.rightSide.outline1.customColor",
    "id": "deco.rightSide.outline1.customColor",
    "contentType": "CustomColorControl"
  },
  {
    "parentId": "deco.rightSide",
    "childId": "outline2.color",
    "name": "Outline 2",
    "propId": "deco.rightSide.outline2.color",
    "id": "deco.rightSide.outline2.color",
    "contentType": "ColorSelectControl"
  },
  {
    "parentId": "deco.rightSide",
    "childId": "outline2.customColor",
    "name": "Custom Color",
    "propId": "deco.rightSide.outline2.customColor",
    "id": "deco.rightSide.outline2.customColor",
    "contentType": "CustomColorControl"
  },
  {
    "childId": "details",
    "name": "Roster",
    "id": "details"
  },
  {
    "parentId": "details",
    "childId": "designName",
    "name": "Design Name",
    "propId": "details.recipeName.text",
    "id": "details.designName",
    "contentType": "TextInputControl"
  },
  {
    "parentId": "details",
    "childId": "specialInstructions",
    "name": "Special Instructions",
    "propId": "details.specialInstructions.text",
    "id": "details.specialInstructions",
    "contentType": "TextareaInputControl"
  },
  {
    "parentId": "details",
    "childId": "roster",
    "name": "Team Roster",
    "id": "details.roster",
    "contentType": "RosterControl"
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<NavTreeItemRow[]>> = data;

export type NavTreeItem = typeof typed[number];

export const NAV_TREE_ITEM_INDEX_KEY = "id";
export type NavTreeItemIndexKey = "id";
export type NavTreeItemId = NavTreeItem["id"];

let i = 0;
export const NAV_TREE_ITEM_DICT = {
  "product": typed[i++],
  "product.sport": typed[i++],
  "colors": typed[i++],
  "colors.color": typed[i++],
  "deco": typed[i++],
  "deco.package": typed[i++],
  "deco.upperFrontLeft": typed[i++],
  "deco.upperFrontLeft.type": typed[i++],
  "deco.upperFrontLeft.teamName": typed[i++],
  "deco.upperFrontLeft.playerName": typed[i++],
  "deco.upperFrontLeft.number": typed[i++],
  "deco.upperFrontLeft.logo.previewFile": typed[i++],
  "deco.upperFrontLeft.logo.factoryFile": typed[i++],
  "deco.upperFrontLeft.size": typed[i++],
  "deco.upperFrontLeft.font": typed[i++],
  "deco.upperFrontLeft.fill.color": typed[i++],
  "deco.upperFrontLeft.fill.customColor": typed[i++],
  "deco.upperFrontLeft.outline1.color": typed[i++],
  "deco.upperFrontLeft.outline1.customColor": typed[i++],
  "deco.upperFrontLeft.outline2.color": typed[i++],
  "deco.upperFrontLeft.outline2.customColor": typed[i++],
  "deco.upperFrontRight": typed[i++],
  "deco.upperFrontRight.type": typed[i++],
  "deco.upperFrontRight.teamName": typed[i++],
  "deco.upperFrontRight.playerName": typed[i++],
  "deco.upperFrontRight.number": typed[i++],
  "deco.upperFrontRight.logo.previewFile": typed[i++],
  "deco.upperFrontRight.logo.factoryFile": typed[i++],
  "deco.upperFrontRight.size": typed[i++],
  "deco.upperFrontRight.font": typed[i++],
  "deco.upperFrontRight.fill.color": typed[i++],
  "deco.upperFrontRight.fill.customColor": typed[i++],
  "deco.upperFrontRight.outline1.color": typed[i++],
  "deco.upperFrontRight.outline1.customColor": typed[i++],
  "deco.upperFrontRight.outline2.color": typed[i++],
  "deco.upperFrontRight.outline2.customColor": typed[i++],
  "deco.fullFront-teamName": typed[i++],
  "deco.fullFront-teamName.type": typed[i++],
  "deco.fullFront-teamName.teamName": typed[i++],
  "deco.fullFront-teamName.playerName": typed[i++],
  "deco.fullFront-teamName.number": typed[i++],
  "deco.fullFront-teamName.logo.previewFile": typed[i++],
  "deco.fullFront-teamName.logo.factoryFile": typed[i++],
  "deco.fullFront-teamName.size": typed[i++],
  "deco.fullFront-teamName.font": typed[i++],
  "deco.fullFront-teamName.fill.color": typed[i++],
  "deco.fullFront-teamName.fill.customColor": typed[i++],
  "deco.fullFront-teamName.outline1.color": typed[i++],
  "deco.fullFront-teamName.outline1.customColor": typed[i++],
  "deco.fullFront-teamName.outline2.color": typed[i++],
  "deco.fullFront-teamName.outline2.customColor": typed[i++],
  "deco.fullFront": typed[i++],
  "deco.fullFront.type": typed[i++],
  "deco.fullFront.teamName": typed[i++],
  "deco.fullFront.playerName": typed[i++],
  "deco.fullFront.number": typed[i++],
  "deco.fullFront.logo.previewFile": typed[i++],
  "deco.fullFront.logo.factoryFile": typed[i++],
  "deco.fullFront.size": typed[i++],
  "deco.fullFront.font": typed[i++],
  "deco.fullFront.fill.color": typed[i++],
  "deco.fullFront.fill.customColor": typed[i++],
  "deco.fullFront.outline1.color": typed[i++],
  "deco.fullFront.outline1.customColor": typed[i++],
  "deco.fullFront.outline2.color": typed[i++],
  "deco.fullFront.outline2.customColor": typed[i++],
  "deco.lowerFrontLeft": typed[i++],
  "deco.lowerFrontLeft.type": typed[i++],
  "deco.lowerFrontLeft.teamName": typed[i++],
  "deco.lowerFrontLeft.playerName": typed[i++],
  "deco.lowerFrontLeft.number": typed[i++],
  "deco.lowerFrontLeft.logo.previewFile": typed[i++],
  "deco.lowerFrontLeft.logo.factoryFile": typed[i++],
  "deco.lowerFrontLeft.size": typed[i++],
  "deco.lowerFrontLeft.font": typed[i++],
  "deco.lowerFrontLeft.fill.color": typed[i++],
  "deco.lowerFrontLeft.fill.customColor": typed[i++],
  "deco.lowerFrontLeft.outline1.color": typed[i++],
  "deco.lowerFrontLeft.outline1.customColor": typed[i++],
  "deco.lowerFrontLeft.outline2.color": typed[i++],
  "deco.lowerFrontLeft.outline2.customColor": typed[i++],
  "deco.lowerFrontRight": typed[i++],
  "deco.lowerFrontRight.type": typed[i++],
  "deco.lowerFrontRight.teamName": typed[i++],
  "deco.lowerFrontRight.playerName": typed[i++],
  "deco.lowerFrontRight.number": typed[i++],
  "deco.lowerFrontRight.logo.previewFile": typed[i++],
  "deco.lowerFrontRight.logo.factoryFile": typed[i++],
  "deco.lowerFrontRight.size": typed[i++],
  "deco.lowerFrontRight.font": typed[i++],
  "deco.lowerFrontRight.fill.color": typed[i++],
  "deco.lowerFrontRight.fill.customColor": typed[i++],
  "deco.lowerFrontRight.outline1.color": typed[i++],
  "deco.lowerFrontRight.outline1.customColor": typed[i++],
  "deco.lowerFrontRight.outline2.color": typed[i++],
  "deco.lowerFrontRight.outline2.customColor": typed[i++],
  "deco.lowerFrontCenter": typed[i++],
  "deco.lowerFrontCenter.type": typed[i++],
  "deco.lowerFrontCenter.teamName": typed[i++],
  "deco.lowerFrontCenter.playerName": typed[i++],
  "deco.lowerFrontCenter.number": typed[i++],
  "deco.lowerFrontCenter.logo.previewFile": typed[i++],
  "deco.lowerFrontCenter.logo.factoryFile": typed[i++],
  "deco.lowerFrontCenter.size": typed[i++],
  "deco.lowerFrontCenter.font": typed[i++],
  "deco.lowerFrontCenter.fill.color": typed[i++],
  "deco.lowerFrontCenter.fill.customColor": typed[i++],
  "deco.lowerFrontCenter.outline1.color": typed[i++],
  "deco.lowerFrontCenter.outline1.customColor": typed[i++],
  "deco.lowerFrontCenter.outline2.color": typed[i++],
  "deco.lowerFrontCenter.outline2.customColor": typed[i++],
  "deco.upperBackSquare": typed[i++],
  "deco.upperBackSquare.type": typed[i++],
  "deco.upperBackSquare.teamName": typed[i++],
  "deco.upperBackSquare.playerName": typed[i++],
  "deco.upperBackSquare.number": typed[i++],
  "deco.upperBackSquare.logo.previewFile": typed[i++],
  "deco.upperBackSquare.logo.factoryFile": typed[i++],
  "deco.upperBackSquare.size": typed[i++],
  "deco.upperBackSquare.font": typed[i++],
  "deco.upperBackSquare.fill.color": typed[i++],
  "deco.upperBackSquare.fill.customColor": typed[i++],
  "deco.upperBackSquare.outline1.color": typed[i++],
  "deco.upperBackSquare.outline1.customColor": typed[i++],
  "deco.upperBackSquare.outline2.color": typed[i++],
  "deco.upperBackSquare.outline2.customColor": typed[i++],
  "deco.upperBackWide": typed[i++],
  "deco.upperBackWide.type": typed[i++],
  "deco.upperBackWide.teamName": typed[i++],
  "deco.upperBackWide.playerName": typed[i++],
  "deco.upperBackWide.number": typed[i++],
  "deco.upperBackWide.logo.previewFile": typed[i++],
  "deco.upperBackWide.logo.factoryFile": typed[i++],
  "deco.upperBackWide.size": typed[i++],
  "deco.upperBackWide.font": typed[i++],
  "deco.upperBackWide.fill.color": typed[i++],
  "deco.upperBackWide.fill.customColor": typed[i++],
  "deco.upperBackWide.outline1.color": typed[i++],
  "deco.upperBackWide.outline1.customColor": typed[i++],
  "deco.upperBackWide.outline2.color": typed[i++],
  "deco.upperBackWide.outline2.customColor": typed[i++],
  "deco.middleBack": typed[i++],
  "deco.middleBack.type": typed[i++],
  "deco.middleBack.teamName": typed[i++],
  "deco.middleBack.playerName": typed[i++],
  "deco.middleBack.number": typed[i++],
  "deco.middleBack.logo.previewFile": typed[i++],
  "deco.middleBack.logo.factoryFile": typed[i++],
  "deco.middleBack.size": typed[i++],
  "deco.middleBack.font": typed[i++],
  "deco.middleBack.fill.color": typed[i++],
  "deco.middleBack.fill.customColor": typed[i++],
  "deco.middleBack.outline1.color": typed[i++],
  "deco.middleBack.outline1.customColor": typed[i++],
  "deco.middleBack.outline2.color": typed[i++],
  "deco.middleBack.outline2.customColor": typed[i++],
  "deco.lowerBack": typed[i++],
  "deco.lowerBack.type": typed[i++],
  "deco.lowerBack.teamName": typed[i++],
  "deco.lowerBack.playerName": typed[i++],
  "deco.lowerBack.number": typed[i++],
  "deco.lowerBack.logo.previewFile": typed[i++],
  "deco.lowerBack.logo.factoryFile": typed[i++],
  "deco.lowerBack.size": typed[i++],
  "deco.lowerBack.font": typed[i++],
  "deco.lowerBack.fill.color": typed[i++],
  "deco.lowerBack.fill.customColor": typed[i++],
  "deco.lowerBack.outline1.color": typed[i++],
  "deco.lowerBack.outline1.customColor": typed[i++],
  "deco.lowerBack.outline2.color": typed[i++],
  "deco.lowerBack.outline2.customColor": typed[i++],
  "deco.fullBack": typed[i++],
  "deco.fullBack.type": typed[i++],
  "deco.fullBack.teamName": typed[i++],
  "deco.fullBack.playerName": typed[i++],
  "deco.fullBack.number": typed[i++],
  "deco.fullBack.logo.previewFile": typed[i++],
  "deco.fullBack.logo.factoryFile": typed[i++],
  "deco.fullBack.size": typed[i++],
  "deco.fullBack.font": typed[i++],
  "deco.fullBack.fill.color": typed[i++],
  "deco.fullBack.fill.customColor": typed[i++],
  "deco.fullBack.outline1.color": typed[i++],
  "deco.fullBack.outline1.customColor": typed[i++],
  "deco.fullBack.outline2.color": typed[i++],
  "deco.fullBack.outline2.customColor": typed[i++],
  "deco.leftSleeve": typed[i++],
  "deco.leftSleeve.type": typed[i++],
  "deco.leftSleeve.teamName": typed[i++],
  "deco.leftSleeve.playerName": typed[i++],
  "deco.leftSleeve.number": typed[i++],
  "deco.leftSleeve.logo.previewFile": typed[i++],
  "deco.leftSleeve.logo.factoryFile": typed[i++],
  "deco.leftSleeve.size": typed[i++],
  "deco.leftSleeve.font": typed[i++],
  "deco.leftSleeve.fill.color": typed[i++],
  "deco.leftSleeve.fill.customColor": typed[i++],
  "deco.leftSleeve.outline1.color": typed[i++],
  "deco.leftSleeve.outline1.customColor": typed[i++],
  "deco.leftSleeve.outline2.color": typed[i++],
  "deco.leftSleeve.outline2.customColor": typed[i++],
  "deco.rightSleeve": typed[i++],
  "deco.rightSleeve.type": typed[i++],
  "deco.rightSleeve.teamName": typed[i++],
  "deco.rightSleeve.playerName": typed[i++],
  "deco.rightSleeve.number": typed[i++],
  "deco.rightSleeve.logo.previewFile": typed[i++],
  "deco.rightSleeve.logo.factoryFile": typed[i++],
  "deco.rightSleeve.size": typed[i++],
  "deco.rightSleeve.font": typed[i++],
  "deco.rightSleeve.fill.color": typed[i++],
  "deco.rightSleeve.fill.customColor": typed[i++],
  "deco.rightSleeve.outline1.color": typed[i++],
  "deco.rightSleeve.outline1.customColor": typed[i++],
  "deco.rightSleeve.outline2.color": typed[i++],
  "deco.rightSleeve.outline2.customColor": typed[i++],
  "deco.fullLeftSleeve": typed[i++],
  "deco.fullLeftSleeve.type": typed[i++],
  "deco.fullLeftSleeve.teamName": typed[i++],
  "deco.fullLeftSleeve.playerName": typed[i++],
  "deco.fullLeftSleeve.number": typed[i++],
  "deco.fullLeftSleeve.logo.previewFile": typed[i++],
  "deco.fullLeftSleeve.logo.factoryFile": typed[i++],
  "deco.fullLeftSleeve.size": typed[i++],
  "deco.fullLeftSleeve.font": typed[i++],
  "deco.fullLeftSleeve.fill.color": typed[i++],
  "deco.fullLeftSleeve.fill.customColor": typed[i++],
  "deco.fullLeftSleeve.outline1.color": typed[i++],
  "deco.fullLeftSleeve.outline1.customColor": typed[i++],
  "deco.fullLeftSleeve.outline2.color": typed[i++],
  "deco.fullLeftSleeve.outline2.customColor": typed[i++],
  "deco.fullRightSleeve": typed[i++],
  "deco.fullRightSleeve.type": typed[i++],
  "deco.fullRightSleeve.teamName": typed[i++],
  "deco.fullRightSleeve.playerName": typed[i++],
  "deco.fullRightSleeve.number": typed[i++],
  "deco.fullRightSleeve.logo.previewFile": typed[i++],
  "deco.fullRightSleeve.logo.factoryFile": typed[i++],
  "deco.fullRightSleeve.size": typed[i++],
  "deco.fullRightSleeve.font": typed[i++],
  "deco.fullRightSleeve.fill.color": typed[i++],
  "deco.fullRightSleeve.fill.customColor": typed[i++],
  "deco.fullRightSleeve.outline1.color": typed[i++],
  "deco.fullRightSleeve.outline1.customColor": typed[i++],
  "deco.fullRightSleeve.outline2.color": typed[i++],
  "deco.fullRightSleeve.outline2.customColor": typed[i++],
  "deco.rightSide": typed[i++],
  "deco.rightSide.type": typed[i++],
  "deco.rightSide.teamName": typed[i++],
  "deco.rightSide.playerName": typed[i++],
  "deco.rightSide.number": typed[i++],
  "deco.rightSide.logo.previewFile": typed[i++],
  "deco.rightSide.logo.factoryFile": typed[i++],
  "deco.rightSide.size": typed[i++],
  "deco.rightSide.font": typed[i++],
  "deco.rightSide.fill.color": typed[i++],
  "deco.rightSide.fill.customColor": typed[i++],
  "deco.rightSide.outline1.color": typed[i++],
  "deco.rightSide.outline1.customColor": typed[i++],
  "deco.rightSide.outline2.color": typed[i++],
  "deco.rightSide.outline2.customColor": typed[i++],
  "details": typed[i++],
  "details.designName": typed[i++],
  "details.specialInstructions": typed[i++],
  "details.roster": typed[i++]
} as const;

export { typed as NAV_TREE };
