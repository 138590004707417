const defaultViewAngleId = 'front'

const viewAngles = {
  front: {
    label: 'Front',
    exposeForUI: true,
    exposeForRecipePreviewImages: true,
    previewSize: {
      width: 1200,
      height: 630,
    },
  },
  back: {
    label: 'Back',
    exposeForUI: true,
    exposeForRecipePreviewImages: true,
    previewSize: {
      width: 1200,
      height: 630,
    },
  },
}

export default viewAngles
export { defaultViewAngleId }
