import * as _ from '@technically/lodash'
import React from 'react'

import generatePreviews from '../generatePreviews'
import ImageLoader from '../ImageLoader'
import ModelLogic from '../ModelLogic'
import composite from '../composite'

import './RenderOverlay.css'

class RenderOverlay extends React.Component {
  overlayCanvas
  modelLogic
  imageLoader
  resultCanvas

  constructor(props) {
    super(props)

    this.imageLoader = new ImageLoader()
  }

  componentDidMount() {
    if (!this.overlayCanvas) {
      throw new Error('this.overlayCanvas not initialized')
    }

    const generator = (viewNames) =>
      generatePreviews(viewNames, this.imageLoader)
    this.props.setPreviewGenerator(generator)

    const modelLogic = new ModelLogic(
      this.overlayCanvas,
      this.props.config.overlays,
    )

    modelLogic.onAfterRender(async () => {
      this.setLoading(true)
      await this.loadAndComposite()
      this.setLoading(false)
    })

    this.modelLogic = modelLogic

    window.addEventListener('resize', this.updateSizes)
    this.updateSizes()

    if (this.props.registerInspectorLauncher && this.modelLogic) {
      this.props.registerInspectorLauncher(this.modelLogic.showDebugLayer)
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.modelLogic) {
      const isModelUpToDate = this.modelLogic.updateState(
        this.props.config.overlays,
      )
      const isCompositeUpToDate = _.isEqual(
        this.props.config.images,
        prevProps.config.images,
      )

      const isUpToDate = isModelUpToDate && isCompositeUpToDate

      if (!isUpToDate) {
        this.setLoading(true)
        if (isModelUpToDate && !isCompositeUpToDate) {
          await this.loadAndComposite()
          this.setLoading(false)
        }
      }
    }
  }

  componentWillUnmount() {
    this.props.setPreviewGenerator(undefined)

    window.removeEventListener('resize', this.updateSizes)

    if (this.modelLogic) {
      this.modelLogic.dispose()
      this.modelLogic = undefined
    }
    this.overlayCanvas = undefined
  }

  loadAndComposite = async () => {
    this.imageLoader.cancel()
    const images = await this.imageLoader.load(this.props.config.images)

    composite(
      images,
      this.props.config.size,
      this.overlayCanvas,
      this.resultCanvas,
    )
  }

  setLoading = (isLoading = true) => {
    const setLoading = this.props.setLoading
    if (setLoading) {
      if (!isLoading) {
        setTimeout(() => {
          setLoading(false)
        }, 0)
      } else {
        setLoading(true)
      }
    }
  }

  render() {
    return (
      <div
        className="RenderOverlay"
        style={this.props.isHidden ? { display: 'none' } : undefined}
      >
        <canvas
          ref={(c) => {
            this.overlayCanvas = c
          }}
        />
        <canvas
          className="resultCanvas"
          ref={(c) => {
            this.resultCanvas = c
          }}
        />
      </div>
    )
  }

  updateSizes = () => {
    const { size } = this.props.config

    if (this.modelLogic) {
      this.modelLogic.setSize(size.width, size.height)
    }

    if (this.resultCanvas) {
      this.resultCanvas.width = size.width
      this.resultCanvas.height = size.height
    }
  }
}

export default RenderOverlay
