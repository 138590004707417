import { concat, handleActions, combine } from 'redux-fp'

const isLoading = concat(
  () =>
    (state = true) =>
      state,
  handleActions({
    SET_RENDER_OVERLAY_LOADING: (action) => () => action.payload,
  }),
)

const activeView = concat(
  () =>
    (state = null) =>
      state,
  handleActions({
    SET_RENDER_OVERLAY_ACTIVE_VIEW: (action) => () => action.payload,
  }),
)

export default combine({ isLoading, activeView })
