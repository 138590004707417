const canvas = document.createElement('canvas')
const ctx = canvas.getContext('2d')

const getPaddedImageURL = (image, textureSize) => {
  const isImageWider = image.width > image.height

  const imageWidth = image.width
  const imageHeight = image.height

  const canvasWidth =
    isImageWider ? imageWidth : (
      imageHeight * (textureSize.width / textureSize.maxHeight)
    )
  const canvasHeight =
    isImageWider ?
      imageWidth * (textureSize.maxHeight / textureSize.width)
    : imageHeight

  const scale =
    Math.min(canvasWidth / imageWidth, canvasHeight / imageHeight) *
    (textureSize.height / textureSize.maxHeight)

  const x = canvasWidth / 2 - (imageWidth / 2) * scale
  const y = canvasHeight / 2 - (imageHeight / 2) * scale

  canvas.width = canvasWidth
  canvas.height = canvasHeight

  ctx.clearRect(0, 0, canvasWidth, canvasHeight)

  // ctx.fillStyle = '#ebebeb'
  // ctx.fillRect(0, 0, canvasWidth, canvasHeight)

  ctx.drawImage(image, x, y, imageWidth * scale, imageHeight * scale)

  return canvas.toDataURL('image/png')
}

export default getPaddedImageURL
