// DO NOT EDIT, GENERATED BY "yarn sheets"

import { DecoHeightRow } from '../typings';

const data = [
  {
    "id": "1.5",
    "name": "1.5\"",
    "props": {
      "height": 1.5
    }
  },
  {
    "id": "2",
    "name": "2\"",
    "props": {
      "height": 2
    }
  },
  {
    "id": "4",
    "name": "4\"",
    "props": {
      "height": 4
    }
  },
  {
    "id": "6",
    "name": "6\"",
    "props": {
      "height": 6
    }
  },
  {
    "id": "8",
    "name": "8\"",
    "props": {
      "height": 8
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<DecoHeightRow[]>> = data;

export type DecoHeight = typeof typed[number];

export const DECO_HEIGHT_INDEX_KEY = "id";
export type DecoHeightIndexKey = "id";
export type DecoHeightId = DecoHeight["id"];

let i = 0;
export const DECO_HEIGHT_DICT = {
  "1.5": typed[i++],
  "2": typed[i++],
  "4": typed[i++],
  "6": typed[i++],
  "8": typed[i++]
} as const;

export { typed as DECO_HEIGHTS };
