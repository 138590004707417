// DO NOT EDIT, GENERATED BY "yarn sheets"

import { GarmentRow } from '../typings';

const data = [
  {
    "props": {
      "sportId": "baseball",
      "defaultColorId": "9191",
      "colorIds": [
        "0000",
        "9191",
        "9292"
      ],
      "viewIds": {
        "front": true,
        "back": true
      },
      "ageGroupId": {
        "adult": true,
        "youth": true
      },
      "decoPlacementId": {
        "upperFrontLeft": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": true
          }
        },
        "upperFrontRight": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullFront-teamName": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": true
          }
        },
        "fullFront": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "lowerFrontLeft": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": true
          }
        },
        "lowerFrontRight": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": true
          }
        },
        "lowerFrontCenter": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": true
          }
        },
        "upperBackSquare": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": true
          }
        },
        "upperBackWide": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": true
          }
        },
        "middleBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": true
          }
        },
        "lowerBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": true
          }
        },
        "fullBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "leftSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": true
          }
        },
        "rightSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": true
          }
        },
        "fullLeftSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullRightSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "rightSide": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        }
      }
    },
    "id": "350713",
    "name": "Aerolite Full Button Baseball Jersey"
  },
  {
    "props": {
      "sportId": "baseball",
      "defaultColorId": "1091",
      "colorIds": [
        "0000",
        "1091",
        "5191",
        "5291",
        "9091",
        "9191",
        "9292"
      ],
      "viewIds": {
        "front": true,
        "back": true
      },
      "ageGroupId": {
        "adult": true,
        "youth": true
      },
      "decoPlacementId": {
        "upperFrontLeft": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "upperFrontRight": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullFront-teamName": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "fullFront": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerFrontLeft": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "lowerFrontRight": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "lowerFrontCenter": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "upperBackSquare": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "upperBackWide": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "middleBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "lowerBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "fullBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "leftSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "rightSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "fullLeftSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullRightSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "rightSide": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        }
      }
    },
    "id": "350749",
    "name": "Aerolite 2 Button Baseball Jersey"
  },
  {
    "props": {
      "sportId": "baseball",
      "defaultColorId": "1091",
      "colorIds": [
        "0000",
        "1091",
        "5191",
        "5291",
        "9091",
        "9191",
        "9292"
      ],
      "viewIds": {
        "front": true,
        "back": true
      },
      "ageGroupId": {
        "adult": true,
        "youth": true
      },
      "decoPlacementId": {
        "upperFrontLeft": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "upperFrontRight": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullFront-teamName": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "fullFront": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerFrontLeft": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerFrontRight": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerFrontCenter": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "upperBackSquare": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "upperBackWide": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "middleBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "fullBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "leftSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "rightSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "fullLeftSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullRightSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "rightSide": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        }
      }
    },
    "id": "350750",
    "name": "Aerolite Crew Baseball Jersey"
  },
  {
    "props": {
      "sportId": "baseball",
      "defaultColorId": "1212",
      "colorIds": [
        "9090",
        "1212",
        "4141",
        "9191",
        "5151",
        "2020",
        "1010",
        "5252",
        "0000"
      ],
      "viewIds": {
        "front": true,
        "back": true
      },
      "ageGroupId": {
        "adult": true,
        "youth": true
      },
      "decoPlacementId": {
        "upperFrontLeft": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "upperFrontRight": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullFront-teamName": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "fullFront": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerFrontLeft": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerFrontRight": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerFrontCenter": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "upperBackSquare": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "upperBackWide": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "middleBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "fullBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "leftSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "rightSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "fullLeftSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullRightSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "rightSide": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        }
      }
    },
    "id": "350636",
    "name": "Comp Diamond SS Crew"
  },
  {
    "props": {
      "sportId": "baseball",
      "defaultColorId": "1212",
      "colorIds": [
        "9090",
        "1212",
        "4141",
        "9191",
        "5151",
        "2020",
        "1010",
        "5252",
        "0000"
      ],
      "viewIds": {
        "front": true,
        "back": true
      },
      "ageGroupId": {
        "adult": true,
        "youth": true
      },
      "decoPlacementId": {
        "upperFrontLeft": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "upperFrontRight": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullFront-teamName": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "fullFront": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerFrontLeft": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerFrontRight": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerFrontCenter": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "upperBackSquare": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "upperBackWide": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "middleBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "fullBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "leftSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "rightSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "fullLeftSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullRightSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "rightSide": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        }
      }
    },
    "id": "350638",
    "name": "Comp Diamond LS Crew"
  },
  {
    "props": {
      "sportId": "baseball",
      "defaultColorId": "9191",
      "colorIds": [
        "9091",
        "9191",
        "5191",
        "1091",
        "5291"
      ],
      "viewIds": {
        "front": true,
        "back": true
      },
      "ageGroupId": {
        "adult": true,
        "youth": true
      },
      "decoPlacementId": {
        "upperFrontLeft": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "upperFrontRight": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "fullFront-teamName": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullFront": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "lowerFrontLeft": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerFrontRight": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerFrontCenter": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "upperBackSquare": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "upperBackWide": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "middleBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "fullBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "leftSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "rightSleeve": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "fullLeftSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullRightSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "rightSide": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        }
      }
    },
    "id": "350780",
    "name": "Comp LS Batting Jacket"
  },
  {
    "props": {
      "sportId": "baseball",
      "defaultColorId": "9191",
      "colorIds": [
        "9091",
        "9191",
        "5191",
        "1091",
        "5291"
      ],
      "viewIds": {
        "front": true,
        "back": true
      },
      "ageGroupId": {
        "adult": true,
        "youth": true
      },
      "decoPlacementId": {
        "upperFrontLeft": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "upperFrontRight": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "fullFront-teamName": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullFront": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "lowerFrontLeft": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerFrontRight": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerFrontCenter": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "upperBackSquare": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "upperBackWide": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "middleBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "fullBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "leftSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "rightSleeve": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "fullLeftSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullRightSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "rightSide": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        }
      }
    },
    "id": "350663",
    "name": "Comp SS Batting Jacket"
  },
  {
    "props": {
      "sportId": "softball",
      "defaultColorId": "1091",
      "colorIds": [
        "0000",
        "1091",
        "5191",
        "5291",
        "9091",
        "9191",
        "9292"
      ],
      "viewIds": {
        "front": true,
        "back": true
      },
      "ageGroupId": {
        "adult": true,
        "youth": true
      },
      "decoPlacementId": {
        "upperFrontLeft": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "upperFrontRight": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullFront-teamName": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "fullFront": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerFrontLeft": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "lowerFrontRight": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "lowerFrontCenter": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "upperBackSquare": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "upperBackWide": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "middleBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "lowerBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "fullBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "leftSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": true
          }
        },
        "rightSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "fullLeftSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullRightSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "rightSide": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        }
      }
    },
    "id": "350715",
    "name": "Aerolite 2 Button Sofball Jersey"
  },
  {
    "props": {
      "sportId": "softball",
      "defaultColorId": "1091",
      "colorIds": [
        "0000",
        "1091",
        "5191",
        "5291",
        "9091",
        "9191",
        "9292"
      ],
      "viewIds": {
        "front": true,
        "back": true
      },
      "ageGroupId": {
        "adult": true,
        "youth": true
      },
      "decoPlacementId": {
        "upperFrontLeft": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "upperFrontRight": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullFront-teamName": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "fullFront": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerFrontLeft": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "lowerFrontRight": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "lowerFrontCenter": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "upperBackSquare": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "upperBackWide": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "middleBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "lowerBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "fullBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "leftSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "rightSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "fullLeftSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullRightSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "rightSide": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        }
      }
    },
    "id": "350721",
    "name": "Aerolite 2 Button Slvless Jersey"
  },
  {
    "props": {
      "sportId": "softball",
      "defaultColorId": "1200",
      "colorIds": [
        "9000",
        "1200",
        "9200",
        "4100",
        "9100",
        "5500",
        "5100",
        "2000",
        "6000",
        "1000",
        "1M00",
        "0091"
      ],
      "viewIds": {
        "front": true,
        "back": true
      },
      "ageGroupId": {
        "adult": true,
        "youth": true
      },
      "decoPlacementId": {
        "upperFrontLeft": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "upperFrontRight": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "fullFront-teamName": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "fullFront": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerFrontLeft": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerFrontRight": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerFrontCenter": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "upperBackSquare": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "upperBackWide": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "middleBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "fullBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "leftSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "rightSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "fullLeftSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullRightSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "rightSide": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        }
      }
    },
    "id": "350718",
    "name": "Softball Crew Neck Jersey"
  },
  {
    "props": {
      "sportId": "volleyball",
      "defaultColorId": "1200",
      "colorIds": [
        "9000",
        "1200",
        "4100",
        "5151",
        "6000",
        "1010",
        "5252",
        "0000",
        "9090",
        "5100",
        "1000",
        "5200"
      ],
      "viewIds": {
        "front": true,
        "back": true
      },
      "ageGroupId": {
        "adult": true,
        "youth": false
      },
      "decoPlacementId": {
        "upperFrontLeft": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "upperFrontRight": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullFront-teamName": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "fullFront": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerFrontLeft": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerFrontRight": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerFrontCenter": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "upperBackSquare": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "upperBackWide": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "middleBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "fullBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "leftSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "rightSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "fullLeftSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullRightSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "rightSide": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        }
      }
    },
    "id": "440681",
    "name": "Techno VII LS"
  },
  {
    "props": {
      "sportId": "volleyball",
      "defaultColorId": "9000",
      "colorIds": [
        "1200",
        "9000",
        "4100",
        "5151",
        "6000",
        "1010",
        "5252",
        "0000",
        "9090",
        "5100",
        "1000",
        "5200"
      ],
      "viewIds": {
        "front": true,
        "back": true
      },
      "ageGroupId": {
        "adult": true,
        "youth": false
      },
      "decoPlacementId": {
        "upperFrontLeft": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "upperFrontRight": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullFront-teamName": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "fullFront": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerFrontLeft": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerFrontRight": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerFrontCenter": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "upperBackSquare": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "upperBackWide": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "middleBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "fullBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "leftSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "rightSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "fullLeftSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullRightSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "rightSide": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        }
      }
    },
    "id": "440682",
    "name": "Techno VII SS"
  },
  {
    "props": {
      "sportId": "volleyball",
      "defaultColorId": "0073",
      "colorIds": [
        "1M73",
        "0073",
        "1073",
        "1273",
        "4173",
        "5173",
        "5273",
        "5S73",
        "6073",
        "9073",
        "9273"
      ],
      "viewIds": {
        "front": true,
        "back": true
      },
      "ageGroupId": {
        "adult": true,
        "youth": false
      },
      "decoPlacementId": {
        "upperFrontLeft": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "upperFrontRight": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullFront-teamName": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "fullFront": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerFrontLeft": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerFrontRight": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerFrontCenter": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "upperBackSquare": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "upperBackWide": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "middleBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "fullBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "leftSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "rightSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "fullLeftSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullRightSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "rightSide": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        }
      }
    },
    "id": "440645",
    "name": "Balboa 5.0 LS"
  },
  {
    "props": {
      "sportId": "volleyball",
      "defaultColorId": "0073",
      "colorIds": [
        "9273",
        "0073",
        "1073",
        "1273",
        "4173",
        "5173",
        "5273",
        "6073",
        "9073"
      ],
      "viewIds": {
        "front": true,
        "back": true
      },
      "ageGroupId": {
        "adult": true,
        "youth": true
      },
      "decoPlacementId": {
        "upperFrontLeft": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "upperFrontRight": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullFront-teamName": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "fullFront": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerFrontLeft": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerFrontRight": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerFrontCenter": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "upperBackSquare": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "upperBackWide": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "middleBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "fullBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "leftSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "rightSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "fullLeftSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullRightSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "rightSide": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        }
      }
    },
    "id": "440646",
    "name": "Balboa 5.0 SS"
  },
  {
    "props": {
      "sportId": "volleyball",
      "defaultColorId": "9191",
      "colorIds": [
        "9090",
        "9191"
      ],
      "viewIds": {
        "front": true,
        "back": true
      },
      "ageGroupId": {
        "adult": true,
        "youth": false
      },
      "decoPlacementId": {
        "upperFrontLeft": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "upperFrontRight": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "fullFront-teamName": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "fullFront": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "lowerFrontLeft": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "lowerFrontRight": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "lowerFrontCenter": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "upperBackSquare": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "upperBackWide": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "middleBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "lowerBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "fullBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "leftSleeve": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "rightSleeve": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "fullLeftSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullRightSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "rightSide": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        }
      }
    },
    "id": "440701",
    "name": "Player Ponch"
  },
  {
    "props": {
      "sportId": "volleyball",
      "defaultColorId": "9191",
      "colorIds": [
        "9090",
        "9191"
      ],
      "viewIds": {
        "front": true,
        "back": true
      },
      "ageGroupId": {
        "adult": true,
        "youth": false
      },
      "decoPlacementId": {
        "upperFrontLeft": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "upperFrontRight": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "fullFront-teamName": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "fullFront": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "lowerFrontLeft": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "lowerFrontRight": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "lowerFrontCenter": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "upperBackSquare": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "upperBackWide": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "middleBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "lowerBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "fullBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "leftSleeve": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "rightSleeve": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "fullLeftSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullRightSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "rightSide": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        }
      }
    },
    "id": "440684",
    "name": "Momentum Funnel Neck Pullover"
  },
  {
    "props": {
      "sportId": "training",
      "defaultColorId": "5151",
      "colorIds": [
        "9090",
        "5151",
        "1010",
        "5252",
        "9I9I"
      ],
      "viewIds": {
        "front": true,
        "back": true
      },
      "ageGroupId": {
        "adult": true,
        "youth": false
      },
      "decoPlacementId": {
        "upperFrontLeft": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "upperFrontRight": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullFront-teamName": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullFront": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "lowerFrontLeft": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerFrontRight": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerFrontCenter": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "upperBackSquare": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "upperBackWide": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "middleBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "lowerBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "leftSleeve": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "rightSleeve": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "fullLeftSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullRightSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "rightSide": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        }
      }
    },
    "id": "530054",
    "name": "Alpha 1/2 Zip"
  },
  {
    "props": {
      "sportId": "training",
      "defaultColorId": "5151",
      "colorIds": [
        "9090",
        "5151",
        "1010",
        "5252",
        "9I9I"
      ],
      "viewIds": {
        "front": true,
        "back": true
      },
      "ageGroupId": {
        "adult": true,
        "youth": false
      },
      "decoPlacementId": {
        "upperFrontLeft": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "upperFrontRight": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullFront-teamName": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "fullFront": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerFrontLeft": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerFrontRight": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerFrontCenter": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "upperBackSquare": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "upperBackWide": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "middleBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "lowerBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "leftSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "rightSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "fullLeftSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullRightSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "rightSide": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        }
      }
    },
    "id": "530051",
    "name": "Alpha Long Sleeve"
  },
  {
    "props": {
      "sportId": "training",
      "defaultColorId": "9191",
      "colorIds": [
        "9090",
        "9191",
        "5151",
        "1010",
        "5252",
        "0000"
      ],
      "viewIds": {
        "front": true,
        "back": true
      },
      "ageGroupId": {
        "adult": true,
        "youth": true
      },
      "decoPlacementId": {
        "upperFrontLeft": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "upperFrontRight": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullFront-teamName": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "fullFront": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerFrontLeft": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerFrontRight": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerFrontCenter": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "upperBackSquare": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "upperBackWide": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "middleBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "fullBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "leftSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "rightSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "fullLeftSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullRightSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "rightSide": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        }
      }
    },
    "id": "530025",
    "name": "Alpha Stretch Sleeve"
  },
  {
    "props": {
      "sportId": "training",
      "defaultColorId": "5151",
      "colorIds": [
        "9090",
        "5151",
        "1010",
        "5252",
        "9I9I"
      ],
      "viewIds": {
        "front": true,
        "back": true
      },
      "ageGroupId": {
        "adult": true,
        "youth": false
      },
      "decoPlacementId": {
        "upperFrontLeft": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "upperFrontRight": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullFront-teamName": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "fullFront": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerFrontLeft": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerFrontRight": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerFrontCenter": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "upperBackSquare": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "upperBackWide": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "middleBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "lowerBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "leftSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "rightSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "fullLeftSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullRightSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "rightSide": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        }
      }
    },
    "id": "530050",
    "name": "Alpha Short Sleeve"
  },
  {
    "props": {
      "sportId": "training",
      "defaultColorId": "519I",
      "colorIds": [
        "909I",
        "519I",
        "109I",
        "529I",
        "9I9I"
      ],
      "viewIds": {
        "front": true,
        "back": true
      },
      "ageGroupId": {
        "adult": true,
        "youth": true
      },
      "decoPlacementId": {
        "upperFrontLeft": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "upperFrontRight": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "fullFront-teamName": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "fullFront": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerFrontLeft": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "lowerFrontRight": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "lowerFrontCenter": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "upperBackSquare": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "upperBackWide": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "middleBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "lowerBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "fullBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "leftSleeve": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "rightSleeve": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "fullLeftSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullRightSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "rightSide": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        }
      }
    },
    "id": "530023",
    "name": "Velocity Hoodie"
  },
  {
    "props": {
      "sportId": "training",
      "defaultColorId": "519I",
      "colorIds": [
        "909I",
        "519I",
        "109I",
        "529I",
        "9I9I"
      ],
      "viewIds": {
        "front": true,
        "back": true
      },
      "ageGroupId": {
        "adult": true,
        "youth": true
      },
      "decoPlacementId": {
        "upperFrontLeft": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "upperFrontRight": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "fullFront-teamName": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "fullFront": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerFrontLeft": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "lowerFrontRight": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "lowerFrontCenter": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "upperBackSquare": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "upperBackWide": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "middleBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "lowerBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "fullBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "leftSleeve": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "rightSleeve": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "fullLeftSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullRightSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "rightSide": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        }
      }
    },
    "id": "530021",
    "name": "Velocity LS Crew"
  },
  {
    "props": {
      "sportId": "training",
      "defaultColorId": "1212",
      "colorIds": [
        "9090",
        "1212",
        "9292",
        "4141",
        "9191",
        "4L4L",
        "5555",
        "5151",
        "2020",
        "6060",
        "1010",
        "5252",
        "9I9I",
        "1M1M",
        "0000"
      ],
      "viewIds": {
        "front": true,
        "back": true
      },
      "ageGroupId": {
        "adult": true,
        "youth": true
      },
      "decoPlacementId": {
        "upperFrontLeft": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "upperFrontRight": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "fullFront-teamName": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "fullFront": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerFrontLeft": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerFrontRight": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerFrontCenter": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "upperBackSquare": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "upperBackWide": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "middleBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "fullBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "leftSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "rightSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "fullLeftSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullRightSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "rightSide": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        }
      }
    },
    "id": "530060",
    "name": "Mizuno Tee"
  },
  {
    "props": {
      "sportId": "training",
      "defaultColorId": "5555",
      "colorIds": [
        "9090",
        "5555",
        "5151",
        "5252",
        "0000"
      ],
      "viewIds": {
        "front": true,
        "back": true
      },
      "ageGroupId": {
        "adult": true,
        "youth": false
      },
      "decoPlacementId": {
        "upperFrontLeft": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "upperFrontRight": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "fullFront-teamName": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullFront": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "lowerFrontLeft": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "lowerFrontRight": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "lowerFrontCenter": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "upperBackSquare": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "upperBackWide": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "middleBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "lowerBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "leftSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "rightSleeve": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "fullLeftSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullRightSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "rightSide": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        }
      }
    },
    "id": "350738",
    "name": "Mizuno Performance Polo"
  },
  {
    "props": {
      "sportId": "training",
      "defaultColorId": "5151",
      "colorIds": [
        "9090",
        "5151",
        "1010",
        "5252",
        "9I9I",
        "0000"
      ],
      "viewIds": {
        "front": true,
        "back": true
      },
      "ageGroupId": {
        "adult": true,
        "youth": false
      },
      "decoPlacementId": {
        "upperFrontLeft": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "upperFrontRight": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullFront-teamName": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullFront": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "lowerFrontLeft": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "lowerFrontRight": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "lowerFrontCenter": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "upperBackSquare": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "upperBackWide": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "middleBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "lowerBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "leftSleeve": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "rightSleeve": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "fullLeftSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullRightSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "rightSide": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        }
      }
    },
    "id": "530052",
    "name": "Alpha Polo"
  },
  {
    "props": {
      "sportId": "training",
      "defaultColorId": "5151",
      "colorIds": [
        "9090",
        "5151",
        "1010",
        "5252",
        "9I9I"
      ],
      "viewIds": {
        "front": true,
        "back": true
      },
      "ageGroupId": {
        "adult": true,
        "youth": false
      },
      "decoPlacementId": {
        "upperFrontLeft": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "upperFrontRight": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullFront-teamName": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullFront": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "lowerFrontLeft": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerFrontRight": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerFrontCenter": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "upperBackSquare": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "upperBackWide": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "middleBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "lowerBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "leftSleeve": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "rightSleeve": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "fullLeftSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullRightSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "rightSide": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        }
      }
    },
    "id": "530036",
    "name": "Women's Alpha 1/2 Zip"
  },
  {
    "props": {
      "sportId": "training",
      "defaultColorId": "5151",
      "colorIds": [
        "9090",
        "5151",
        "1010",
        "5252",
        "9I9I"
      ],
      "viewIds": {
        "front": true,
        "back": true
      },
      "ageGroupId": {
        "adult": true,
        "youth": false
      },
      "decoPlacementId": {
        "upperFrontLeft": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "upperFrontRight": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullFront-teamName": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "fullFront": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerFrontLeft": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerFrontRight": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerFrontCenter": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "upperBackSquare": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "upperBackWide": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "middleBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "lowerBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "leftSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "rightSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "fullLeftSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullRightSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "rightSide": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        }
      }
    },
    "id": "530039",
    "name": "Women's Alpha Long Sleeve"
  },
  {
    "props": {
      "sportId": "training",
      "defaultColorId": "5151",
      "colorIds": [
        "9090",
        "5151",
        "1010",
        "5252",
        "9I9I"
      ],
      "viewIds": {
        "front": true,
        "back": true
      },
      "ageGroupId": {
        "adult": true,
        "youth": false
      },
      "decoPlacementId": {
        "upperFrontLeft": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "upperFrontRight": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullFront-teamName": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "fullFront": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerFrontLeft": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerFrontRight": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "lowerFrontCenter": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "upperBackSquare": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "upperBackWide": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "middleBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "lowerBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "leftSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "rightSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "fullLeftSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullRightSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "rightSide": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        }
      }
    },
    "id": "530040",
    "name": "Women's Alpha Short Sleeve"
  },
  {
    "props": {
      "sportId": "cap",
      "defaultColorId": "5151",
      "colorIds": [
        "9090",
        "5151",
        "1010",
        "5252",
        "5110",
        "0000",
        "9191"
      ],
      "viewIds": {
        "front": true,
        "back": true
      },
      "ageGroupId": {
        "adult": true,
        "youth": false
      },
      "decoPlacementId": {
        "upperFrontLeft": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "upperFrontRight": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullFront-teamName": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullFront": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "lowerFrontLeft": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "lowerFrontRight": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "lowerFrontCenter": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "upperBackSquare": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "upperBackWide": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "middleBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "lowerBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullBack": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "leftSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "rightSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullLeftSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "fullRightSleeve": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "rightSide": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": false,
            "tackleTwill": false
          }
        }
      }
    },
    "id": "370274",
    "name": "Mizuno Team Snapback"
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<GarmentRow[]>> = data;

export type Garment = typeof typed[number];

export const GARMENT_INDEX_KEY = "id";
export type GarmentIndexKey = "id";
export type GarmentId = Garment["id"];

let i = 0;
export const GARMENT_DICT = {
  "350713": typed[i++],
  "350749": typed[i++],
  "350750": typed[i++],
  "350636": typed[i++],
  "350638": typed[i++],
  "350780": typed[i++],
  "350663": typed[i++],
  "350715": typed[i++],
  "350721": typed[i++],
  "350718": typed[i++],
  "440681": typed[i++],
  "440682": typed[i++],
  "440645": typed[i++],
  "440646": typed[i++],
  "440701": typed[i++],
  "440684": typed[i++],
  "530054": typed[i++],
  "530051": typed[i++],
  "530025": typed[i++],
  "530050": typed[i++],
  "530023": typed[i++],
  "530021": typed[i++],
  "530060": typed[i++],
  "350738": typed[i++],
  "530052": typed[i++],
  "530036": typed[i++],
  "530039": typed[i++],
  "530040": typed[i++],
  "370274": typed[i++]
} as const;

export { typed as GARMENTS };
