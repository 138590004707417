import {
  filter,
  some,
  reject,
  includes,
  mapValues,
  compact,
  map,
} from '@technically/lodash'
import {
  Text,
  Select,
  FileUpload,
  Repeater,
  createControlTree,
} from '~p/client/control-tree'

import {
  DECO_COLORS,
  DECO_HEIGHTS,
  DECO_HEIGHT_DICT,
  DECO_PACKAGES,
  DECO_PLACEMENT_DICT,
  FONTS,
  GARMENTS,
  GARMENT_COLORS,
  PROP_DEF_DICT,
  ROSTER_SIZES,
  SPORTS,
  SPORT_DICT,
  DECOS,
} from '../common/sheets'

const boilerplate = (propDef) => ({
  label: propDef.name,
  subline: propDef.description,
  description: propDef.description,
  defaultValue:
    'defaultValueId' in propDef && propDef.defaultValueId ?
      propDef.defaultValueId
    : null,
  isRequired: !propDef.isOptional,
  autoUnavailable: true,
})

const controls = {
  filter: {
    sport: Select({
      ...boilerplate(PROP_DEF_DICT.filter_sport),
      options: SPORTS,
      dependencies: ['filtered.garments'],
      visibleOptions: (garments) =>
        filter(SPORTS, (sport) =>
          some(garments, (garment) => garment.props.sportId === sport.id),
        ),
    }),
  },

  filtered: {
    garments: Text({
      isPrivate: true,
      value: () => () => reject(GARMENTS, (garment) => garment.props.isHidden),
    }),

    garmentsBySport: Text({
      isPrivate: true,
      dependencies: ['filtered.garments', 'filter.sport'],
      value: (garments, sport) => () => {
        if (sport == null) {
          return garments
        }
        return filter(
          garments,
          (garment) => garment.props.sportId === (sport && sport.id),
        )
      },
    }),
  },

  calc: {
    sport: Text({
      ...boilerplate(PROP_DEF_DICT.calc_sport),
      isPrivate: true,
      dependencies: ['product.garment'],
      value: (garment) => () => SPORT_DICT[garment.props.sportId],
    }),

    sku: Text({
      ...boilerplate(PROP_DEF_DICT.calc_sku),
      isPrivate: true,
      dependencies: ['product.garment'],
      value: (garment) => () => garment.id,
    }),
  },

  product: {
    garment: Select({
      ...boilerplate(PROP_DEF_DICT.product_garment),
      options: GARMENTS,
      visibleOptions: (garments) => garments,
      dependencies: ['filtered.garmentsBySport'],
    }),
  },

  colors: {
    color: Select({
      ...boilerplate(PROP_DEF_DICT.colors_color),
      options: GARMENT_COLORS,
      dependencies: ['product.garment'],
      visibleOptions: (garment) =>
        filter(GARMENT_COLORS, (color) =>
          includes(garment.props.colorIds, color.id),
        ),
    }),
  },

  deco: {
    package: Select({
      ...boilerplate(PROP_DEF_DICT.deco_package),
      options: DECO_PACKAGES,
      dependencies: ['product.garment'],
      visibleOptions: (garment) =>
        filter(DECO_PACKAGES, (decoPackage) =>
          some(
            garment.props.decoPlacementId,
            (placement) => placement.decoPackageId[decoPackage.id] === true,
          ),
        ),
    }),
    ...mapValues(DECO_PLACEMENT_DICT, (placement, placementId) => ({
      isAvailable: Text({
        isPrivate: true,
        dependencies: ['product.garment', 'deco.package'],
        value: (garment, decoPackage) => () =>
          garment.props.decoPlacementId[placementId].decoPackageId[
            decoPackage.id
          ] === true,
      }),
      type: Select({
        ...boilerplate(PROP_DEF_DICT[`deco_${placementId}_type`]),
        options: DECOS,
        visibleOptions: (isPlacementAvailable, sport) => {
          const sizesByType = placement.props.sizesBySportId[sport.id] ?? {}
          return filter(DECOS, (deco) => {
            const size = sizesByType[deco.id]
            return size && size.width && (size.height || size.heightIds)
          })
        },
        dependencies: [`deco.${placementId}.isAvailable`, 'calc.sport'],
        isAvailable: (isPlacementAvailable) => isPlacementAvailable,
      }),
      logo: {
        previewFile: FileUpload({
          ...boilerplate(PROP_DEF_DICT[`deco_${placementId}_logo_previewFile`]),
          dependencies: [`deco.${placementId}.type`],
          isAvailable: (decoType) => !!(decoType && decoType.id === 'logo'),
        }),
        factoryFile: FileUpload({
          ...boilerplate(PROP_DEF_DICT[`deco_${placementId}_logo_factoryFile`]),
          dependencies: [`deco.${placementId}.type`],
          isAvailable: (decoType) => !!(decoType && decoType.id === 'logo'),
        }),
      },
      teamName: Text({
        ...boilerplate(PROP_DEF_DICT[`deco_${placementId}_teamName`]),
        dependencies: [`deco.${placementId}.type`],
        isAvailable: (decoType) => !!(decoType && decoType.id === 'teamName'),
        maxLength: 15,
        pattern: /^[a-zA-Z0-9 ]+$/,
      }),
      playerName: Text({
        ...boilerplate(PROP_DEF_DICT[`deco_${placementId}_playerName`]),
        dependencies: [`deco.${placementId}.type`],
        isAvailable: (decoType) => !!(decoType && decoType.id === 'playerName'),
        maxLength: 15,
        pattern: /^[a-zA-Z0-9 ]+$/,
      }),
      number: Text({
        ...boilerplate(PROP_DEF_DICT[`deco_${placementId}_number`]),
        dependencies: [`deco.${placementId}.type`],
        isAvailable: (decoType) => !!(decoType && decoType.id === 'number'),
        maxLength: 2,
        pattern: /^[0-9]+$/,
      }),
      size: Select({
        ...boilerplate(PROP_DEF_DICT[`deco_${placementId}_size`]),
        options: DECO_HEIGHTS,
        dependencies: [`deco.${placementId}.type`, 'calc.sport'],
        isAvailable: (decoType) => !!(decoType && decoType.id === 'number'),
        visibleOptions: (_, sport) => {
          const sizesByType = placement.props.sizesBySportId[sport.id] ?? {}
          const sizeIds = sizesByType.number?.heightIds
          return compact(map(sizeIds, (id) => DECO_HEIGHT_DICT[id]))
        },
      }),
      font: Select({
        ...boilerplate(PROP_DEF_DICT[`deco_${placementId}_font`]),
        options: FONTS,
        dependencies: ['deco.package', `deco.${placementId}.type`],
        isAvailable: (_, decoType) => !!(decoType && decoType.id !== 'logo'),
        visibleOptions: (decoPackage, decoType) =>
          filter(
            FONTS,
            (font) =>
              font.props.decoTypeId[decoType.id].decoPackageId[
                decoPackage.id
              ] === true,
          ),
      }),
      fill: {
        color: Select({
          ...boilerplate(PROP_DEF_DICT[`deco_${placementId}_fill_color`]),
          options: DECO_COLORS,
          dependencies: ['deco.package', `deco.${placementId}.type`],
          isAvailable: (decoPackage, decoType) =>
            !!(decoType && decoType.id !== 'logo'),
        }),
        customColor: Text({
          ...boilerplate(PROP_DEF_DICT[`deco_${placementId}_fill_customColor`]),
          dependencies: [`deco.${placementId}.fill.color`],
          isAvailable: (decoColor) =>
            !!(decoColor && decoColor.id === 'custom'),
        }),
      },
      outline1: {
        color: Select({
          ...boilerplate(PROP_DEF_DICT[`deco_${placementId}_outline1_color`]),
          options: DECO_COLORS,
          dependencies: ['deco.package', `deco.${placementId}.type`],
          isAvailable: (decoPackage, decoType) =>
            !!(
              decoType &&
              decoType.id !== 'logo' &&
              decoPackage.props.outlineCount[decoType.id] >= 1
            ),
        }),
        customColor: Text({
          ...boilerplate(
            PROP_DEF_DICT[`deco_${placementId}_outline1_customColor`],
          ),
          dependencies: [`deco.${placementId}.outline1.color`],
          isAvailable: (decoColor) =>
            !!(decoColor && decoColor.id === 'custom'),
        }),
      },
      outline2: {
        color: Select({
          ...boilerplate(PROP_DEF_DICT[`deco_${placementId}_outline2_color`]),
          options: DECO_COLORS,
          dependencies: ['deco.package', `deco.${placementId}.type`],
          isAvailable: (decoPackage, decoType) =>
            !!(
              decoType &&
              decoType.id !== 'logo' &&
              decoPackage.props.outlineCount[decoType.id] >= 2
            ),
        }),
        customColor: Text({
          ...boilerplate(
            PROP_DEF_DICT[`deco_${placementId}_outline2_customColor`],
          ),
          dependencies: [`deco.${placementId}.outline2.color`],
          isAvailable: (decoColor) =>
            !!(decoColor && decoColor.id === 'custom'),
        }),
      },
    })),
  },

  details: {
    specialInstructions: {
      text: Text({
        ...boilerplate(PROP_DEF_DICT.details_specialInstructions_text),
        maxLength: 120,
      }),
    },

    recipeName: {
      text: Text({
        ...boilerplate(PROP_DEF_DICT.details_recipeName_text),
        maxLength: 30,
      }),
    },

    roster: Repeater({
      defaultRepeats: 1,
      controls: {
        size: Select({
          ...boilerplate(PROP_DEF_DICT.details_roster_size),
          dependencies: ['product.garment'],
          options: ROSTER_SIZES,
          visibleOptions: (garment) =>
            filter(
              ROSTER_SIZES,
              (size) => garment.props.ageGroupId[size.props.ageGroupId],
            ),
        }),

        quantity: Text({
          ...boilerplate(PROP_DEF_DICT.details_roster_quantity),
          maxLength: 3,
          pattern: /^[0-9]+$/,
          inputType: 'tel',
        }),

        number: Text({
          ...boilerplate(PROP_DEF_DICT.details_roster_number),
          maxLength: 2,
          pattern: /^[0-9]+$/,
          inputType: 'tel',
        }),

        name: Text({
          ...boilerplate(PROP_DEF_DICT.details_roster_name),
          maxLength: 15,
          pattern: /^[a-zA-Z0-9 #]+$/,
        }),
      },
    }),
  },
}

const controlTree = createControlTree(controls)

export default controlTree
