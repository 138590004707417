import * as _ from '@technically/lodash'

const composite = (images, size, overlayCanvas, resultCanvas) => {
  const compositeId = _.uniqueId('composite')
  console.time(compositeId)

  const ctx = resultCanvas.getContext('2d')

  ctx.clearRect(0, 0, resultCanvas.width, resultCanvas.height)
  ctx.save()

  ctx.drawImage(overlayCanvas, 0, 0)

  ctx.globalCompositeOperation = 'multiply'
  ctx.drawImage(images.shadow, 0, 0)

  ctx.globalCompositeOperation = 'screen'
  ctx.drawImage(images.highlight, 0, 0)

  ctx.globalCompositeOperation = 'destination-in'
  ctx.drawImage(images.mask, 0, 0)

  ctx.globalCompositeOperation = 'destination-in'
  ctx.drawImage(overlayCanvas, 0, 0)

  ctx.globalCompositeOperation = 'destination-over'
  ctx.drawImage(images.bg, 0, 0)

  ctx.restore()

  console.timeEnd(compositeId)
}

export default composite
