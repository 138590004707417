import Bluebird from 'bluebird'
import * as _ from '@technically/lodash'

import http from '../../client/http'

class ImageLoader {
  _cache = {}
  _bluebirds = []

  _loadSingle = (src) =>
    new Bluebird((resolve, reject) => {
      const cachedImage = this._cache[src]

      if (cachedImage) {
        return resolve(cachedImage)
      }

      http.get(src, { responseType: 'blob' }).then((response) => {
        const fileReader = new FileReader()

        fileReader.readAsDataURL(response.data)

        fileReader.addEventListener('loadend', () => {
          const image = new Image()

          image.setAttribute('src', fileReader.result)

          image.addEventListener('load', () => {
            this._cache[src] = image

            resolve(image)
          })

          image.addEventListener('error', reject)
        })

        fileReader.addEventListener('error', reject)
      })

      return undefined
    })

  load = (imageSrcs) => {
    const bluebirdsByKey = _.mapValues(imageSrcs, (src) =>
      this._loadSingle(src),
    )
    this._bluebirds = _.concat(this._bluebirds, _.values(bluebirdsByKey))
    return Bluebird.props(bluebirdsByKey).finally(() => {
      // Can't simply reset everything because load can be called many times at once.
      const pendingBluebirds = []
      _.forEach(this._bluebirds, (bluebird) => {
        if (bluebird.isPending()) {
          pendingBluebirds.push(bluebird)
        }
      })
      this._bluebirds = pendingBluebirds
    })
  }

  cancel = () => {
    _.forEach(this._bluebirds, (bluebird) => {
      bluebird.cancel()
    })
    this._bluebirds = []
  }
}

export default ImageLoader
