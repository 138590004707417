import Bluebird from 'bluebird'
import * as _ from '@technically/lodash'

import store from '~c/client/store'
import controlTree from '~c/client/controlTree'

import composite from './composite'
import ModelLogic from './ModelLogic'

import getConfigModule from '~c/renderOverlay/getConfig'

const getConfig = getConfigModule && getConfigModule.default
if (!getConfig) {
  throw new Error('getConfig is not defined')
}

const generatePreviews = (viewNames, imageLoader) => {
  const renderPromises = {}

  const state = store.getState()
  const expandedRecipeNested = controlTree.getExpandedRecipeNested(state)

  _.forEach(viewNames, (viewName) => {
    const promise = new Promise((resolve) => {
      const config = getConfig(viewName, expandedRecipeNested)
      const { size } = config

      const overlayCanvas = document.createElement('canvas')
      const resultCanvas = document.createElement('canvas')

      overlayCanvas.width = size.width
      overlayCanvas.height = size.height
      resultCanvas.width = size.width
      resultCanvas.height = size.height

      const imagesPromise = imageLoader.load(config.images)

      const modelLogic = new ModelLogic(overlayCanvas, config.overlays)

      modelLogic.setSize(size.width, size.height)

      modelLogic.onAfterRender(async () => {
        const images = await imagesPromise

        composite(images, config.size, overlayCanvas, resultCanvas)

        resultCanvas.toBlob((blob) => {
          resolve(blob)
        })
      })
    })
    promise.catch((error) => {
      console.error(error)
    })
    renderPromises[viewName] = promise
  })

  return Bluebird.props(renderPromises)
}

export default generatePreviews
