import fp from 'lodash/fp.js'
import { concat } from 'redux-fp'

import renderOverlayUpdater from '~p/client/renderOverlay/updater'

import controlTree from './controlTree'
import { defaultViewAngleId } from '../viewAngles'

const initialState = {
  renderOverlay: { activeView: defaultViewAngleId },
  closedBlocks: {},
}

const getDefaultState = () => (state) => fp.assign(initialState, state)

export default concat(getDefaultState, controlTree.updater, (action) =>
  fp.update('renderOverlay', renderOverlayUpdater(action)),
)
