// DO NOT EDIT, GENERATED BY "yarn sheets"

import { DecoRow } from '../typings';

const data = [
  {
    "id": "logo",
    "name": "Custom Logo"
  },
  {
    "id": "teamName",
    "name": "Team Name"
  },
  {
    "id": "playerName",
    "name": "Player Name"
  },
  {
    "id": "number",
    "name": "Number"
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<DecoRow[]>> = data;

export type Deco = typeof typed[number];

export const DECO_INDEX_KEY = "id";
export type DecoIndexKey = "id";
export type DecoId = Deco["id"];

let i = 0;
export const DECO_DICT = {
  "logo": typed[i++],
  "teamName": typed[i++],
  "playerName": typed[i++],
  "number": typed[i++]
} as const;

export { typed as DECOS };
