// DO NOT EDIT, GENERATED BY "yarn sheets"

import { PropDefRow } from '../typings';

const data = [
  {
    "id": "filter_sport",
    "name": "Sport",
    "tileType": "name",
    "isOptional": true
  },
  {
    "id": "product_garment",
    "name": "Product",
    "tileType": "image",
    "tileImageProps": "icons/garment/${id}.png",
    "isOptional": false
  },
  {
    "id": "colors_color",
    "name": "Color",
    "tileType": "image",
    "tileImageProps": "icons/garments/${garment}-${id}.png",
    "isOptional": false
  },
  {
    "id": "deco_package",
    "name": "Decoration Package",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "deco_upperFrontLeft_type",
    "name": "Content Type",
    "tileType": "name",
    "isOptional": true
  },
  {
    "id": "deco_upperFrontLeft_teamName",
    "name": "Team Name",
    "description": "Only letters, numbers and spaces are allowed. Maximum 15 characters.",
    "tileType": "text",
    "defaultValueId": "Team Name",
    "isOptional": false
  },
  {
    "id": "deco_upperFrontLeft_playerName",
    "name": "Player Name",
    "description": "Only letters, numbers and spaces are allowed. Maximum 15 characters.",
    "tileType": "text",
    "defaultValueId": "Player Name",
    "isOptional": false
  },
  {
    "id": "deco_upperFrontLeft_number",
    "name": "Number",
    "description": "Maximum 2 characters.",
    "tileType": "text",
    "defaultValueId": "24",
    "isOptional": false
  },
  {
    "id": "deco_upperFrontLeft_logo_previewFile",
    "name": "Preview File",
    "tileType": "upload",
    "isOptional": false
  },
  {
    "id": "deco_upperFrontLeft_logo_factoryFile",
    "name": "Factory File",
    "tileType": "upload",
    "isOptional": true
  },
  {
    "id": "deco_upperFrontLeft_size",
    "name": "Size",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "deco_upperFrontLeft_font",
    "name": "Font",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "deco_upperFrontLeft_fill_color",
    "name": "Fill",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "deco_upperFrontLeft_fill_customColor",
    "name": "Custom Color",
    "tileType": "colorPicker",
    "isOptional": false
  },
  {
    "id": "deco_upperFrontLeft_outline1_color",
    "name": "Outline 1",
    "tileType": "color",
    "isOptional": true
  },
  {
    "id": "deco_upperFrontLeft_outline1_customColor",
    "name": "Custom Color",
    "tileType": "colorPicker",
    "isOptional": false
  },
  {
    "id": "deco_upperFrontLeft_outline2_color",
    "name": "Outline 2",
    "tileType": "color",
    "isOptional": true
  },
  {
    "id": "deco_upperFrontLeft_outline2_customColor",
    "name": "Custom Color",
    "tileType": "colorPicker",
    "isOptional": false
  },
  {
    "id": "deco_upperFrontRight_type",
    "name": "Content Type",
    "tileType": "name",
    "isOptional": true
  },
  {
    "id": "deco_upperFrontRight_teamName",
    "name": "Team Name",
    "description": "Only letters, numbers and spaces are allowed. Maximum 15 characters.",
    "tileType": "text",
    "defaultValueId": "Team Name",
    "isOptional": false
  },
  {
    "id": "deco_upperFrontRight_playerName",
    "name": "Player Name",
    "description": "Only letters, numbers and spaces are allowed. Maximum 15 characters.",
    "tileType": "text",
    "defaultValueId": "Player Name",
    "isOptional": false
  },
  {
    "id": "deco_upperFrontRight_number",
    "name": "Number",
    "description": "Maximum 2 characters.",
    "tileType": "text",
    "defaultValueId": "24",
    "isOptional": false
  },
  {
    "id": "deco_upperFrontRight_logo_previewFile",
    "name": "Preview File",
    "tileType": "upload",
    "isOptional": false
  },
  {
    "id": "deco_upperFrontRight_logo_factoryFile",
    "name": "Factory File",
    "tileType": "upload",
    "isOptional": true
  },
  {
    "id": "deco_upperFrontRight_size",
    "name": "Size",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "deco_upperFrontRight_font",
    "name": "Font",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "deco_upperFrontRight_fill_color",
    "name": "Fill",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "deco_upperFrontRight_fill_customColor",
    "name": "Custom Color",
    "tileType": "colorPicker",
    "isOptional": false
  },
  {
    "id": "deco_upperFrontRight_outline1_color",
    "name": "Outline 1",
    "tileType": "color",
    "isOptional": true
  },
  {
    "id": "deco_upperFrontRight_outline1_customColor",
    "name": "Custom Color",
    "tileType": "colorPicker",
    "isOptional": false
  },
  {
    "id": "deco_upperFrontRight_outline2_color",
    "name": "Outline 2",
    "tileType": "color",
    "isOptional": true
  },
  {
    "id": "deco_upperFrontRight_outline2_customColor",
    "name": "Custom Color",
    "tileType": "colorPicker",
    "isOptional": false
  },
  {
    "id": "deco_fullFront-teamName_teamName",
    "name": "Team Name",
    "description": "Only letters, numbers and spaces are allowed. Maximum 15 characters.",
    "tileType": "text",
    "defaultValueId": "Team Name",
    "isOptional": false
  },
  {
    "id": "deco_fullFront-teamName_type",
    "name": "Content Type",
    "tileType": "name",
    "isOptional": true
  },
  {
    "id": "deco_fullFront-teamName_playerName",
    "name": "Player Name",
    "description": "Only letters, numbers and spaces are allowed. Maximum 15 characters.",
    "tileType": "text",
    "defaultValueId": "Player Name",
    "isOptional": false
  },
  {
    "id": "deco_fullFront-teamName_number",
    "name": "Number",
    "description": "Maximum 2 characters.",
    "tileType": "text",
    "defaultValueId": "24",
    "isOptional": false
  },
  {
    "id": "deco_fullFront-teamName_logo_previewFile",
    "name": "Preview File",
    "tileType": "upload",
    "isOptional": false
  },
  {
    "id": "deco_fullFront-teamName_logo_factoryFile",
    "name": "Factory File",
    "tileType": "upload",
    "isOptional": true
  },
  {
    "id": "deco_fullFront-teamName_size",
    "name": "Size",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "deco_fullFront-teamName_font",
    "name": "Font",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "deco_fullFront-teamName_fill_color",
    "name": "Fill",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "deco_fullFront-teamName_fill_customColor",
    "name": "Custom Color",
    "tileType": "colorPicker",
    "isOptional": false
  },
  {
    "id": "deco_fullFront-teamName_outline1_color",
    "name": "Outline 1",
    "tileType": "color",
    "isOptional": true
  },
  {
    "id": "deco_fullFront-teamName_outline1_customColor",
    "name": "Custom Color",
    "tileType": "colorPicker",
    "isOptional": false
  },
  {
    "id": "deco_fullFront-teamName_outline2_color",
    "name": "Outline 2",
    "tileType": "color",
    "isOptional": true
  },
  {
    "id": "deco_fullFront-teamName_outline2_customColor",
    "name": "Custom Color",
    "tileType": "colorPicker",
    "isOptional": false
  },
  {
    "id": "deco_fullFront_teamName",
    "name": "Team Name",
    "description": "Only letters, numbers and spaces are allowed. Maximum 15 characters.",
    "tileType": "text",
    "defaultValueId": "Team Name",
    "isOptional": false
  },
  {
    "id": "deco_fullFront_type",
    "name": "Content Type",
    "tileType": "name",
    "isOptional": true
  },
  {
    "id": "deco_fullFront_playerName",
    "name": "Player Name",
    "description": "Only letters, numbers and spaces are allowed. Maximum 15 characters.",
    "tileType": "text",
    "defaultValueId": "Player Name",
    "isOptional": false
  },
  {
    "id": "deco_fullFront_number",
    "name": "Number",
    "description": "Maximum 2 characters.",
    "tileType": "text",
    "defaultValueId": "24",
    "isOptional": false
  },
  {
    "id": "deco_fullFront_logo_previewFile",
    "name": "Preview File",
    "tileType": "upload",
    "isOptional": false
  },
  {
    "id": "deco_fullFront_logo_factoryFile",
    "name": "Factory File",
    "tileType": "upload",
    "isOptional": true
  },
  {
    "id": "deco_fullFront_size",
    "name": "Size",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "deco_fullFront_font",
    "name": "Font",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "deco_fullFront_fill_color",
    "name": "Fill",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "deco_fullFront_fill_customColor",
    "name": "Custom Color",
    "tileType": "colorPicker",
    "isOptional": false
  },
  {
    "id": "deco_fullFront_outline1_color",
    "name": "Outline 1",
    "tileType": "color",
    "isOptional": true
  },
  {
    "id": "deco_fullFront_outline1_customColor",
    "name": "Custom Color",
    "tileType": "colorPicker",
    "isOptional": false
  },
  {
    "id": "deco_fullFront_outline2_color",
    "name": "Outline 2",
    "tileType": "color",
    "isOptional": true
  },
  {
    "id": "deco_fullFront_outline2_customColor",
    "name": "Custom Color",
    "tileType": "colorPicker",
    "isOptional": false
  },
  {
    "id": "deco_lowerFrontLeft_teamName",
    "name": "Team Name",
    "description": "Only letters, numbers and spaces are allowed. Maximum 15 characters.",
    "tileType": "text",
    "defaultValueId": "Team Name",
    "isOptional": false
  },
  {
    "id": "deco_lowerFrontLeft_type",
    "name": "Content Type",
    "tileType": "name",
    "isOptional": true
  },
  {
    "id": "deco_lowerFrontLeft_playerName",
    "name": "Player Name",
    "description": "Only letters, numbers and spaces are allowed. Maximum 15 characters.",
    "tileType": "text",
    "defaultValueId": "Player Name",
    "isOptional": false
  },
  {
    "id": "deco_lowerFrontLeft_number",
    "name": "Number",
    "description": "Maximum 2 characters.",
    "tileType": "text",
    "defaultValueId": "24",
    "isOptional": false
  },
  {
    "id": "deco_lowerFrontLeft_logo_previewFile",
    "name": "Preview File",
    "tileType": "upload",
    "isOptional": false
  },
  {
    "id": "deco_lowerFrontLeft_logo_factoryFile",
    "name": "Factory File",
    "tileType": "upload",
    "isOptional": true
  },
  {
    "id": "deco_lowerFrontLeft_size",
    "name": "Size",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "deco_lowerFrontLeft_font",
    "name": "Font",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "deco_lowerFrontLeft_fill_color",
    "name": "Fill",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "deco_lowerFrontLeft_fill_customColor",
    "name": "Custom Color",
    "tileType": "colorPicker",
    "isOptional": false
  },
  {
    "id": "deco_lowerFrontLeft_outline1_color",
    "name": "Outline 1",
    "tileType": "color",
    "isOptional": true
  },
  {
    "id": "deco_lowerFrontLeft_outline1_customColor",
    "name": "Custom Color",
    "tileType": "colorPicker",
    "isOptional": false
  },
  {
    "id": "deco_lowerFrontLeft_outline2_color",
    "name": "Outline 2",
    "tileType": "color",
    "isOptional": true
  },
  {
    "id": "deco_lowerFrontLeft_outline2_customColor",
    "name": "Custom Color",
    "tileType": "colorPicker",
    "isOptional": false
  },
  {
    "id": "deco_lowerFrontRight_teamName",
    "name": "Team Name",
    "description": "Only letters, numbers and spaces are allowed. Maximum 15 characters.",
    "tileType": "text",
    "defaultValueId": "Team Name",
    "isOptional": false
  },
  {
    "id": "deco_lowerFrontRight_type",
    "name": "Content Type",
    "tileType": "name",
    "isOptional": true
  },
  {
    "id": "deco_lowerFrontRight_playerName",
    "name": "Player Name",
    "description": "Only letters, numbers and spaces are allowed. Maximum 15 characters.",
    "tileType": "text",
    "defaultValueId": "Player Name",
    "isOptional": false
  },
  {
    "id": "deco_lowerFrontRight_number",
    "name": "Number",
    "description": "Maximum 2 characters.",
    "tileType": "text",
    "defaultValueId": "24",
    "isOptional": false
  },
  {
    "id": "deco_lowerFrontRight_logo_previewFile",
    "name": "Preview File",
    "tileType": "upload",
    "isOptional": false
  },
  {
    "id": "deco_lowerFrontRight_logo_factoryFile",
    "name": "Factory File",
    "tileType": "upload",
    "isOptional": true
  },
  {
    "id": "deco_lowerFrontRight_size",
    "name": "Size",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "deco_lowerFrontRight_font",
    "name": "Font",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "deco_lowerFrontRight_fill_color",
    "name": "Fill",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "deco_lowerFrontRight_fill_customColor",
    "name": "Custom Color",
    "tileType": "colorPicker",
    "isOptional": false
  },
  {
    "id": "deco_lowerFrontRight_outline1_color",
    "name": "Outline 1",
    "tileType": "color",
    "isOptional": true
  },
  {
    "id": "deco_lowerFrontRight_outline1_customColor",
    "name": "Custom Color",
    "tileType": "colorPicker",
    "isOptional": false
  },
  {
    "id": "deco_lowerFrontRight_outline2_color",
    "name": "Outline 2",
    "tileType": "color",
    "isOptional": true
  },
  {
    "id": "deco_lowerFrontRight_outline2_customColor",
    "name": "Custom Color",
    "tileType": "colorPicker",
    "isOptional": false
  },
  {
    "id": "deco_lowerFrontCenter_teamName",
    "name": "Team Name",
    "description": "Only letters, numbers and spaces are allowed. Maximum 15 characters.",
    "tileType": "text",
    "defaultValueId": "Team Name",
    "isOptional": false
  },
  {
    "id": "deco_lowerFrontCenter_type",
    "name": "Content Type",
    "tileType": "name",
    "isOptional": true
  },
  {
    "id": "deco_lowerFrontCenter_playerName",
    "name": "Player Name",
    "description": "Only letters, numbers and spaces are allowed. Maximum 15 characters.",
    "tileType": "text",
    "defaultValueId": "Player Name",
    "isOptional": false
  },
  {
    "id": "deco_lowerFrontCenter_number",
    "name": "Number",
    "description": "Maximum 2 characters.",
    "tileType": "text",
    "defaultValueId": "24",
    "isOptional": false
  },
  {
    "id": "deco_lowerFrontCenter_logo_previewFile",
    "name": "Preview File",
    "tileType": "upload",
    "isOptional": false
  },
  {
    "id": "deco_lowerFrontCenter_logo_factoryFile",
    "name": "Factory File",
    "tileType": "upload",
    "isOptional": true
  },
  {
    "id": "deco_lowerFrontCenter_size",
    "name": "Size",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "deco_lowerFrontCenter_font",
    "name": "Font",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "deco_lowerFrontCenter_fill_color",
    "name": "Fill",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "deco_lowerFrontCenter_fill_customColor",
    "name": "Custom Color",
    "tileType": "colorPicker",
    "isOptional": false
  },
  {
    "id": "deco_lowerFrontCenter_outline1_color",
    "name": "Outline 1",
    "tileType": "color",
    "isOptional": true
  },
  {
    "id": "deco_lowerFrontCenter_outline1_customColor",
    "name": "Custom Color",
    "tileType": "colorPicker",
    "isOptional": false
  },
  {
    "id": "deco_lowerFrontCenter_outline2_color",
    "name": "Outline 2",
    "tileType": "color",
    "isOptional": true
  },
  {
    "id": "deco_lowerFrontCenter_outline2_customColor",
    "name": "Custom Color",
    "tileType": "colorPicker",
    "isOptional": false
  },
  {
    "id": "deco_upperBackSquare_teamName",
    "name": "Team Name",
    "description": "Only letters, numbers and spaces are allowed. Maximum 15 characters.",
    "tileType": "text",
    "defaultValueId": "Team Name",
    "isOptional": false
  },
  {
    "id": "deco_upperBackSquare_type",
    "name": "Content Type",
    "tileType": "name",
    "isOptional": true
  },
  {
    "id": "deco_upperBackSquare_playerName",
    "name": "Player Name",
    "description": "Only letters, numbers and spaces are allowed. Maximum 15 characters.",
    "tileType": "text",
    "defaultValueId": "Player Name",
    "isOptional": false
  },
  {
    "id": "deco_upperBackSquare_number",
    "name": "Number",
    "description": "Maximum 2 characters.",
    "tileType": "text",
    "defaultValueId": "24",
    "isOptional": false
  },
  {
    "id": "deco_upperBackSquare_logo_previewFile",
    "name": "Preview File",
    "tileType": "upload",
    "isOptional": false
  },
  {
    "id": "deco_upperBackSquare_logo_factoryFile",
    "name": "Factory File",
    "tileType": "upload",
    "isOptional": true
  },
  {
    "id": "deco_upperBackSquare_size",
    "name": "Size",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "deco_upperBackSquare_font",
    "name": "Font",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "deco_upperBackSquare_fill_color",
    "name": "Fill",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "deco_upperBackSquare_fill_customColor",
    "name": "Custom Color",
    "tileType": "colorPicker",
    "isOptional": false
  },
  {
    "id": "deco_upperBackSquare_outline1_color",
    "name": "Outline 1",
    "tileType": "color",
    "isOptional": true
  },
  {
    "id": "deco_upperBackSquare_outline1_customColor",
    "name": "Custom Color",
    "tileType": "colorPicker",
    "isOptional": false
  },
  {
    "id": "deco_upperBackSquare_outline2_color",
    "name": "Outline 2",
    "tileType": "color",
    "isOptional": true
  },
  {
    "id": "deco_upperBackSquare_outline2_customColor",
    "name": "Custom Color",
    "tileType": "colorPicker",
    "isOptional": false
  },
  {
    "id": "deco_upperBackWide_teamName",
    "name": "Team Name",
    "description": "Only letters, numbers and spaces are allowed. Maximum 15 characters.",
    "tileType": "text",
    "defaultValueId": "Team Name",
    "isOptional": false
  },
  {
    "id": "deco_upperBackWide_type",
    "name": "Content Type",
    "tileType": "name",
    "isOptional": true
  },
  {
    "id": "deco_upperBackWide_playerName",
    "name": "Player Name",
    "description": "Only letters, numbers and spaces are allowed. Maximum 15 characters.",
    "tileType": "text",
    "defaultValueId": "Player Name",
    "isOptional": false
  },
  {
    "id": "deco_upperBackWide_number",
    "name": "Number",
    "description": "Maximum 2 characters.",
    "tileType": "text",
    "defaultValueId": "24",
    "isOptional": false
  },
  {
    "id": "deco_upperBackWide_logo_previewFile",
    "name": "Preview File",
    "tileType": "upload",
    "isOptional": false
  },
  {
    "id": "deco_upperBackWide_logo_factoryFile",
    "name": "Factory File",
    "tileType": "upload",
    "isOptional": true
  },
  {
    "id": "deco_upperBackWide_size",
    "name": "Size",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "deco_upperBackWide_font",
    "name": "Font",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "deco_upperBackWide_fill_color",
    "name": "Fill",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "deco_upperBackWide_fill_customColor",
    "name": "Custom Color",
    "tileType": "colorPicker",
    "isOptional": false
  },
  {
    "id": "deco_upperBackWide_outline1_color",
    "name": "Outline 1",
    "tileType": "color",
    "isOptional": true
  },
  {
    "id": "deco_upperBackWide_outline1_customColor",
    "name": "Custom Color",
    "tileType": "colorPicker",
    "isOptional": false
  },
  {
    "id": "deco_upperBackWide_outline2_color",
    "name": "Outline 2",
    "tileType": "color",
    "isOptional": true
  },
  {
    "id": "deco_upperBackWide_outline2_customColor",
    "name": "Custom Color",
    "tileType": "colorPicker",
    "isOptional": false
  },
  {
    "id": "deco_middleBack_teamName",
    "name": "Team Name",
    "description": "Only letters, numbers and spaces are allowed. Maximum 15 characters.",
    "tileType": "text",
    "defaultValueId": "Team Name",
    "isOptional": false
  },
  {
    "id": "deco_middleBack_type",
    "name": "Content Type",
    "tileType": "name",
    "isOptional": true
  },
  {
    "id": "deco_middleBack_playerName",
    "name": "Player Name",
    "description": "Only letters, numbers and spaces are allowed. Maximum 15 characters.",
    "tileType": "text",
    "defaultValueId": "Player Name",
    "isOptional": false
  },
  {
    "id": "deco_middleBack_number",
    "name": "Number",
    "description": "Maximum 2 characters.",
    "tileType": "text",
    "defaultValueId": "24",
    "isOptional": false
  },
  {
    "id": "deco_middleBack_logo_previewFile",
    "name": "Preview File",
    "tileType": "upload",
    "isOptional": false
  },
  {
    "id": "deco_middleBack_logo_factoryFile",
    "name": "Factory File",
    "tileType": "upload",
    "isOptional": true
  },
  {
    "id": "deco_middleBack_size",
    "name": "Size",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "deco_middleBack_font",
    "name": "Font",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "deco_middleBack_fill_color",
    "name": "Fill",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "deco_middleBack_fill_customColor",
    "name": "Custom Color",
    "tileType": "colorPicker",
    "isOptional": false
  },
  {
    "id": "deco_middleBack_outline1_color",
    "name": "Outline 1",
    "tileType": "color",
    "isOptional": true
  },
  {
    "id": "deco_middleBack_outline1_customColor",
    "name": "Custom Color",
    "tileType": "colorPicker",
    "isOptional": false
  },
  {
    "id": "deco_middleBack_outline2_color",
    "name": "Outline 2",
    "tileType": "color",
    "isOptional": true
  },
  {
    "id": "deco_middleBack_outline2_customColor",
    "name": "Custom Color",
    "tileType": "colorPicker",
    "isOptional": false
  },
  {
    "id": "deco_lowerBack_teamName",
    "name": "Team Name",
    "description": "Only letters, numbers and spaces are allowed. Maximum 15 characters.",
    "tileType": "text",
    "defaultValueId": "Team Name",
    "isOptional": false
  },
  {
    "id": "deco_lowerBack_type",
    "name": "Content Type",
    "tileType": "name",
    "isOptional": true
  },
  {
    "id": "deco_lowerBack_playerName",
    "name": "Player Name",
    "description": "Only letters, numbers and spaces are allowed. Maximum 15 characters.",
    "tileType": "text",
    "defaultValueId": "Player Name",
    "isOptional": false
  },
  {
    "id": "deco_lowerBack_number",
    "name": "Number",
    "description": "Maximum 2 characters.",
    "tileType": "text",
    "defaultValueId": "24",
    "isOptional": false
  },
  {
    "id": "deco_lowerBack_logo_previewFile",
    "name": "Preview File",
    "tileType": "upload",
    "isOptional": false
  },
  {
    "id": "deco_lowerBack_logo_factoryFile",
    "name": "Factory File",
    "tileType": "upload",
    "isOptional": true
  },
  {
    "id": "deco_lowerBack_size",
    "name": "Size",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "deco_lowerBack_font",
    "name": "Font",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "deco_lowerBack_fill_color",
    "name": "Fill",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "deco_lowerBack_fill_customColor",
    "name": "Custom Color",
    "tileType": "colorPicker",
    "isOptional": false
  },
  {
    "id": "deco_lowerBack_outline1_color",
    "name": "Outline 1",
    "tileType": "color",
    "isOptional": true
  },
  {
    "id": "deco_lowerBack_outline1_customColor",
    "name": "Custom Color",
    "tileType": "colorPicker",
    "isOptional": false
  },
  {
    "id": "deco_lowerBack_outline2_color",
    "name": "Outline 2",
    "tileType": "color",
    "isOptional": true
  },
  {
    "id": "deco_lowerBack_outline2_customColor",
    "name": "Custom Color",
    "tileType": "colorPicker",
    "isOptional": false
  },
  {
    "id": "deco_fullBack_teamName",
    "name": "Team Name",
    "description": "Only letters, numbers and spaces are allowed. Maximum 15 characters.",
    "tileType": "text",
    "defaultValueId": "Team Name",
    "isOptional": false
  },
  {
    "id": "deco_fullBack_type",
    "name": "Content Type",
    "tileType": "name",
    "isOptional": true
  },
  {
    "id": "deco_fullBack_playerName",
    "name": "Player Name",
    "description": "Only letters, numbers and spaces are allowed. Maximum 15 characters.",
    "tileType": "text",
    "defaultValueId": "Player Name",
    "isOptional": false
  },
  {
    "id": "deco_fullBack_number",
    "name": "Number",
    "description": "Maximum 2 characters.",
    "tileType": "text",
    "defaultValueId": "24",
    "isOptional": false
  },
  {
    "id": "deco_fullBack_logo_previewFile",
    "name": "Preview File",
    "tileType": "upload",
    "isOptional": false
  },
  {
    "id": "deco_fullBack_logo_factoryFile",
    "name": "Factory File",
    "tileType": "upload",
    "isOptional": true
  },
  {
    "id": "deco_fullBack_size",
    "name": "Size",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "deco_fullBack_font",
    "name": "Font",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "deco_fullBack_fill_color",
    "name": "Fill",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "deco_fullBack_fill_customColor",
    "name": "Custom Color",
    "tileType": "colorPicker",
    "isOptional": false
  },
  {
    "id": "deco_fullBack_outline1_color",
    "name": "Outline 1",
    "tileType": "color",
    "isOptional": true
  },
  {
    "id": "deco_fullBack_outline1_customColor",
    "name": "Custom Color",
    "tileType": "colorPicker",
    "isOptional": false
  },
  {
    "id": "deco_fullBack_outline2_color",
    "name": "Outline 2",
    "tileType": "color",
    "isOptional": true
  },
  {
    "id": "deco_fullBack_outline2_customColor",
    "name": "Custom Color",
    "tileType": "colorPicker",
    "isOptional": false
  },
  {
    "id": "deco_leftSleeve_teamName",
    "name": "Team Name",
    "description": "Only letters, numbers and spaces are allowed. Maximum 15 characters.",
    "tileType": "text",
    "defaultValueId": "Team Name",
    "isOptional": false
  },
  {
    "id": "deco_leftSleeve_type",
    "name": "Content Type",
    "tileType": "name",
    "isOptional": true
  },
  {
    "id": "deco_leftSleeve_playerName",
    "name": "Player Name",
    "description": "Only letters, numbers and spaces are allowed. Maximum 15 characters.",
    "tileType": "text",
    "defaultValueId": "Player Name",
    "isOptional": false
  },
  {
    "id": "deco_leftSleeve_number",
    "name": "Number",
    "description": "Maximum 2 characters.",
    "tileType": "text",
    "defaultValueId": "24",
    "isOptional": false
  },
  {
    "id": "deco_leftSleeve_logo_previewFile",
    "name": "Preview File",
    "tileType": "upload",
    "isOptional": false
  },
  {
    "id": "deco_leftSleeve_logo_factoryFile",
    "name": "Factory File",
    "tileType": "upload",
    "isOptional": true
  },
  {
    "id": "deco_leftSleeve_size",
    "name": "Size",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "deco_leftSleeve_font",
    "name": "Font",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "deco_leftSleeve_fill_color",
    "name": "Fill",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "deco_leftSleeve_fill_customColor",
    "name": "Custom Color",
    "tileType": "colorPicker",
    "isOptional": false
  },
  {
    "id": "deco_leftSleeve_outline1_color",
    "name": "Outline 1",
    "tileType": "color",
    "isOptional": true
  },
  {
    "id": "deco_leftSleeve_outline1_customColor",
    "name": "Custom Color",
    "tileType": "colorPicker",
    "isOptional": false
  },
  {
    "id": "deco_leftSleeve_outline2_color",
    "name": "Outline 2",
    "tileType": "color",
    "isOptional": true
  },
  {
    "id": "deco_leftSleeve_outline2_customColor",
    "name": "Custom Color",
    "tileType": "colorPicker",
    "isOptional": false
  },
  {
    "id": "deco_rightSleeve_teamName",
    "name": "Team Name",
    "description": "Only letters, numbers and spaces are allowed. Maximum 15 characters.",
    "tileType": "text",
    "defaultValueId": "Team Name",
    "isOptional": false
  },
  {
    "id": "deco_rightSleeve_type",
    "name": "Content Type",
    "tileType": "name",
    "isOptional": true
  },
  {
    "id": "deco_rightSleeve_playerName",
    "name": "Player Name",
    "description": "Only letters, numbers and spaces are allowed. Maximum 15 characters.",
    "tileType": "text",
    "defaultValueId": "Player Name",
    "isOptional": false
  },
  {
    "id": "deco_rightSleeve_number",
    "name": "Number",
    "description": "Maximum 2 characters.",
    "tileType": "text",
    "defaultValueId": "24",
    "isOptional": false
  },
  {
    "id": "deco_rightSleeve_logo_previewFile",
    "name": "Preview File",
    "tileType": "upload",
    "isOptional": false
  },
  {
    "id": "deco_rightSleeve_logo_factoryFile",
    "name": "Factory File",
    "tileType": "upload",
    "isOptional": true
  },
  {
    "id": "deco_rightSleeve_size",
    "name": "Size",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "deco_rightSleeve_font",
    "name": "Font",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "deco_rightSleeve_fill_color",
    "name": "Fill",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "deco_rightSleeve_fill_customColor",
    "name": "Custom Color",
    "tileType": "colorPicker",
    "isOptional": false
  },
  {
    "id": "deco_rightSleeve_outline1_color",
    "name": "Outline 1",
    "tileType": "color",
    "isOptional": true
  },
  {
    "id": "deco_rightSleeve_outline1_customColor",
    "name": "Custom Color",
    "tileType": "colorPicker",
    "isOptional": false
  },
  {
    "id": "deco_rightSleeve_outline2_color",
    "name": "Outline 2",
    "tileType": "color",
    "isOptional": true
  },
  {
    "id": "deco_rightSleeve_outline2_customColor",
    "name": "Custom Color",
    "tileType": "colorPicker",
    "isOptional": false
  },
  {
    "id": "deco_fullLeftSleeve_teamName",
    "name": "Team Name",
    "description": "Only letters, numbers and spaces are allowed. Maximum 15 characters.",
    "tileType": "text",
    "defaultValueId": "Team Name",
    "isOptional": false
  },
  {
    "id": "deco_fullLeftSleeve_type",
    "name": "Content Type",
    "tileType": "name",
    "isOptional": true
  },
  {
    "id": "deco_fullLeftSleeve_playerName",
    "name": "Player Name",
    "description": "Only letters, numbers and spaces are allowed. Maximum 15 characters.",
    "tileType": "text",
    "defaultValueId": "Player Name",
    "isOptional": false
  },
  {
    "id": "deco_fullLeftSleeve_number",
    "name": "Number",
    "description": "Maximum 2 characters.",
    "tileType": "text",
    "defaultValueId": "24",
    "isOptional": false
  },
  {
    "id": "deco_fullLeftSleeve_logo_previewFile",
    "name": "Preview File",
    "tileType": "upload",
    "isOptional": false
  },
  {
    "id": "deco_fullLeftSleeve_logo_factoryFile",
    "name": "Factory File",
    "tileType": "upload",
    "isOptional": true
  },
  {
    "id": "deco_fullLeftSleeve_size",
    "name": "Size",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "deco_fullLeftSleeve_font",
    "name": "Font",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "deco_fullLeftSleeve_fill_color",
    "name": "Fill",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "deco_fullLeftSleeve_fill_customColor",
    "name": "Custom Color",
    "tileType": "colorPicker",
    "isOptional": false
  },
  {
    "id": "deco_fullLeftSleeve_outline1_color",
    "name": "Outline 1",
    "tileType": "color",
    "isOptional": true
  },
  {
    "id": "deco_fullLeftSleeve_outline1_customColor",
    "name": "Custom Color",
    "tileType": "colorPicker",
    "isOptional": false
  },
  {
    "id": "deco_fullLeftSleeve_outline2_color",
    "name": "Outline 2",
    "tileType": "color",
    "isOptional": true
  },
  {
    "id": "deco_fullLeftSleeve_outline2_customColor",
    "name": "Custom Color",
    "tileType": "colorPicker",
    "isOptional": false
  },
  {
    "id": "deco_fullRightSleeve_teamName",
    "name": "Team Name",
    "description": "Only letters, numbers and spaces are allowed. Maximum 15 characters.",
    "tileType": "text",
    "defaultValueId": "Team Name",
    "isOptional": false
  },
  {
    "id": "deco_fullRightSleeve_type",
    "name": "Content Type",
    "tileType": "name",
    "isOptional": true
  },
  {
    "id": "deco_fullRightSleeve_playerName",
    "name": "Player Name",
    "description": "Only letters, numbers and spaces are allowed. Maximum 15 characters.",
    "tileType": "text",
    "defaultValueId": "Player Name",
    "isOptional": false
  },
  {
    "id": "deco_fullRightSleeve_number",
    "name": "Number",
    "description": "Maximum 2 characters.",
    "tileType": "text",
    "defaultValueId": "24",
    "isOptional": false
  },
  {
    "id": "deco_fullRightSleeve_logo_previewFile",
    "name": "Preview File",
    "tileType": "upload",
    "isOptional": false
  },
  {
    "id": "deco_fullRightSleeve_logo_factoryFile",
    "name": "Factory File",
    "tileType": "upload",
    "isOptional": true
  },
  {
    "id": "deco_fullRightSleeve_size",
    "name": "Size",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "deco_fullRightSleeve_font",
    "name": "Font",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "deco_fullRightSleeve_fill_color",
    "name": "Fill",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "deco_fullRightSleeve_fill_customColor",
    "name": "Custom Color",
    "tileType": "colorPicker",
    "isOptional": false
  },
  {
    "id": "deco_fullRightSleeve_outline1_color",
    "name": "Outline 1",
    "tileType": "color",
    "isOptional": true
  },
  {
    "id": "deco_fullRightSleeve_outline1_customColor",
    "name": "Custom Color",
    "tileType": "colorPicker",
    "isOptional": false
  },
  {
    "id": "deco_fullRightSleeve_outline2_color",
    "name": "Outline 2",
    "tileType": "color",
    "isOptional": true
  },
  {
    "id": "deco_fullRightSleeve_outline2_customColor",
    "name": "Custom Color",
    "tileType": "colorPicker",
    "isOptional": false
  },
  {
    "id": "deco_rightSide_teamName",
    "name": "Team Name",
    "description": "Only letters, numbers and spaces are allowed. Maximum 15 characters.",
    "tileType": "text",
    "defaultValueId": "Team Name",
    "isOptional": false
  },
  {
    "id": "deco_rightSide_type",
    "name": "Content Type",
    "tileType": "name",
    "isOptional": true
  },
  {
    "id": "deco_rightSide_playerName",
    "name": "Player Name",
    "description": "Only letters, numbers and spaces are allowed. Maximum 15 characters.",
    "tileType": "text",
    "defaultValueId": "Player Name",
    "isOptional": false
  },
  {
    "id": "deco_rightSide_number",
    "name": "Number",
    "description": "Maximum 2 characters.",
    "tileType": "text",
    "defaultValueId": "24",
    "isOptional": false
  },
  {
    "id": "deco_rightSide_logo_previewFile",
    "name": "Preview File",
    "tileType": "upload",
    "isOptional": false
  },
  {
    "id": "deco_rightSide_logo_factoryFile",
    "name": "Factory File",
    "tileType": "upload",
    "isOptional": true
  },
  {
    "id": "deco_rightSide_size",
    "name": "Size",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "deco_rightSide_font",
    "name": "Font",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "deco_rightSide_fill_color",
    "name": "Fill",
    "tileType": "color",
    "isOptional": false
  },
  {
    "id": "deco_rightSide_fill_customColor",
    "name": "Custom Color",
    "tileType": "colorPicker",
    "isOptional": false
  },
  {
    "id": "deco_rightSide_outline1_color",
    "name": "Outline 1",
    "tileType": "color",
    "isOptional": true
  },
  {
    "id": "deco_rightSide_outline1_customColor",
    "name": "Custom Color",
    "tileType": "colorPicker",
    "isOptional": false
  },
  {
    "id": "deco_rightSide_outline2_color",
    "name": "Outline 2",
    "tileType": "color",
    "isOptional": true
  },
  {
    "id": "deco_rightSide_outline2_customColor",
    "name": "Custom Color",
    "tileType": "colorPicker",
    "isOptional": false
  },
  {
    "id": "details_specialInstructions_text",
    "name": "Special Instructions",
    "description": "Maximum 120 characters.",
    "tileType": "text",
    "isOptional": true
  },
  {
    "id": "details_recipeName_text",
    "name": "Recipe Name",
    "description": "Maximum 30 characters.",
    "tileType": "text",
    "isOptional": true
  },
  {
    "id": "details_roster_size",
    "name": "Size",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "details_roster_quantity",
    "name": "Quantity",
    "tileType": "text",
    "defaultValueId": "1",
    "isOptional": false
  },
  {
    "id": "details_roster_number",
    "name": "Number",
    "tileType": "text",
    "isOptional": true
  },
  {
    "id": "details_roster_name",
    "name": "Name",
    "tileType": "text",
    "isOptional": true
  },
  {
    "id": "calc_sport",
    "name": "Calc Sport",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "calc_sku",
    "name": "Calc SKU",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "calc_roster",
    "name": "Team Roster",
    "tileType": "name",
    "isOptional": false
  },
  {
    "id": "calc_sizes",
    "name": "Size Summary",
    "tileType": "name",
    "isOptional": false
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<PropDefRow[]>> = data;

export type PropDef = typeof typed[number];

export const PROP_DEF_INDEX_KEY = "id";
export type PropDefIndexKey = "id";
export type PropDefId = PropDef["id"];

let i = 0;
export const PROP_DEF_DICT = {
  "filter_sport": typed[i++],
  "product_garment": typed[i++],
  "colors_color": typed[i++],
  "deco_package": typed[i++],
  "deco_upperFrontLeft_type": typed[i++],
  "deco_upperFrontLeft_teamName": typed[i++],
  "deco_upperFrontLeft_playerName": typed[i++],
  "deco_upperFrontLeft_number": typed[i++],
  "deco_upperFrontLeft_logo_previewFile": typed[i++],
  "deco_upperFrontLeft_logo_factoryFile": typed[i++],
  "deco_upperFrontLeft_size": typed[i++],
  "deco_upperFrontLeft_font": typed[i++],
  "deco_upperFrontLeft_fill_color": typed[i++],
  "deco_upperFrontLeft_fill_customColor": typed[i++],
  "deco_upperFrontLeft_outline1_color": typed[i++],
  "deco_upperFrontLeft_outline1_customColor": typed[i++],
  "deco_upperFrontLeft_outline2_color": typed[i++],
  "deco_upperFrontLeft_outline2_customColor": typed[i++],
  "deco_upperFrontRight_type": typed[i++],
  "deco_upperFrontRight_teamName": typed[i++],
  "deco_upperFrontRight_playerName": typed[i++],
  "deco_upperFrontRight_number": typed[i++],
  "deco_upperFrontRight_logo_previewFile": typed[i++],
  "deco_upperFrontRight_logo_factoryFile": typed[i++],
  "deco_upperFrontRight_size": typed[i++],
  "deco_upperFrontRight_font": typed[i++],
  "deco_upperFrontRight_fill_color": typed[i++],
  "deco_upperFrontRight_fill_customColor": typed[i++],
  "deco_upperFrontRight_outline1_color": typed[i++],
  "deco_upperFrontRight_outline1_customColor": typed[i++],
  "deco_upperFrontRight_outline2_color": typed[i++],
  "deco_upperFrontRight_outline2_customColor": typed[i++],
  "deco_fullFront-teamName_teamName": typed[i++],
  "deco_fullFront-teamName_type": typed[i++],
  "deco_fullFront-teamName_playerName": typed[i++],
  "deco_fullFront-teamName_number": typed[i++],
  "deco_fullFront-teamName_logo_previewFile": typed[i++],
  "deco_fullFront-teamName_logo_factoryFile": typed[i++],
  "deco_fullFront-teamName_size": typed[i++],
  "deco_fullFront-teamName_font": typed[i++],
  "deco_fullFront-teamName_fill_color": typed[i++],
  "deco_fullFront-teamName_fill_customColor": typed[i++],
  "deco_fullFront-teamName_outline1_color": typed[i++],
  "deco_fullFront-teamName_outline1_customColor": typed[i++],
  "deco_fullFront-teamName_outline2_color": typed[i++],
  "deco_fullFront-teamName_outline2_customColor": typed[i++],
  "deco_fullFront_teamName": typed[i++],
  "deco_fullFront_type": typed[i++],
  "deco_fullFront_playerName": typed[i++],
  "deco_fullFront_number": typed[i++],
  "deco_fullFront_logo_previewFile": typed[i++],
  "deco_fullFront_logo_factoryFile": typed[i++],
  "deco_fullFront_size": typed[i++],
  "deco_fullFront_font": typed[i++],
  "deco_fullFront_fill_color": typed[i++],
  "deco_fullFront_fill_customColor": typed[i++],
  "deco_fullFront_outline1_color": typed[i++],
  "deco_fullFront_outline1_customColor": typed[i++],
  "deco_fullFront_outline2_color": typed[i++],
  "deco_fullFront_outline2_customColor": typed[i++],
  "deco_lowerFrontLeft_teamName": typed[i++],
  "deco_lowerFrontLeft_type": typed[i++],
  "deco_lowerFrontLeft_playerName": typed[i++],
  "deco_lowerFrontLeft_number": typed[i++],
  "deco_lowerFrontLeft_logo_previewFile": typed[i++],
  "deco_lowerFrontLeft_logo_factoryFile": typed[i++],
  "deco_lowerFrontLeft_size": typed[i++],
  "deco_lowerFrontLeft_font": typed[i++],
  "deco_lowerFrontLeft_fill_color": typed[i++],
  "deco_lowerFrontLeft_fill_customColor": typed[i++],
  "deco_lowerFrontLeft_outline1_color": typed[i++],
  "deco_lowerFrontLeft_outline1_customColor": typed[i++],
  "deco_lowerFrontLeft_outline2_color": typed[i++],
  "deco_lowerFrontLeft_outline2_customColor": typed[i++],
  "deco_lowerFrontRight_teamName": typed[i++],
  "deco_lowerFrontRight_type": typed[i++],
  "deco_lowerFrontRight_playerName": typed[i++],
  "deco_lowerFrontRight_number": typed[i++],
  "deco_lowerFrontRight_logo_previewFile": typed[i++],
  "deco_lowerFrontRight_logo_factoryFile": typed[i++],
  "deco_lowerFrontRight_size": typed[i++],
  "deco_lowerFrontRight_font": typed[i++],
  "deco_lowerFrontRight_fill_color": typed[i++],
  "deco_lowerFrontRight_fill_customColor": typed[i++],
  "deco_lowerFrontRight_outline1_color": typed[i++],
  "deco_lowerFrontRight_outline1_customColor": typed[i++],
  "deco_lowerFrontRight_outline2_color": typed[i++],
  "deco_lowerFrontRight_outline2_customColor": typed[i++],
  "deco_lowerFrontCenter_teamName": typed[i++],
  "deco_lowerFrontCenter_type": typed[i++],
  "deco_lowerFrontCenter_playerName": typed[i++],
  "deco_lowerFrontCenter_number": typed[i++],
  "deco_lowerFrontCenter_logo_previewFile": typed[i++],
  "deco_lowerFrontCenter_logo_factoryFile": typed[i++],
  "deco_lowerFrontCenter_size": typed[i++],
  "deco_lowerFrontCenter_font": typed[i++],
  "deco_lowerFrontCenter_fill_color": typed[i++],
  "deco_lowerFrontCenter_fill_customColor": typed[i++],
  "deco_lowerFrontCenter_outline1_color": typed[i++],
  "deco_lowerFrontCenter_outline1_customColor": typed[i++],
  "deco_lowerFrontCenter_outline2_color": typed[i++],
  "deco_lowerFrontCenter_outline2_customColor": typed[i++],
  "deco_upperBackSquare_teamName": typed[i++],
  "deco_upperBackSquare_type": typed[i++],
  "deco_upperBackSquare_playerName": typed[i++],
  "deco_upperBackSquare_number": typed[i++],
  "deco_upperBackSquare_logo_previewFile": typed[i++],
  "deco_upperBackSquare_logo_factoryFile": typed[i++],
  "deco_upperBackSquare_size": typed[i++],
  "deco_upperBackSquare_font": typed[i++],
  "deco_upperBackSquare_fill_color": typed[i++],
  "deco_upperBackSquare_fill_customColor": typed[i++],
  "deco_upperBackSquare_outline1_color": typed[i++],
  "deco_upperBackSquare_outline1_customColor": typed[i++],
  "deco_upperBackSquare_outline2_color": typed[i++],
  "deco_upperBackSquare_outline2_customColor": typed[i++],
  "deco_upperBackWide_teamName": typed[i++],
  "deco_upperBackWide_type": typed[i++],
  "deco_upperBackWide_playerName": typed[i++],
  "deco_upperBackWide_number": typed[i++],
  "deco_upperBackWide_logo_previewFile": typed[i++],
  "deco_upperBackWide_logo_factoryFile": typed[i++],
  "deco_upperBackWide_size": typed[i++],
  "deco_upperBackWide_font": typed[i++],
  "deco_upperBackWide_fill_color": typed[i++],
  "deco_upperBackWide_fill_customColor": typed[i++],
  "deco_upperBackWide_outline1_color": typed[i++],
  "deco_upperBackWide_outline1_customColor": typed[i++],
  "deco_upperBackWide_outline2_color": typed[i++],
  "deco_upperBackWide_outline2_customColor": typed[i++],
  "deco_middleBack_teamName": typed[i++],
  "deco_middleBack_type": typed[i++],
  "deco_middleBack_playerName": typed[i++],
  "deco_middleBack_number": typed[i++],
  "deco_middleBack_logo_previewFile": typed[i++],
  "deco_middleBack_logo_factoryFile": typed[i++],
  "deco_middleBack_size": typed[i++],
  "deco_middleBack_font": typed[i++],
  "deco_middleBack_fill_color": typed[i++],
  "deco_middleBack_fill_customColor": typed[i++],
  "deco_middleBack_outline1_color": typed[i++],
  "deco_middleBack_outline1_customColor": typed[i++],
  "deco_middleBack_outline2_color": typed[i++],
  "deco_middleBack_outline2_customColor": typed[i++],
  "deco_lowerBack_teamName": typed[i++],
  "deco_lowerBack_type": typed[i++],
  "deco_lowerBack_playerName": typed[i++],
  "deco_lowerBack_number": typed[i++],
  "deco_lowerBack_logo_previewFile": typed[i++],
  "deco_lowerBack_logo_factoryFile": typed[i++],
  "deco_lowerBack_size": typed[i++],
  "deco_lowerBack_font": typed[i++],
  "deco_lowerBack_fill_color": typed[i++],
  "deco_lowerBack_fill_customColor": typed[i++],
  "deco_lowerBack_outline1_color": typed[i++],
  "deco_lowerBack_outline1_customColor": typed[i++],
  "deco_lowerBack_outline2_color": typed[i++],
  "deco_lowerBack_outline2_customColor": typed[i++],
  "deco_fullBack_teamName": typed[i++],
  "deco_fullBack_type": typed[i++],
  "deco_fullBack_playerName": typed[i++],
  "deco_fullBack_number": typed[i++],
  "deco_fullBack_logo_previewFile": typed[i++],
  "deco_fullBack_logo_factoryFile": typed[i++],
  "deco_fullBack_size": typed[i++],
  "deco_fullBack_font": typed[i++],
  "deco_fullBack_fill_color": typed[i++],
  "deco_fullBack_fill_customColor": typed[i++],
  "deco_fullBack_outline1_color": typed[i++],
  "deco_fullBack_outline1_customColor": typed[i++],
  "deco_fullBack_outline2_color": typed[i++],
  "deco_fullBack_outline2_customColor": typed[i++],
  "deco_leftSleeve_teamName": typed[i++],
  "deco_leftSleeve_type": typed[i++],
  "deco_leftSleeve_playerName": typed[i++],
  "deco_leftSleeve_number": typed[i++],
  "deco_leftSleeve_logo_previewFile": typed[i++],
  "deco_leftSleeve_logo_factoryFile": typed[i++],
  "deco_leftSleeve_size": typed[i++],
  "deco_leftSleeve_font": typed[i++],
  "deco_leftSleeve_fill_color": typed[i++],
  "deco_leftSleeve_fill_customColor": typed[i++],
  "deco_leftSleeve_outline1_color": typed[i++],
  "deco_leftSleeve_outline1_customColor": typed[i++],
  "deco_leftSleeve_outline2_color": typed[i++],
  "deco_leftSleeve_outline2_customColor": typed[i++],
  "deco_rightSleeve_teamName": typed[i++],
  "deco_rightSleeve_type": typed[i++],
  "deco_rightSleeve_playerName": typed[i++],
  "deco_rightSleeve_number": typed[i++],
  "deco_rightSleeve_logo_previewFile": typed[i++],
  "deco_rightSleeve_logo_factoryFile": typed[i++],
  "deco_rightSleeve_size": typed[i++],
  "deco_rightSleeve_font": typed[i++],
  "deco_rightSleeve_fill_color": typed[i++],
  "deco_rightSleeve_fill_customColor": typed[i++],
  "deco_rightSleeve_outline1_color": typed[i++],
  "deco_rightSleeve_outline1_customColor": typed[i++],
  "deco_rightSleeve_outline2_color": typed[i++],
  "deco_rightSleeve_outline2_customColor": typed[i++],
  "deco_fullLeftSleeve_teamName": typed[i++],
  "deco_fullLeftSleeve_type": typed[i++],
  "deco_fullLeftSleeve_playerName": typed[i++],
  "deco_fullLeftSleeve_number": typed[i++],
  "deco_fullLeftSleeve_logo_previewFile": typed[i++],
  "deco_fullLeftSleeve_logo_factoryFile": typed[i++],
  "deco_fullLeftSleeve_size": typed[i++],
  "deco_fullLeftSleeve_font": typed[i++],
  "deco_fullLeftSleeve_fill_color": typed[i++],
  "deco_fullLeftSleeve_fill_customColor": typed[i++],
  "deco_fullLeftSleeve_outline1_color": typed[i++],
  "deco_fullLeftSleeve_outline1_customColor": typed[i++],
  "deco_fullLeftSleeve_outline2_color": typed[i++],
  "deco_fullLeftSleeve_outline2_customColor": typed[i++],
  "deco_fullRightSleeve_teamName": typed[i++],
  "deco_fullRightSleeve_type": typed[i++],
  "deco_fullRightSleeve_playerName": typed[i++],
  "deco_fullRightSleeve_number": typed[i++],
  "deco_fullRightSleeve_logo_previewFile": typed[i++],
  "deco_fullRightSleeve_logo_factoryFile": typed[i++],
  "deco_fullRightSleeve_size": typed[i++],
  "deco_fullRightSleeve_font": typed[i++],
  "deco_fullRightSleeve_fill_color": typed[i++],
  "deco_fullRightSleeve_fill_customColor": typed[i++],
  "deco_fullRightSleeve_outline1_color": typed[i++],
  "deco_fullRightSleeve_outline1_customColor": typed[i++],
  "deco_fullRightSleeve_outline2_color": typed[i++],
  "deco_fullRightSleeve_outline2_customColor": typed[i++],
  "deco_rightSide_teamName": typed[i++],
  "deco_rightSide_type": typed[i++],
  "deco_rightSide_playerName": typed[i++],
  "deco_rightSide_number": typed[i++],
  "deco_rightSide_logo_previewFile": typed[i++],
  "deco_rightSide_logo_factoryFile": typed[i++],
  "deco_rightSide_size": typed[i++],
  "deco_rightSide_font": typed[i++],
  "deco_rightSide_fill_color": typed[i++],
  "deco_rightSide_fill_customColor": typed[i++],
  "deco_rightSide_outline1_color": typed[i++],
  "deco_rightSide_outline1_customColor": typed[i++],
  "deco_rightSide_outline2_color": typed[i++],
  "deco_rightSide_outline2_customColor": typed[i++],
  "details_specialInstructions_text": typed[i++],
  "details_recipeName_text": typed[i++],
  "details_roster_size": typed[i++],
  "details_roster_quantity": typed[i++],
  "details_roster_number": typed[i++],
  "details_roster_name": typed[i++],
  "calc_sport": typed[i++],
  "calc_sku": typed[i++],
  "calc_roster": typed[i++],
  "calc_sizes": typed[i++]
} as const;

export { typed as PROP_DEFS };
