import { connect } from 'react-redux'

import {
  isRecipeFinalizedSelector,
  createMenuSelector,
  isAppLoadingSelector,
  getPreviewUrlsSelector,
} from '~p/client/common/selectors'
import {
  togglePreviewMinimization,
  saveRecipe,
  toggleShare,
  copyLinkToClipboard,
  openMenu,
  setPreviewGenerator,
} from '~p/client/common/actions'
import {
  setRenderOverlayLoading,
  setRenderOverlayActiveView,
} from '~p/client/renderOverlay/actions'

import viewAngles, { defaultViewAngleId } from '../../viewAngles'
import controlTree from '../controlTree'
import Root from '../components/Root'
import getConfig from '../../renderOverlay/getConfig'

const menuSelector = createMenuSelector(controlTree)

const mapStateToProps = (state) => {
  const nodes = controlTree.getNodes(state)
  const productNode = nodes['product.garment']

  const productName = productNode.optionName
  const products = productNode.visibleOptions || productNode.options

  const expandedRecipeNested = controlTree.getExpandedRecipeNested(state)

  const { activeView, isLoading } = state.renderOverlay

  return {
    nodes,
    productName,
    products,
    expandedRecipeNested,
    menu: menuSelector(state),
    isRecipeFinalized: isRecipeFinalizedSelector(state),
    isAppLoading: isAppLoadingSelector(state),
    isPreviewMinimized: state.isPreviewMinimized,
    renderOverlayActiveView: activeView,
    renderOverlayIsLoading: isLoading,
    renderOverlayConfig: getConfig(activeView, expandedRecipeNested),
    previewUrls: getPreviewUrlsSelector(viewAngles, defaultViewAngleId)(state),
  }
}

const mapDispatchToProps = (dispatch) => ({
  saveRecipe: () => dispatch(saveRecipe(controlTree)),
  toggleShare: () => dispatch(toggleShare()),
  copyLinkToClipboard: () => dispatch(copyLinkToClipboard()),
  togglePreviewMinimization: () => dispatch(togglePreviewMinimization()),
  changeProduct: (id) => {
    dispatch(controlTree.change('product.garment', id))
    dispatch(openMenu(controlTree, 'colors'))
  },
  setRenderOverlayLoading: (isLoading) =>
    dispatch(setRenderOverlayLoading(isLoading)),
  setRenderOverlayActiveView: (activeView) =>
    dispatch(setRenderOverlayActiveView(activeView)),
  setPreviewGenerator: (previewGenerator) =>
    dispatch(setPreviewGenerator(previewGenerator)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Root)
