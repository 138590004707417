import customizerId from '~p/customizerId'
import getRevedImagePath from '~p/client/renderComposite/data/getRevedImagePath'

import { revManifests } from '~c/assetPipeline/manifest'

const muteAssetWarnings = process.env.MUTE_ASSET_WARNINGS === '1'
const localCompositeAssets = process.env.LOCAL_COMPOSITE_ASSETS === '1'

const getCompositeAsset = (bundleName, size, pathParts) => {
  const manifest = revManifests && revManifests[bundleName]
  if (!manifest) {
    if (!muteAssetWarnings) {
      console.warn('No manifest found for', '\nbundle:', bundleName)
    }
    return ''
  }

  const filePath = getRevedImagePath(manifest, pathParts)
  if (filePath == null) {
    if (!muteAssetWarnings) {
      console.warn(
        'No revision found',
        '\nbundle:',
        bundleName,
        '\nfile:',
        pathParts.join('/'),
      )
    }
    return ''
  }

  const basePath =
    localCompositeAssets ?
      '/assetPipeline/remoteResources/resize/'
    : `//storage.googleapis.com/olv-customizers-${customizerId}-`

  return `${basePath}${bundleName}/x${size.height}/${filePath}`
}

export default getCompositeAsset
