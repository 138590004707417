// DO NOT EDIT, GENERATED BY "yarn sheets"

import { DecoColorRow } from '../typings';

const data = [
  {
    "id": "white",
    "name": "White",
    "props": {
      "hex": "#FFEBCD",
      "decoTypeId": {
        "teamName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "playerName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "number": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        }
      }
    }
  },
  {
    "id": "black",
    "name": "Black",
    "props": {
      "hex": "#87CEEB",
      "decoTypeId": {
        "teamName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "playerName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "number": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        }
      }
    }
  },
  {
    "id": "darkCharcoal",
    "name": "Dark Charcoal",
    "props": {
      "hex": "#4169E1",
      "decoTypeId": {
        "teamName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "playerName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "number": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": false,
            "tackleTwill": true
          }
        }
      }
    }
  },
  {
    "id": "shade",
    "name": "Shade",
    "props": {
      "hex": "#32CD32",
      "decoTypeId": {
        "teamName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "playerName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "number": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": false,
            "tackleTwill": false
          }
        }
      }
    }
  },
  {
    "id": "silver",
    "name": "Silver",
    "props": {
      "hex": "#FFC0CB",
      "decoTypeId": {
        "teamName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "playerName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "number": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": false,
            "tackleTwill": false
          }
        }
      }
    }
  },
  {
    "id": "charcoal",
    "name": "Charcoal",
    "props": {
      "hex": "#E9967A",
      "decoTypeId": {
        "teamName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "playerName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "number": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": false,
            "tackleTwill": true
          }
        }
      }
    }
  },
  {
    "id": "gray",
    "name": "Gray",
    "props": {
      "hex": "#FFDAB9",
      "decoTypeId": {
        "teamName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "playerName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "number": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        }
      }
    }
  },
  {
    "id": "maroon",
    "name": "Maroon",
    "props": {
      "hex": "#FAFAD2",
      "decoTypeId": {
        "teamName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "playerName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "number": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        }
      }
    }
  },
  {
    "id": "cardinal",
    "name": "Cardinal",
    "props": {
      "hex": "#AFEEEE",
      "decoTypeId": {
        "teamName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "playerName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "number": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": false,
            "tackleTwill": true
          }
        }
      }
    }
  },
  {
    "id": "red",
    "name": "Red",
    "props": {
      "hex": "#F0FFFF",
      "decoTypeId": {
        "teamName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "playerName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "number": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        }
      }
    }
  },
  {
    "id": "blazingOrange",
    "name": "Blazing Orange",
    "props": {
      "hex": "#FF00FF",
      "decoTypeId": {
        "teamName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "playerName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "number": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": false
          }
        }
      }
    }
  },
  {
    "id": "orange",
    "name": "Orange",
    "props": {
      "hex": "#D2691E",
      "decoTypeId": {
        "teamName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "playerName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "number": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": false,
            "tackleTwill": true
          }
        }
      }
    }
  },
  {
    "id": "brown",
    "name": "Brown",
    "props": {
      "hex": "#FFA07A",
      "decoTypeId": {
        "teamName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "playerName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "number": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": false,
            "tackleTwill": true
          }
        }
      }
    }
  },
  {
    "id": "espresso",
    "name": "Espresso",
    "props": {
      "hex": "#7B68EE",
      "decoTypeId": {
        "teamName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "playerName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "number": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": false,
            "tackleTwill": false
          }
        }
      }
    }
  },
  {
    "id": "vegasGold",
    "name": "Vegas Gold",
    "props": {
      "hex": "#9400D3",
      "decoTypeId": {
        "teamName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "playerName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "number": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        }
      }
    }
  },
  {
    "id": "yellow",
    "name": "Yellow",
    "props": {
      "hex": "#FFFFF0",
      "decoTypeId": {
        "teamName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "playerName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "number": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        }
      }
    }
  },
  {
    "id": "lemon",
    "name": "Lemon",
    "props": {
      "hex": "#FFFACD",
      "decoTypeId": {
        "teamName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "playerName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "number": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": false,
            "tackleTwill": true
          }
        }
      }
    }
  },
  {
    "id": "electricGreen",
    "name": "Electric Green",
    "props": {
      "hex": "#98FB98",
      "decoTypeId": {
        "teamName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "playerName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "number": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        }
      }
    }
  },
  {
    "id": "kelly",
    "name": "Kelly",
    "props": {
      "hex": "#7FFF00",
      "decoTypeId": {
        "teamName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "playerName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "number": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": false,
            "tackleTwill": true
          }
        }
      }
    }
  },
  {
    "id": "forest",
    "name": "Forest",
    "props": {
      "hex": "#191970",
      "decoTypeId": {
        "teamName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "playerName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "number": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": false,
            "tackleTwill": true
          }
        }
      }
    }
  },
  {
    "id": "navy",
    "name": "Navy",
    "props": {
      "hex": "#8A2BE2",
      "decoTypeId": {
        "teamName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "playerName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "number": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        }
      }
    }
  },
  {
    "id": "royal",
    "name": "Royal",
    "props": {
      "hex": "#778899",
      "decoTypeId": {
        "teamName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "playerName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "number": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        }
      }
    }
  },
  {
    "id": "divaBlue",
    "name": "Diva Blue",
    "props": {
      "hex": "#DDA0DD",
      "decoTypeId": {
        "teamName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "playerName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "number": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": false,
            "tackleTwill": false
          }
        }
      }
    }
  },
  {
    "id": "columbiaBlue",
    "name": "Columbia Blue",
    "props": {
      "hex": "#FFFAF0",
      "decoTypeId": {
        "teamName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "playerName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "number": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        }
      }
    }
  },
  {
    "id": "purple",
    "name": "Purple",
    "props": {
      "hex": "#00FFFF",
      "decoTypeId": {
        "teamName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "playerName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "number": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        }
      }
    }
  },
  {
    "id": "electricPurple",
    "name": "Electric Purple",
    "props": {
      "hex": "#CD5C5C",
      "decoTypeId": {
        "teamName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "playerName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "number": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": false,
            "tackleTwill": false
          }
        }
      }
    }
  },
  {
    "id": "shockingPink",
    "name": "Shocking Pink",
    "props": {
      "hex": "#EEE8AA",
      "decoTypeId": {
        "teamName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "playerName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "number": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": false,
            "tackleTwill": true
          }
        }
      }
    }
  },
  {
    "id": "pink",
    "name": "Pink",
    "props": {
      "hex": "#483D8B",
      "decoTypeId": {
        "teamName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "playerName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "number": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": false,
            "tackleTwill": true
          }
        }
      }
    }
  },
  {
    "id": "opticYellow",
    "name": "Optic Yellow",
    "props": {
      "hex": "#6B8E23",
      "decoTypeId": {
        "teamName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "playerName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "number": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": false,
            "tackleTwill": false
          }
        }
      }
    }
  },
  {
    "id": "texasOrange",
    "name": "Texas Orange",
    "props": {
      "hex": "#4682b4",
      "decoTypeId": {
        "teamName": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": true
          }
        },
        "playerName": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": false,
            "tackleTwill": true
          }
        },
        "number": {
          "decoPackageId": {
            "embroidery": true,
            "screenPrint": false,
            "tackleTwill": true
          }
        }
      }
    }
  },
  {
    "id": "custom",
    "name": "Custom",
    "props": {
      "hex": "#008000",
      "decoTypeId": {
        "teamName": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": true,
            "tackleTwill": false
          }
        },
        "playerName": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        },
        "number": {
          "decoPackageId": {
            "embroidery": false,
            "screenPrint": false,
            "tackleTwill": false
          }
        }
      }
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<DecoColorRow[]>> = data;

export type DecoColor = typeof typed[number];

export const DECO_COLOR_INDEX_KEY = "id";
export type DecoColorIndexKey = "id";
export type DecoColorId = DecoColor["id"];

let i = 0;
export const DECO_COLOR_DICT = {
  "white": typed[i++],
  "black": typed[i++],
  "darkCharcoal": typed[i++],
  "shade": typed[i++],
  "silver": typed[i++],
  "charcoal": typed[i++],
  "gray": typed[i++],
  "maroon": typed[i++],
  "cardinal": typed[i++],
  "red": typed[i++],
  "blazingOrange": typed[i++],
  "orange": typed[i++],
  "brown": typed[i++],
  "espresso": typed[i++],
  "vegasGold": typed[i++],
  "yellow": typed[i++],
  "lemon": typed[i++],
  "electricGreen": typed[i++],
  "kelly": typed[i++],
  "forest": typed[i++],
  "navy": typed[i++],
  "royal": typed[i++],
  "divaBlue": typed[i++],
  "columbiaBlue": typed[i++],
  "purple": typed[i++],
  "electricPurple": typed[i++],
  "shockingPink": typed[i++],
  "pink": typed[i++],
  "opticYellow": typed[i++],
  "texasOrange": typed[i++],
  "custom": typed[i++]
} as const;

export { typed as DECO_COLORS };
