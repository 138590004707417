import * as _ from '@technically/lodash'

import { GARMENT_DICT, ROSTER_SIZE_DICT } from '../common/sheets'

const PRODUCT_TITLE = 'Mizuno Stock Apparel'

const getIndexTitle = () => `${PRODUCT_TITLE} Builder`

const getIndexDescription = () =>
  'Build your own stock apparel in a photorealistic customizer.'

const getSkuDescription = () =>
  'Build your own stock apparel based on this template.'

const getRecipeDescription = () =>
  'Order this stock apparel or build your own based on it.'

const getVia = (config) => {
  if (config.hideVia || config.vendorName == null) {
    return ''
  }
  return ` via ${config.vendorName}`
}

const getSkuValues = (sku) => {
  if (!_.find(GARMENT_DICT, { id: sku })) {
    throw Error(`SKU is not valid: "${sku}"`)
  }

  return {
    garmentId: sku,
    canonicalSku: sku,
  }
}

const getSkuTitle = (config, params) => {
  const sku = params[0]
  const { canonicalSku } = getSkuValues(sku)

  return `${canonicalSku} - ${PRODUCT_TITLE} Template${getVia(config)}`
}

const getRecipeTitle = (config, recipe, id, hasChanges) =>
  `Design #${id}${hasChanges ? ' with changes' : ''} - ${PRODUCT_TITLE}${getVia(
    config,
  )}`

const getStaticTags = () => ''

const garmentSizes = [{ title: 'Size', path: 'size', sheet: ROSTER_SIZE_DICT }]

export {
  getIndexTitle,
  getIndexDescription,
  getSkuValues,
  getSkuTitle,
  getSkuDescription,
  getRecipeTitle,
  getRecipeDescription,
  getStaticTags,
  garmentSizes,
}
